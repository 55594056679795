import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { commonInputStyles, BankCountry, entity_options, radio_options } from "../helper";
import { HookTextField } from "../../../atoms/form-fields/SLFieldTextField";
import { useHookFormContext } from "../../../utils/hooks/useHookFormContext";
import { HookCheckBox } from "../../../atoms/form-fields/SLFieldCheckBox";
import styles from "../style.module.scss";
import CountriesAutocomplete from "../../../molecules/input-components/countriesAutocomplete";
import { RequiredTextField } from "../../../molecules/input-components/RequiredTextField";
import { HookRadioButton } from "../../../atoms/form-fields/SLFieldRadioButton";
import BasicAutocomplete from "../../../molecules/autocompletes/basic-autocomplete";
import UploadFiles from "../add-msme-certificate";
import { useBoolean } from "../../../utils/hooks/useBoolean";
import { useBankContext } from "../context";
import { BoxFlex } from "../../../atoms/boxSpaceBtw";
import { Link } from "react-router-dom";
import { Tooltip, Typography } from "@mui/material";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HelpIcon from '@mui/icons-material/Help';
import CustomToolTip from "../../../atoms/custom-tool-tip";
import { requiredFieldsForW8BEN, requiredFieldsForW8BENE, requiredFieldsForW9 } from "../../../constants/infoHtml";

type Props = {
  country: string;
  isEdit: boolean;
  default_individual_option: any;
};

const formatSSN = (value: string) => {
  // Remove all non-digit characters
  const cleaned = value.replace(/\D/g, '');

  // Apply formatting based on length
  if (cleaned.length <= 3) {
    return cleaned;
  } else if (cleaned.length <= 5) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
  } else if (cleaned.length <= 9) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5)}`;
  } else {
    // Truncate to 9 digits if longer
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5, 9)}`;
  }
};

const BankDetailsOthers = ({ country, isEdit, default_individual_option }: Props) => {
  const { registerState, watch, getValues, setValue } = useHookFormContext();
  const [wFormUpload, setWFormUpload] = useState({
    w9_form: false,
    w8_ben_e_form: false,
    w8_ben_form: false
  });

  const openUploadWForm = (is_w9: boolean, is_w8_ben: boolean, is_w8_ben_e: boolean) => setWFormUpload((prev) => ({
    w9_form: is_w9,
    w8_ben_form: is_w8_ben,
    w8_ben_e_form: is_w8_ben_e
  }));
  const setWFormFalse = () => setWFormUpload({ w9_form: false, w8_ben_form: false, w8_ben_e_form: false });


  const { setController, setWForm, wForm, docVerification, setDocVerification } = useBankContext();
  const { value: isNewUpload, setTrue: SetNewUpload } = useBoolean();
  const { value: isIndividualValueChange, setTrue: setIndividualValueChangeTrue } = useBoolean();

  function isDifferentCountry(state: any) {
    if (state.bank_country && state.account_holder_country) {
      return state.bank_country !== state.account_holder_country;
    } else {
      return false;
    }
  }

  const [isCountryDiferent, setIsCountryDifferent] = useState(
    isDifferentCountry(getValues())
  );

  const ssnValue = watch('ssn');

  useEffect(() => {
    if (ssnValue) {
      // Format SSN whenever the value changes
      const formattedSSN = formatSSN(ssnValue);

      // Only set value if it's different from current input
      if (formattedSSN !== ssnValue) {
        setValue('ssn', formattedSSN, {
          shouldValidate: true,
          shouldDirty: true
        });
      }
    }
  }, [ssnValue, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change") {
        setIsCountryDifferent(isDifferentCountry(value));

        if (name === "individual_option") {
          setIndividualValueChangeTrue();
        }
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const closeWForm = () => {
    setController({
      control: null,
      for: "",
      setSelectedFile: null,
    });
    setWFormUpload({ w9_form: false, w8_ben_form: false, w8_ben_e_form: false })
  }

  const addIntermediatry = watch("add_intermediatry");
  const document_type = watch("document_type");
  const bank_in_usa = country === "United States of America";
  const individual_option_value = watch("individual_option");

  const manual_verification = docVerification.state !== null ? (docVerification.state === "document_verified" ? false : true) : null;
  const docRejected = docVerification.state === "manual_verify_rejected";
  const document_verified = docVerification.state === "document_verified";

  const verification_status = isNewUpload ? "uploaded_first_time" : document_verified ? "Verified" : docRejected ? "manual_verify_rejected" : manual_verification ? "verification_pending" : ""

  // If form is Edit Form
  // If individual_option is changed
  // If individual_option is not equal to default_individual_option, We want user to upload new W-9 Form
  const w9_form_url = isEdit && isIndividualValueChange && default_individual_option !== individual_option_value ? null : wForm.w9;

  const rejected_doc_url = wForm.w9 ?? (individual_option_value === "yes" ? wForm.W8_BEN_url : wForm.W8_BEN_E_url);

  useEffect(() => {
    if (!isEdit) {
      setValue("entity_type", null);
    }
  }, []);

  return (
    <>
      <Grid item xs={12} md={12} className={styles.inputRow}>
        <RequiredTextField
          id="bank_country"
          label="My Bank Account is in:"
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField id="holder_name" label="Account Holder Name" />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField
          max={35}
          id="account_number"
          label="Account Number"
        />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField id="bank_name" label="Bank Name" />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField max={140} id="bank_address" label="Bank Address" />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField
          id="account_holder_address"
          label="Account Holder's Address"
          max={140}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <CountriesAutocomplete
          id="account_holder_country"
          label="Account Holder's Residing country"
        />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField max={35} id="swift_code" label="SWIFT Code" />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        {country === "United States of America" ? (
          <RequiredTextField max={35} id="routing_code" label="Routing Code" />
        ) : country === "Canada" ? (
          <RequiredTextField max={35} id="canada_code" label="Canadian Code" />
        ) : (
          <RequiredTextField max={35} required={false} id="iban" label="IBAN" />
        )}
      </Grid>

      {isCountryDiferent && (
        <Grid item xs={12} sm={12} className={styles.inputRow}>
          <RequiredTextField
            id="reason_for_different_country"
            label="Bank and Beneficiary's country are different. Provide the reason."
          />
        </Grid>
      )}

      <Grid item xs={12} sm={12} className={styles.inputRow}>
        <HookCheckBox
          {...registerState("is_primary")}
          label="Set This Account as Primary"
        />
      </Grid>
      <Grid item xs={12} sm={12} className={styles.inputRow}>
        <HookCheckBox
          {...registerState("add_intermediatry")}
          label="Add Intermediary Bank details"
        />
      </Grid>
      {addIntermediatry && (
        <>
          <Grid item xs={12} sm={6} className={styles.inputRow}>
            <RequiredTextField
              id="intermediary_bank_name"
              label="Intermediary Bank Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={styles.inputRow}>
            <RequiredTextField
              id="intermediary_bank_address"
              label="Intermediary Bank address"
              max={140}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={styles.inputRow}>
            <RequiredTextField
              id="intermediary_bank_swift_code"
              label="Intermediary Bank Swift Code"
              max={35}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={styles.inputRow}>
            <RequiredTextField
              id="intermediary_bank_account_number"
              label="Intermediary Bank Account Number"
              max={35}
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} className={styles.inputRow}>
        <HookRadioButton
          {...registerState("individual_option")}
          label="Does this bank account belong to an individual?"
          radioGroupProps={{
            sx: {
              "& .MuiTypography-root": {
                marginLeft: "-5px !important",
              },
            },
          }}
          fields={radio_options}
        />
      </Grid>

      {individual_option_value === "yes" && bank_in_usa && (
        <>
          <Grid item xs={12} sm={12} className={styles.inputRow}>
            <RequiredTextField
              id="ssn"
              label="SSN (XXX-XX-XXXX)"
              max={11}
              pattern={{
                value: /^(?!000|666|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/,
                message: "Please Enter a valid SSN Number",
              }}
            />
          </Grid>
        </>
      )}

      {!bank_in_usa && (
        <Grid item xs={12} className={styles.inputRow} sx={{ my: "-10px" }}>
          <Typography sx={{ fontSize: 10 }}>
            FTIN: Foreign Tax Identifying Number
          </Typography>
        </Grid>
      )}
      {!bank_in_usa && (
        <Grid item xs={12} className={styles.inputRow} sx={{ mt: "-3px" }}>
          <RequiredTextField required id="ftin" label="FTIN" />
        </Grid>
      )}

      {individual_option_value === "yes" && !bank_in_usa && (
        <Grid item xs={12}>
          {wForm.W8_BEN_url && (docRejected && !isNewUpload ? false : true) ? (
            <BoxFlex sx={{ gap: "1rem" }}>
              <Link
                to={wForm.W8_BEN_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--green-color)",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    "&:focus": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  View Uploaded W-8BEN Form
                </Typography>
              </Link>
              <CustomBtnFilled
                label="UpdateW-8BEN Form"
                variant="outlined"
                onClick={() => openUploadWForm(false, true, false)}
              />
            </BoxFlex>
          ) : (
            <BoxFlex sx={{ gap: "1rem" }}>
              <CustomBtnFilled
                label="Upload W-8BEN Form"
                variant="outlined"
                onClick={() => openUploadWForm(false, true, false)}
              />
              <Link
                to="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf"
                target="_blank"
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "var(--green-color)",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    "&:focus": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Download W-8BEN Form
                </Typography>
              </Link>

              <CustomToolTip title={requiredFieldsForW8BEN} >
                <HelpIcon sx={{ cursor: 'pointer' }} />
              </CustomToolTip>
            </BoxFlex>
          )}
        </Grid>
      )}

      {individual_option_value === "no" && (
        <>
          <Grid item xs={12} sm={12} className={styles.inputRow}>
            <RequiredTextField
              id="entity_name"
              label="Entity Name"
              max={100}
              min={3}
              minMsg={"Entity Name should be more than 3 characters"}
            />
          </Grid>

          <Grid item xs={12} sm={12} className={styles.inputRow}>
            <BasicAutocomplete
              label="Entity Type"
              registerName="entity_type"
              isRequired
              options={entity_options}
            />
          </Grid>

          {bank_in_usa && (
            <Grid item xs={12} sm={12} className={styles.inputRow}>
              <RequiredTextField id="ein" label="EIN" />
            </Grid>
          )}

          {!bank_in_usa && (
            <Grid item xs={12}>
              {wForm.W8_BEN_E_url &&
                (docRejected && !isNewUpload ? false : true) ? (
                <BoxFlex sx={{ gap: "1rem" }}>
                  <Link
                    to={wForm.W8_BEN_E_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "var(--green-color)",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                        "&:focus": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      View UploadedW-8BEN-E Form
                    </Typography>
                  </Link>
                  <CustomBtnFilled
                    label="Update W-8BEN-E Form"
                    variant="outlined"
                    onClick={() => openUploadWForm(false, false, true)}
                  />
                </BoxFlex>
              ) : (
                <BoxFlex sx={{ gap: "1rem" }}>
                  <CustomBtnFilled
                    label="Upload W-8BEN-E Form"
                    variant="outlined"
                    onClick={() => openUploadWForm(false, false, true)}
                  />
                  <Link
                    to="https://www.irs.gov/pub/irs-pdf/fw8bene.pdf"
                    target="_blank"
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "var(--green-color)",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                        "&:focus": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      Download W-8BEN-E Form
                    </Typography>
                  </Link>
                  <CustomToolTip title={requiredFieldsForW8BENE} >
                    <HelpIcon sx={{ cursor: 'pointer' }} />
                  </CustomToolTip>
                </BoxFlex>
              )}
            </Grid>
          )}
        </>
      )}

      {bank_in_usa && (
        <Grid item xs={12}>
          {w9_form_url && (docRejected && !isNewUpload ? false : true) ? (
            <BoxFlex sx={{ gap: "1rem" }}>
              <Link to={w9_form_url} target="_blank" rel="noopener noreferrer">
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--green-color)",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    "&:focus": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  View Uploaded W-9 Form
                </Typography>
              </Link>
              <CustomBtnFilled
                label="Update W-9 Form"
                variant="outlined"
                onClick={() => openUploadWForm(true, false, false)}
              />
            </BoxFlex>
          ) : (
            <BoxFlex sx={{ gap: "1rem" }}>
              <CustomBtnFilled
                label="Upload W-9 Form"
                variant="outlined"
                onClick={() => openUploadWForm(true, false, false)}
              />
              <Link
                to="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                target="_blank"
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "var(--green-color)",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    "&:focus": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Download W-9 Form
                </Typography>
              </Link>
              <CustomToolTip title={requiredFieldsForW9} >
                <HelpIcon sx={{ cursor: 'pointer' }} />
              </CustomToolTip>
            </BoxFlex>
          )}
        </Grid>
      )}

      {verification_status && (
        <Grid item xs={12} sx={{ mt: "10px" }}>
          {verification_status === "Verified" ? (
            <BoxFlex
              sx={{
                color: "green",
                gap: "0.2rem",
                "& p": { fontSize: "12px", fontWeight: "500" },
              }}
            >
              <CheckCircleIcon sx={{ fontSize: "14px" }} />
              <p>{document_type} Verified</p>
            </BoxFlex>
          ) : verification_status === "uploaded_first_time" ? (
            <BoxFlex
              sx={{
                color: "red",
                gap: "0.2rem",
                "& p": { fontSize: "12px", fontWeight: "500" },
              }}
            >
              <PendingActionsIcon />
              <p>
                The new form will be manually verified by our team. Payment will
                not be processed to this bank account until the verification is
                complete.
              </p>
            </BoxFlex>
          ) : verification_status === "manual_verify_rejected" ? (
            <BoxFlex sx={{ flexDirection: "column", alignItems: "flex-start" }}>
              <BoxFlex
                sx={{
                  color: "red",
                  gap: "0.2rem",
                  "& p": { fontSize: "12px", fontWeight: "500" },
                }}
              >
                <HighlightOffIcon sx={{ fontSize: "14px" }} />
                <p>
                  {document_type} verification rejected, please update your
                  document
                </p>
              </BoxFlex>
              {rejected_doc_url && !isNewUpload && (
                <>
                  <Link
                    to={rejected_doc_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "red",
                        textDecoration: "underline",
                      }}
                    >
                      View Rejected {document_type} Form
                    </Typography>
                  </Link>
                </>
              )}
            </BoxFlex>
          ) : verification_status === "verification_pending" ? (
            <BoxFlex
              sx={{
                color: "red",
                gap: "0.2rem",
                "& p": { fontSize: "12px", fontWeight: "500" },
              }}
            >
              <PendingActionsIcon />
              <p>{document_type} Verfication Pending</p>
            </BoxFlex>
          ) : (
            <></>
          )}
        </Grid>
      )}

      <Grid item xs={12} sm={12} sx={{ mt: "10px" }}>
        <HookCheckBox
          {...registerState("check_1")}
          label={`If there are any changes in circumstances that affect the information on the form, I'll submit a new ${bank_in_usa ? "W-9" : "W-8BEN or W-8BEN-E"
            } form to update my status`}
        />
      </Grid>

      {/* Upload PAN */}

      {(wFormUpload.w9_form ||
        wFormUpload.w8_ben_form ||
        wFormUpload.w8_ben_e_form) && (
          <UploadFiles
            isOpen={
              wFormUpload.w9_form ||
              wFormUpload.w8_ben_form ||
              wFormUpload.w8_ben_e_form
            }
            handleClose={closeWForm}
            handleSubmitClose={(url) => {
              // we will send to the Backend => invalid_doc only triggering Manual Verification
              if (wFormUpload.w9_form) {
                setWForm((prev) => ({ ...prev, w9: url }));
                setDocVerification((prev) => ({ ...prev, state: "invalid_doc" }));
                SetNewUpload();
              } else if (wFormUpload.w8_ben_e_form) {
                setWForm((prev) => ({ ...prev, W8_BEN_E_url: url }));
                setDocVerification((prev) => ({ ...prev, state: "invalid_doc" }));
                SetNewUpload();
              } else if (wFormUpload.w8_ben_form) {
                setWForm((prev) => ({ ...prev, W8_BEN_url: url }));
                setDocVerification((prev) => ({ ...prev, state: "invalid_doc" }));
                SetNewUpload();
              }
              setWFormFalse();
            }}
            isPanUpload={true}
            title={
              wFormUpload.w9_form
                ? "Upload W-9 Form"
                : wFormUpload.w8_ben_e_form
                  ? "Upload Form W-8BEN-E"
                  : wFormUpload.w8_ben_form
                    ? "Upload Form W-8BEN"
                    : undefined
            }
          />
        )}
    </>
  );
};

export default BankDetailsOthers;