import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Grid,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  fetchCompanySearchList,
  fetchMastersList,
  removeFromRecent,
} from "./helper";
import styles from "./MastersList.module.scss";
import SearchBar from "../../../molecules/app-bar-common/search-bar";
import { MasterItem } from "../interfaces";
import { API_RESPONSES, API_PARAMS } from "../MastersCompany.constants";
import { Close, CalendarMonth } from "@mui/icons-material";
import CalenderRangePicker from "../../../molecules/calender-picker-dialog/calenderRangePicker";
import dayjs from "dayjs";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import { useSnackbar } from "notistack";
import CalenderTabs from "../../../molecules/calender-picker-dialog/calenderTabs";
interface MasterListPageContentProps {
  selectedMasterCompany: number | null;
  setSelectedMasterCompany: (a: number) => void;
  page: number;
  setTotalResults: React.Dispatch<React.SetStateAction<number>>;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  rowsPerPage: number;
  selectedMasterCompanyLabel: string | null;
  setSelectedMasterCompanyLabel: (a: string) => void;
}
export const MastersListPageContent: React.FC<MasterListPageContentProps> = ({
  selectedMasterCompany,
  setSelectedMasterCompany,
  page,
  rowsPerPage,
  setTotalPages,
  setTotalResults,
  selectedMasterCompanyLabel,
  setSelectedMasterCompanyLabel,
}) => {
  const [data, setData] = useState<MasterItem[] | null>([]);
  const [recentCount, setRecentCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [searchData, setSearchData] = useState<MasterItem[] | null>([]);
  const [dateFilterDialogOpen, setDateFilterDialogOpen] =
    useState<boolean>(false);
  const [prevSelectDate, setPrevSelectDate] = useState<any>({
    select: "between",
    singleDate: null,
    date: [null, null],
  });
  const [dateFilterApplied, setDateFilterApplied] = useState<boolean>(false);
  const [selectDate, setSelectDate] = useState<any>({
    select: "between",
    singleDate: null,
    date: [null, null],
  });
  const { enqueueSnackbar } = useSnackbar();
  function compareDate(from: any, to: any) {
    if (!from || !to) {
      return false;
    }
    if (from?.$y > to?.$y) {
      return false;
    }
    if (from?.$y === to?.$y) {
      if (from?.$M > to?.$M) {
        return false;
      }
      if (from?.$M === to?.$M) {
        if (from?.$D > to?.$D) {
          return false;
        }
      }
    }
    return true;
  }
  function formatDate(date: any): string {
    if (!date) {
      return "";
    }
    const year = date?.$y;
    const month = String(date?.$M + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date?.$D).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const handleCancelDateFilter = () => {
    setSelectDate(prevSelectDate);
    setDateFilterDialogOpen(false);
  };
  const handleResetDateFilter = () => {
    setSelectDate({
      select: "between",
      singleDate: null,
      date: [null, null],
    });
    setDateFilterApplied(false);
    setDateFilterDialogOpen(false);
    setLoading(true);
    setError(null);
    getMastersList(false);
  };
  const handleApplyDateFilter = () => {
    if (selectDate?.select !== "between" && !selectDate?.singleDate) {
      enqueueSnackbar(API_RESPONSES.NO_DATE_SELECTED, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      return;
    }
    if (
      selectDate?.select === "between" &&
      compareDate(selectDate?.date[0], selectDate?.date[1]) === false
    ) {
      enqueueSnackbar(API_RESPONSES.INVALID_DATE_RANGE, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      return;
    }
    setPrevSelectDate(selectDate);
    setDateFilterApplied(true);
    setLoading(true);
    setError(null);
    getMastersList(true);
    setDateFilterDialogOpen(false);
  };
  const searchCompanyList = async (searchString: string) => {
    setSearch(searchString);
    try {
      const result = await fetchCompanySearchList(
        searchString,
        page,
        rowsPerPage
      );
      if (!result?.data?.length) {
        setSearchError(API_RESPONSES.NO_COMPANIES_FOUND);
        return;
      }
      setSearchData(result?.data || []);
      if (result?.total) {
        setTotalResults(result.total);
        setTotalPages(Math.ceil(result.total / rowsPerPage));
      }
    } catch (err) {
      setSearchError(API_RESPONSES.ERROR_FETCHING_MASTERS_LIST);
    } finally {
      setSearchLoading(false);
    }
  };
  useEffect(() => {
    if (!search) {
      return;
    }
    setSearchLoading(true);
    searchCompanyList(search);
  }, [page, rowsPerPage]);
  const handleMasterCompanyLabelChange = async (masterCompanyId: number) => {
    let found = false;
    if (data?.length) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].id === masterCompanyId) {
          setSelectedMasterCompanyLabel(data[index].label);
          found = true;
          break;
        }
      }
    }
    if (!found && searchData?.length) {
      for (let index = 0; index < searchData.length; index++) {
        if (searchData[index].id === masterCompanyId) {
          setSelectedMasterCompanyLabel(searchData[index].label);
          break;
        }
      }
    }
  };
  const removeRecentlyAddedCompany = async (
    e: BaseSyntheticEvent,
    item: MasterItem
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedData = data?.filter((dataItem) => dataItem.id !== item.id);
    setData(updatedData || []);
    setRecentCount((prev) => { return prev - 1 });
    const response = await removeFromRecent(item.id);
    if (response?.success) {
      enqueueSnackbar(
        response?.message || API_RESPONSES.SUCCESSFULLY_REMOVED_FROM_RECENT,
        {
          variant: "success",
          autoHideDuration: 2000,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        }
      );
    } else {
      enqueueSnackbar(
        response?.message || API_RESPONSES.ERROR_REMOVING_FROM_RECENT,
        {
          variant: "error",
          autoHideDuration: 2000,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        }
      );
    }
  };
  const handleSelectedMasterCompanyChange = async (e: BaseSyntheticEvent) => {
    setSelectedMasterCompany(e.target.value);
    setSelectedMasterCompanyLabel("Loading ...");
    return handleMasterCompanyLabelChange(parseInt(e.target.value));
  };
  const handleSearch = async (searchString: string) => {
    setSearchLoading(true);
    setSearchError(null);
    searchCompanyList(searchString);
  };
  const getMastersList = async (applyFilter: boolean) => {
    try {
      const result = await fetchMastersList(
        API_PARAMS.SEARCH_RECENT,
        selectDate,
        applyFilter
      );
      const { data, total } = result;
      if (!data?.length) {
        setError(API_RESPONSES.NO_COMPANIES_FOUND);
      } else {
        setData(data);
      }
      setRecentCount(total);
    } catch (err) {
      setError(API_RESPONSES.ERROR_FETCHING_MASTERS_LIST);
      setRecentCount(0);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getMastersList(dateFilterApplied);
  }, []);
  return (
    <Box className={styles.container}>
      <Grid container>
        <Grid item xs={"auto"} className={styles.leftSide}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Typography variant="h6">Recently Added Companies {recentCount > 0 ? `(${recentCount})` : ''}</Typography>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}
            >
              {dateFilterApplied ? (
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "0.25rem",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem",
                  }}
                >
                  <Typography>
                    {selectDate?.select === "between" ? "" : selectDate?.select}{" "}
                    {selectDate?.select === "between"
                      ? formatDate(selectDate?.date[0])
                      : formatDate(selectDate?.singleDate)}
                    {selectDate?.select === "between"
                      ? " - " + formatDate(selectDate?.date[1])
                      : ""}
                  </Typography>

                  <IconButton
                    onClick={() => {
                      handleResetDateFilter();
                    }}
                    sx={{
                      backgroundColor: "rgba(210,210,210 , 1)",
                      padding: "2px",
                      fontSize: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#ff474c",
                      },
                    }}
                  >
                    <Close
                      style={{
                        display: "block",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  </IconButton>
                </div>
              ) : (
                <></>
              )}
              <IconButton
                onClick={() => {
                  setDateFilterDialogOpen(true);
                }}
                sx={{
                  backgroundColor: "rgba(210,210,210 , 1)",
                  padding: "4px",
                  fontSize: "2px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#EC9324",
                  },
                }}
              >
                <CalendarMonth
                  style={{
                    display: "block",
                    width: "15px",
                    height: "15px",
                  }}
                />
              </IconButton>
            </div>
          </div>
          {loading && (
            <CircularProgress
              style={{ marginTop: "1rem", marginLeft: "1rem" }}
            />
          )}
          {!loading && error && <Typography color="error">{error}</Typography>}
          {!loading && !error && data && (
            <FormControl>
              <RadioGroup
                value={selectedMasterCompany}
                onChange={handleSelectedMasterCompanyChange}
                name="recent-master-company-list-radio-group"
              >
                {data.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      style={{
                        maxWidth: "400px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      key={item?.id}
                      value={item?.id}
                      control={<Radio />}
                      label={`${item?.label} (${item?.id})`}
                    />
                    <IconButton
                      onClick={(e) => {
                        removeRecentlyAddedCompany(e, item);
                      }}
                      sx={{
                        backgroundColor: "rgba(210,210,210 , 1)",
                        padding: "2px",
                        fontSize: "2px",
                        marginLeft: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#ff474c",
                        },
                      }}
                    >
                      <Close
                        style={{
                          display: "block",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    </IconButton>
                  </div>
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs="auto">
          <Divider
            sx={{ borderColor: "#ccc", height: "100%", borderWidth: "1px" }}
          />
        </Grid>
        <Grid item xs className={styles.rightSide}>
          <Typography variant="h6">Select a Master Company</Typography>
          <SearchBar
            placeholder="Search Master Company"
            onSearch={handleSearch}
            searchValue={""}
          />
          {searchLoading && (
            <CircularProgress
              style={{ marginTop: "1rem", marginLeft: "1rem" }}
            />
          )}
          {!searchLoading && searchError && (
            <Typography color="error">{searchError}</Typography>
          )}
          {!searchError && !searchLoading && searchData && (
            <FormControl>
              <RadioGroup
                value={selectedMasterCompany}
                onChange={handleSelectedMasterCompanyChange}
                name="search-master-company-list-radio-group"
              >
                {searchData.map((item) => (
                  <FormControlLabel
                    key={item?.id}
                    value={item?.id}
                    control={<Radio />}
                    label={`${item?.label} (${item?.id})`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Dialog
        maxWidth="md"
        open={dateFilterDialogOpen}
        onClose={handleCancelDateFilter}
      >
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <CalenderTabs
            selectDate={selectDate}
            setSelectDate={setSelectDate}
            isOnClientDetails={false}
          />
          <DialogActions>
            <CustomBtnFilled
              label="Reset"
              variant="contained"
              onClick={handleResetDateFilter}
            />
            <CustomBtnFilled
              label="Cancel"
              variant="contained"
              onClick={handleCancelDateFilter}
            />
            <CustomBtnFilled
              label="Apply"
              variant="contained"
              onClick={handleApplyDateFilter}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
