import { IconButton, Drawer, Box, Link } from "@mui/material";
import ProfileSection from "../../molecules/expert-profile-sections/profile-section/ProfileSection";
import PersonalInfoSection from "../../molecules/expert-profile-sections/personal-info-section/PersonalInfoSection";
import BasicDetailSection from "../../molecules/expert-profile-sections/basic-details-section/BasicDetailSection";
import AboutSection from "../../molecules/expert-profile-sections/about-section/AboutSection";
import ExperienceSection from "../../molecules/expert-profile-sections/experience-section/ExperienceSection";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import EducationSection from "../../molecules/expert-profile-sections/education-section/EducationSection";
import AwardSection from "../../molecules/expert-profile-sections/award-section/AwardSection";
import PublicationSection from "../../molecules/expert-profile-sections/publication-section/pubSection";
import PatentSection from "../../molecules/expert-profile-sections/patent-section/patent";
import SnippetSection from "../../molecules/expert-profile-sections/snippet-section/SnippetSection";
import InternalInfoSection from "../../molecules/expert-profile-sections/internal-info-section/BasicDetailSection";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpertProfileLoading from "../skeletons/exprtProfileSkeletons";
import { isClient, isInfollion, isExpert } from "../../utils/role";
import { useSearchParams } from "react-router-dom";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { DrawerHeader, drawerWidth } from "../../pages/Quick-Search/style";
import { QuickProfileReviewProps } from "./types";

const QuickProfileReview: React.FC<QuickProfileReviewProps> = ({
  isDrawerExpaned,
  expandLeftDrawer,
  expandRightDrawer,
  closeDrawer,
  data,
  page
}) => {
  const [searchParams] = useSearchParams();
  const is_add_bank_open = !!searchParams.get("add_bank");
  const profileData = data;
  const isMobile = useIsMobile();

  // Handlers for header icons
  const handleCloseDrawer = () => closeDrawer();
  const handleChevronLeft = () => expandLeftDrawer();
  const handleChevronRight = () => expandRightDrawer();

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isDrawerExpaned}
      sx={{
        flexShrink: 0,
        width: drawerWidth,
        "& .MuiDrawer-paper": {
          width: {
            xs: "100%",
            sm: drawerWidth
          },
          boxSizing: "border-box",
          boxShadow: "-10px 0 15px -3px rgba(0, 0, 0, 0.1), -4px 0 6px -2px rgba(0, 0, 0, 0.05)",
          transition: theme => theme.transitions.create(['transform', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
      transitionDuration={{ enter: 300, exit: 200 }}
      SlideProps={{ easing: { enter: 'easeOut', exit: 'easeIn' } }}
    >
      <DrawerHeader>
        {/* Fixed Header */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            width: '100%',
            zIndex: 1,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
            borderBottom: "1px solid #ddd",
          }}
        >
          {!isMobile &&
            <IconButton onClick={handleCloseDrawer}>
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
          }
          <Box sx={{ flex: 1, textAlign: "center", mx: 2 }}>
            {profileData && (
              <Link
                fontWeight={600}
                variant="h6"
                rel="noopener noreferrer"
                target="_blank"
                href={`/layout/expert-profile?id=${profileData.id}&page=${page}`}
                underline="none"
              >
                {profileData.name} #{profileData.id}
              </Link>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2, mr: 1 }}>
            <IconButton
              edge="start"
              onClick={handleChevronLeft}
              sx={{
                backgroundColor: "#f0f0f0",
                borderRadius: "50%",
                p: 1,
              }}
            >
              <ChevronLeftIcon sx={{ color: "black", fontSize: "1.5rem" }} />
            </IconButton>
            <IconButton
              edge="start"
              onClick={handleChevronRight}
              sx={{
                backgroundColor: "#f0f0f0",
                borderRadius: "50%",
                p: 1,
              }}
            >
              <ChevronRightIcon sx={{ color: "black", fontSize: "1.5rem" }} />
            </IconButton>
          </Box>
        </Box>
      </DrawerHeader>

      {/* Scrollable Content with conditional rendering */}
      <Box
        sx={{
          overflowY: "auto",
          flex: 1,
          p: 2,
        }}
      >
        {profileData ? (
          <>
            <ProfileSection
              apiData={profileData}
              showSection={true}
              handleShowImageDialogOpen={() => { }}
            />
            {!isClient() && !isInfollion() && (
              <PersonalInfoSection apiData={profileData} />
            )}
            <BasicDetailSection apiData={profileData} />
            <AboutSection apiData={profileData} />
            <ExperienceSection apiData={profileData} />
            <EducationSection apiData={profileData} />
            <AwardSection apiData={profileData} />
            <PublicationSection apiData={profileData} />
            <PatentSection apiData={profileData} />
            <SnippetSection apiData={profileData} />
            {!isExpert() && !isClient() && !isInfollion() && (
              <InternalInfoSection apiData={profileData} />
            )}
          </>
        ) : (
          <ExpertProfileLoading />
        )}
      </Box>
    </Drawer>
  );
};

export default QuickProfileReview;
