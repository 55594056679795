import { useEffect, useState } from "react";
import PageLayout from "../../atoms/page-layout";
import ExchangeRateHeader from "../../molecules/app-bars/exchange-rate";
import { ExchangeRateContext } from "./context";
import { Dialog, ExchangeRateData, Filters } from "./types";
import { defaultDialog, defaultFilters, getUrl } from "./helper";
import { useFetch } from "../../utils/hooks/useFetch";
import ExchangeRateNavbar from "../../molecules/nav-bars/exchange-rate-page";
import ExchangeRateListView from "./list-view";
import AddExchangeRateDialog from "./add-form";



export default function ExchangeRatePage() {
    const [filters, setFilters] = useState<Filters>(defaultFilters);
    const [dialog, setDialog] = useState<Dialog>(defaultDialog);
    const [selectedTab, setSelectedTab] = useState(0);
    const url = getUrl(filters, selectedTab);

    const { data, loading: loadingData, refetchWithNewUrl } = useFetch<ExchangeRateData>(url);
    useEffect(() => {
        refetchWithNewUrl(url);
    }, [url]);

    const resetFilters = () => setFilters(defaultFilters);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    return (
        <PageLayout>
            <ExchangeRateContext.Provider value={{ filters, setFilters, refetch: async () => refetchWithNewUrl(url), dialog, data, loadingData, setDialog }}>
                <ExchangeRateHeader />
                <ExchangeRateNavbar isFilterApplied={filters.isFilterApplied} resetFilters={resetFilters}
                    selectedTab={selectedTab}
                    handleTabChange={handleTabChange}
                />
                <ExchangeRateListView selectedTab={selectedTab} />
                {/* Add Exchange Rates Dialog */}
                {dialog.add.state &&
                    <AddExchangeRateDialog
                        isOpen={dialog.add.state}
                        handleClose={() => setDialog(defaultDialog)}
                    />}
            </ExchangeRateContext.Provider>
        </PageLayout>
    )
}