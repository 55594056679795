import React from 'react'
import { updateExpertData } from './helper';
import { EXPERT_API_RESPONSES } from './expert-list.constants';
import { useSnackbar } from 'notistack';
import CustomBtnFilled from '../../atoms/form-molecules/CustomBtnFilled';
import { useFullPageLoading } from '../../atoms/full-page-loading/loadingContext';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../constants';

const pageStyle: any = {
    position: "fixed",
    left: "0px",
    top: "100px",
    padding: '20px',
    backgroundColor: 'white',
    width: '100%',
    height: 'calc(100vh - 70px)',
    zIndex: 999,
}

interface FullPageReviewProps {
    closeReview: () => void;
    payload: any;
}

const FullPageReview: React.FC<FullPageReviewProps> = ({ closeReview, payload }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { setLoading } = useFullPageLoading();
    const navigate = useNavigate();
    // const payload = {
    //     name: name,
    //     expertId: expertId,
    //     headline: headline,
    //     education: finalEducationPayload,
    //     work_experiences: finalWorkExperiencesPayload,
    // };
    const handleUpdate = async () => {
        try {
            setLoading(true);
            const result = await updateExpertData(payload);
            if (result.success) {
                enqueueSnackbar(result.message, {
                    variant: "success",
                    autoHideDuration: 2000,
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                });
                navigate(`${AppRoutes.EXPERT_PROFILE}?id=${payload?.expertId}&page=1`);
            } else {
                enqueueSnackbar(EXPERT_API_RESPONSES.FAILED_TO_UPDATE_DATA, {
                    variant: "error",
                    autoHideDuration: 2000,
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                });
            }
        } catch (error) {
            enqueueSnackbar(EXPERT_API_RESPONSES.ERROR_OCCURED_WHILE_UPDATING_DATA, {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
            });
        } finally {
            setLoading(false);
        }
    };
    const renderWorkExperience = (work: any) => {
        return (
            <div style={{ border: '1px solid #e0e0e0', padding: '10px', borderRadius: '5px' }}>
                <p><b>Company: </b>{work?.company}</p>
                <p><b>Designation: </b>{work?.designation}</p>
                <p><b>Location: </b>{(typeof work?.location === "string" ? !work?.location || work?.location?.trim() === "" : !work?.location?.label || work?.location?.label?.trim() === "") ? "" : (typeof work?.location === "string" ? work?.location : work?.location?.label)}</p>
                <p><b>Division: </b>{work?.division}</p>
                <p><b>Start Date: </b>{work?.start_date}</p>
                {work?.currently_works_here ? <></> : <p><b>End Date: </b>{work?.end_date}</p>}
                <p><b>Currently Works Here: </b>{work?.currently_works_here ? "Yes" : "No"}</p>
                <p><b>Job Description: </b>{work?.job_description}</p>
            </div>
        )
    }
    const renderEducation = (edu: any) => {
        return (
            <div style={{ border: '1px solid #e0e0e0', padding: '10px', borderRadius: '5px' }}>
                <p><b>Course: </b>{edu?.course}</p>
                <p><b>Institution: </b>{edu?.institution}</p>
                <p><b>Start Year: </b>{edu?.start_year}</p>
                <p><b>End Year: </b>{edu?.end_year}</p>
            </div>
        )
    }
    const handleExpertProfile = async () => {
        window.open(`${AppRoutes.EXPERT_PROFILE}?id=${payload?.expertId}&page=1`, "_blank");
    }
    return (
        <div style={pageStyle}>
            <div style={{ display: 'flex', flexDirection: 'column', overflowY: "auto", maxHeight: 'calc(100% - 50px)' }}>
                <div style={{ alignSelf: "center", fontSize: '20px' }}>
                    <h1><b>Basic Info</b></h1>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: '10px' }}>
                    <p><b>Name: </b>{payload?.name}</p>
                    <p><b>Headline: </b>{payload?.headline}</p>
                    <p><b>Expert ID : </b>{payload?.expertId}</p>
                    <p><b>LinkedIn URL : </b>{payload?.source_link}</p>
                </div>
                <div style={{ alignSelf: "center", fontSize: '20px' }}>
                    <h1><b>Work Experience</b></h1>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', gap: '10px' }}>
                    {payload?.work_experiences?.map((work: any) => renderWorkExperience(work))}
                </div>
                <div style={{ alignSelf: "center", fontSize: '20px' }}>
                    <h1><b>Education</b></h1>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px' }}>
                    {payload?.education?.map((edu: any) => renderEducation(edu))}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', gap: "1rem", borderTop: '1px solid #e0e0e0' }}>
                <CustomBtnFilled variant='outlined' onClick={closeReview} label="Back" />
                <CustomBtnFilled variant='outlined' onClick={handleExpertProfile} label="Go to Expert Profile" />
                <CustomBtnFilled variant='contained' onClick={handleUpdate} label="Update" />
            </div>
        </div>
    )
}

export default FullPageReview