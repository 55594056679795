import DialogModal from "../../../../../atoms/dialog";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { usePeMappingContext } from "../../helper";
import { defaultFormTheme } from "../../../../../atoms/defaultFormTheme";
import Fields from "./fields";
import { DefaultValues, defaultValues } from "./helper";
import { APIRoutes } from "../../../../../constants";
import { RequestServer } from "../../../../../utils/services";
import { removeWhiteSpacesFromForm } from "../../../../../utils/utils";
import { useFullPageLoading } from "../../../../../atoms/full-page-loading/loadingContext";
import { Ratings } from "../../type";
import WarningDialog from "../../../../../molecules/form-close-warning";
import { useBoolean } from "../../../../../utils/hooks/useBoolean";
import { useRef } from "react";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  expert_id: number;
  project_id: number;
  pe_ratings: Ratings["data"];
};
//TODO: Make ratings functional
const AddRatings = ({ isOpen, handleClose, expert_id, project_id, pe_ratings }: Props) => {
  const { refetch } = usePeMappingContext();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useFullPageLoading();
  const { value: isWarningShowing, setTrue: showWarning, setFalse: hideWarning } = useBoolean();
  const countRef = useRef(0);
  const loggedInUserId = localStorage.getItem("id");

  const onSubmit: SubmitHandler<Partial<DefaultValues>> = async (formData) => {
    const newFormData = removeWhiteSpacesFromForm(formData, []);

    // If Ratings Exists, show warning only for the first time
    if (countRef.current === 0 && loggedInUserId && pe_ratings.find(r => r.rated_by === +loggedInUserId)) {
      countRef.current++;
      showWarning();
      return;
    }
    

    // VALIDATIONS -------------------------------------------- //

    if (!newFormData.project_relevancy_rating) {
      enqueueSnackbar("Project Relevancy Rating is required", {
        variant: "warning",
      });
      return;
    }

    if (newFormData.seniority_remark && !newFormData.seniority_rating) {
      enqueueSnackbar("Please provide a Seniority Rating before submitting your Seniority Remark.", {
        variant: "warning",
      });
      return;
    }
    if (newFormData.relevancy_remark && !newFormData.relevancy_rating) {
      enqueueSnackbar("Please provide a Relevancy Rating (Snippet) before submitting your Relevancy Remark (Snippet).", {
        variant: "warning",
      });
      return;
    }

    // ------------------------------------------------------- //

    newFormData.project_relevancy_rating = parseInt(`${newFormData.project_relevancy_rating}`);
    newFormData.seniority_rating =
      newFormData.seniority_rating &&
      parseInt(`${newFormData.seniority_rating}`);
    newFormData.relevancy_rating =
      newFormData.relevancy_rating &&
      parseInt(`${newFormData.relevancy_rating}`);
    newFormData.fk_expert = expert_id;
    newFormData.fk_project = project_id;
    setLoading(true);
    try {
      const url = APIRoutes.peRating;
      // console.log(newFormData);
      const response = await RequestServer(url, "PUT", newFormData);
      if (response.success) {
        enqueueSnackbar("Review Posted Successfully", {
          variant: "success",
        });
        handleClose();
        refetch();
      } else {
        console.log({ response });
        enqueueSnackbar(response.message.toString(), {
          variant: "warning",
        });
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Request failed.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm({ defaultValues });

  const defaultTheme = createTheme(defaultFormTheme);

  return (
    <>
      <DialogModal
        isOpen={isOpen}
        handleClose={handleClose}
        title={`Add Ratings`}
      >
        <FormProvider {...methods}>
          <ThemeProvider theme={defaultTheme}>
            <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
              <Fields handleClose={handleClose} />
            </form>
          </ThemeProvider>
        </FormProvider>
      </DialogModal>

      <WarningDialog
        open={isWarningShowing}
        handleClose={hideWarning}
        handleYesClick={hideWarning}
        text="Please note that submitting this will overwrite your previously submitted ratings. Are you sure you want to proceed?"
      />
    </>
  );
};

export default AddRatings;
