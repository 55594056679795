import { useState } from "react";
import { CheckboxOptions } from "../../organisms/expert-filter-dialog/helper";
import { useHookFormContext } from "../../utils/hooks/useHookFormContext";
import { useFieldArray } from "react-hook-form";
import Grid from '@mui/material/Grid';
import { HookAutoComplete } from "../../atoms/form-fields/SLFieldAutoComplete";
import { getCompanies } from "../../pages/Experts/helper";
import { HookTextField } from "../../atoms/form-fields/SLFieldTextField";
import { commonInputStyles } from "../../common/input-styles";
import { companyFieldsOptions, FormFieldType, TFormField } from "./types";
import { getFieldType } from "./helper";
import WatchAutoComplete from "./advanced-search-input/WatchAutoComplete";
import { BeforeAfterDatePicker } from "./advanced-search-input/BeforeAfterDatePicker";
import { Chip } from "@mui/material";
import CompanyTextFieldAsyncSearchHook from "./CompanyTextFieldAsyncSearchHook";

const DynamicFields = ({ andIndex, orIndex, formArrayPath, domains, countries }: TFormField) => {

  const [companiesOptions, setCompaniesOptions] =
    useState<companyFieldsOptions>({
      current: [],
    });
  const { registerState, control } = useHookFormContext()
  const { fields: orFilterFields } =
    useFieldArray({
      control,
      name: formArrayPath,
    });

  const registerName = (index: number) => `${formArrayPath}.${index}.value`

  const renderField = (inputElement: any, index: number) => {
    const fieldType = getFieldType(inputElement);
    switch (fieldType) {
      case FormFieldType.WATCH_AUTOCOMPLETE:
        return (
          <WatchAutoComplete
            registerName={registerName}
            formArrayPath={formArrayPath}
            index={index}
            inputElement={inputElement}
          />
        );
      case FormFieldType.COMPANY:
        return (
          <CompanyTextFieldAsyncSearchHook
            key={inputElement.id}
            enableFreeText
            displayFreeTextOnTop
            autocompleteProps={{
              autoHighlight: true,
              limitTags: 1,
              freeSolo: true,
              renderTags: (value: any, getTagProps: any) => {
                return (
                  value.map((option: any, index: number) => (
                    <Chip
                      key={index}
                      color={typeof option.value === "string" && option.value.startsWith('free_text') ? "primary" : "default"}
                      variant={'filled'}
                      label={option.label}
                      {...getTagProps({ index })}
                      size="small"
                    />
                  ))
                );
              }
            }}
            displayPlainFreeText
            onFreeTextClick={() => { }}
            isValueNeeded
            registerStatename={registerName(index)}
            options={companiesOptions.current}
            searchFunction={(inputValue: string) => getCompanies(inputValue, "current")}
            setOptions={(state: any) =>
              setCompaniesOptions((prev: companyFieldsOptions) => ({
                ...prev,
                current: state
              }))
            }
            label={`${inputElement.label}${inputElement.required ? " *" : ""}`}
            multi={true}
            isRequired={!!inputElement.required}
            disableCloseOnSelect={true}
          />
        );
      case FormFieldType.FREE_SOLO:
        return (
          <HookAutoComplete
            key={inputElement.id}
            {...registerState(registerName(index))}
            textFieldProps={{ label: inputElement.label, size: "small" }}
            rules={{
              required: { value: !!inputElement.required, message: "This field is required" },
            }}
            autocompleteProps={{
              limitTags: 1,
              autoHighlight: true,
              multiple: true,
              freeSolo: true,
              options: [],
              size: "small",
            }}
          />
        )
      case FormFieldType.TEXT_INPUT:
        return (
          <HookTextField
            key={inputElement.id}
            {...registerState(registerName(index))}
            rules={{
              required: { value: !!inputElement.required, message: "This field is required" },
            }}
            textFieldProps={{
              ...commonInputStyles,
              type: inputElement?.inputType || "",
              label: inputElement.label || 'type',
              required: !!inputElement.required,
              size: 'small',
              InputProps: {
                inputProps: {
                  maxLength: "250",
                },
              },
            }}
          />
        )
      case FormFieldType.GEOGRAPHY:
        return (
          <HookAutoComplete
            key={inputElement.id}
            {...registerState(registerName(index))}
            textFieldProps={{
              label: inputElement.label,
              size: "small",
            }}
            rules={{
              required: { value: !!inputElement.required, message: "This field is required" },
            }}
            autocompleteProps={{
              freeSolo: true,
              isOptionEqualToValue: (option: any, value: any) => {
                return option.value === value.value
              },
              autoHighlight: true,
              size: "small",
              limitTags: 1,
              disablePortal: true,
              options: countries || [],
              loadingText: "Loading...",
              disableCloseOnSelect: true,
              style: { backgroundColor: "white" },
              multiple: true,
              renderOption: (props: any, option: any, state: any) => CheckboxOptions(props, option, state, { fontSize: "10px" }),
            }}
          />
        )
      case FormFieldType.DOMAIN:
        return (
          <HookAutoComplete
            key={inputElement.id}
            {...registerState(registerName(index))}
            textFieldProps={{
              label: inputElement.label,
              size: "small",
            }}
            rules={{
              required: { value: !!inputElement.required, message: "This field is required" },
            }}
            autocompleteProps={{
              isOptionEqualToValue: (option: any, value: any) => option?.value === value?.value,
              multiple: true,
              disablePortal: true,
              autoHighlight: true,
              options: domains || [],
              noOptionsText: "Loading...",
              size: "small",
              limitTags: 1,
              disableCloseOnSelect: true,
              renderOption: (props: any, option: any, state: any) => CheckboxOptions(props, option, state, { fontSize: "10px" }),
              filterOptions: (options, { inputValue }) => {
                const inputValueLowercased: string = inputValue.toLowerCase();
                return options.filter((option: any) =>
                  option?.label.toLowerCase().includes(inputValueLowercased)
                );
              },

            }}
          />
        )
      case FormFieldType.DATE_PICKER:
        return (
          <BeforeAfterDatePicker
            index={index}
            registerName={registerName}
            inputElement={inputElement}
            formArrayPath={formArrayPath}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      {orFilterFields.map((inputElement: any, index) => (
        <Grid item xs={12} sm={6} md={orFilterFields?.length === 3 ? 3 : 4} flex={1} key={`${andIndex}-${orIndex}-${index}`}>
          {renderField(inputElement, index)}
        </Grid>
      ))}
    </>
  );
};

export default DynamicFields
