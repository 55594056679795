export const API_RESPONSES = {
    INVALID_API_RESPONSE: "Invalid API response format",
    ERROR_FETCHING_MASTERS_LIST: "Error fetching masters list",
    PLEASE_SELECT_MASTER_COMPANY: "Please select a master company",
    SUCCESSFULLY_SEARCHED: "Successfully Searched",
    NO_MASTER_COMPANY_SELECTED: "No master company selected",
    NO_COMPANIES_FOUND: "No companies found",
    ERROR_SUBMITTING_DEDUPLICATION: "Error submitting deduplication",
    SELECT_ATLEAST_ONE_COMPANY: "Please select at least one company",
    SUCCESSFULLY_REMOVED_FROM_RECENT: "Company successfully removed from recent",
    ERROR_REMOVING_FROM_RECENT: "Failed to remove company from recent",
    INVALID_DATE_RANGE: "From date should be less than To date",
    NO_MASTER_COMPANY_LABEL: "Master company label cannot be empty",
    NO_DATE_SELECTED: "Please select a date",
}
export const URLS = {
    SELECTED_PAGE_URL : "/layout/Masters-list/Selected-Master?masterCompanyId=",
    MASTER_PAGE_URL : "/layout/masters-list",
}
export const API_PARAMS = {
    SEARCH_RECENT : "recent",
    SEARCH_PROBABLE : "probable",
}
export const STRINGS = {
    OPEN_SELECTED_MASTERS : "Open Selected Masters",
}    