import React, { useEffect, useMemo, useState } from "react";
import DialogModal from "../../../atoms/dialog";
import { CallDetails, SelectedCards, SetSelect } from "../../../pages/Calls/types";
import { checkAllValuesAreSame } from "../../../utils/utils";
import NoResultFoundFilters from "../../../atoms/noResultsFilters";
import SubmitPaymentRequestTable from "./table";
import { isGenerateInvoiceAllowed, requestPaymentSubmit } from "../../../pages/Calls/helpers";
import { PrimaryBankValue } from "../../project/project-pe-mapping/actions/share-profile/email-format-dialog/types";
import { useSnackbar } from "notistack";
import { BoxFlex } from "../../../atoms/boxSpaceBtw";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import { useFullPageLoading } from "../../../atoms/full-page-loading/loadingContext";
import { SectionType, DialogTypes, defaultDialogValue, handleDialogClose, bankDetailsChecks, INDIA_START_DATE, INDIA_END_DATE, NON_INDIA_START_DATE, NON_INDIA_END_DATE } from "./helper";
import Box from "@mui/material/Box";
import { FileUpload } from "../../../molecules/input-components/FileUpload";
import { SubmitDialog } from "./submit-dialog";
import { useBoolean } from "../../../utils/hooks/useBoolean";
import { ViewInvoiceType } from "../view-invoice/types";
import { getInvoice } from "../generate-invoice/helper";
import ViewInvoice from "../view-invoice";
import { RequestServer } from "../../../utils/services";
import { APIRoutes, AppRoutes } from "../../../constants";
import CircularProgress from "@mui/material/CircularProgress";
import BankDetails from "../../../molecules/bank-details-modal";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);


type Props = {
  isOpen: boolean;
  handleClose(): void;
  callDetails: CallDetails;
  refetch(callIds: number[]): Promise<void>;
  setSelect: SetSelect;
};

export default function SubmitPaymentRequest({ isOpen, handleClose, callDetails, refetch, setSelect }: Props) {
  const [bankDetails, setBankDetails] = useState<PrimaryBankValue | null>(null);
  const { value: generateInvoiceAllowed, setValue: setGenerateInvoiceAllowed } = useBoolean();
  const [invoice, setInvoice] = useState<ViewInvoiceType>(null);
  const [section, setSection] = useState<SectionType>("");
  const [noResult, setNoResult] = useState<React.ReactNode | null>(null);
  const [docError, setDocError] = useState<React.ReactNode | null>(null);
  const [select, setSelected] = useState<SelectedCards[]>(callDetails.map((d) => ({ ...d, value: d.id })));
  const [dialog, setDialog] = useState<DialogTypes>(defaultDialogValue);
  const [imageUrl, setImageUrl] = useState("");
  const [controller, setController] = useState({
    control: null,
    for: "",
    setSelectedFile: null,
  }); // abort controller

  const { enqueueSnackbar } = useSnackbar();
  const { setLoading, isLoading } = useFullPageLoading();

  // --------- Is Calls Currency Same Or NOT ----------------------------------------------------- //
  const is_currency_same = useMemo(() => {
    const currency_arr = callDetails.map((call) => call.cost_price_currency);
    return checkAllValuesAreSame<string>(currency_arr);
  }, [isOpen]);
  // --------------------------------------------------------------------------------------------- //

  const handleBtnClick = async (section: SectionType) => {
    if (section !== "Confirm call details" && select.length === 0) {
      enqueueSnackbar("Select call before invoice action", {
        variant: "warning",
      });
      return;
    }

    if (section === "Generate Invoice") {
      await getInvoice(select[0].fk_expert.toString(), select, setInvoice, bankDetails, setLoading);
    }

    setSection(section);
  };

  const submitUploadInvoice = async () => {
    try {
      await requestPaymentSubmit(
        imageUrl,
        select.map((c) => c.id).join(","),
        setLoading,
        enqueueSnackbar,
        setSelect,
        false,
        undefined,
        undefined,
        async () => { },
        callDetails[0].fk_expert
      );
      refetch(select.map((c) => c.id));
      handleDialogClose(setDialog);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const submitGenerateInvoice = async () => {
    if (!invoice) {
      enqueueSnackbar("No invoice found", {
        variant: "warning",
      });
      return;
    }

    const select_ids = select.map((c) => c.id).join(",");

    const payload = {
      action: "RequestPayment",
      invoice_num: invoice.invoice_no.replace("#", ""),
      meta: invoice,
      ids: select_ids,
      expert_id: callDetails[0].fk_expert,
    };

    setLoading(true);
    try {
      const response = await RequestServer(APIRoutes.scheduleCall, "PATCH", payload);

      if (response.success) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        handleClose();
        setSelect(() => ({
          callAction: null,
          isClicked: false,
          selectedCards: [],
        }));
        refetch(select.map((c) => c.id));
        handleDialogClose(setDialog);
        handleClose();
      } else {
        console.log({ response });
        enqueueSnackbar(response.message || response.error || "Something Went Wrong", {
          variant: "warning",
          autoHideDuration: 10000,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const is_expert_same = useMemo(() => {
    return !callDetails.find((c) => c.fk_expert !== callDetails[0].fk_expert);
  }, [callDetails.length]);

  const bankDetailsChecksHandler = async () =>
    await bankDetailsChecks({
      callDetails,
      enqueueSnackbar,
      setBankDetails,
      setDocError,
      setLoading,
      isOpen,
      setNoResult,
      setGenerateInvoiceAllowed,
      setSection,
    });

  useEffect(() => {
    const executeBankDetailsCheck = async () => {
      try {
        if (is_expert_same) {
          await bankDetailsChecksHandler();
        } else {
          setLoading(false);
        }
      } catch (error: any) {
        console.error(error);
        enqueueSnackbar("An unexpected error occurred.", { variant: "error" });
        handleClose();
      } finally {
        setLoading(false);
      }
    };

    executeBankDetailsCheck();
  }, [isOpen]);

  function shouldShowPaymentTimedError() {
    const now = dayjs().utc().tz("Asia/Kolkata")
    if (bankDetails && bankDetails.bank_country_code === 'IND') {
      return now.isSameOrAfter(INDIA_START_DATE) && now.isSameOrBefore(INDIA_END_DATE);
    } else {
      return now.isSameOrAfter(NON_INDIA_START_DATE) && now.isSameOrBefore(NON_INDIA_END_DATE);
    }
  }

  return (
    <>
      <DialogModal isOpen={isOpen} handleClose={handleClose} title={section}>
        {isLoading ? (
          <></>
        ) : callDetails.length === 0 ? (
          <>
            {noResult ? (
              <NoResultFoundFilters
                sx={{ paddingBottom: "12px" }}
                sxItem={{ justifyContent: "flex-start !important" }}
                text={noResult}
              />
            ) : (
              <CircularProgress sx={{ mt: "10px" }} />
            )}
          </>
        ) : !is_expert_same ? (
          <NoResultFoundFilters sx={{ paddingBottom: "12px" }} text="Please select calls with same Expert" />
        ) : shouldShowPaymentTimedError() ? <NoResultFoundFilters sx={{ paddingBottom: "12px" }} text="Due to our financial year‑end procedures, the submission of payment requests is temporarily disabled. The service will be reinstated on April 1st, 2025.
We appreciate your patience and understanding during this period. Should you require further assistance or have any inquiries, please do not hesitate to contact our support team." /> : !callDetails[0]?.fk_expert_value?.primary_bank ? (
          <NoResultFoundFilters sx={{ paddingBottom: "12px" }} text={
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "1rem" }}>
              <Typography variant="h6">
                Please add Bank Account before requesting payment
              </Typography>
              <Link
                rel="noopener noreferrer"
                target="_blank"
                to={`${AppRoutes.EXPERT_PROFILE}?id=${callDetails[0]?.fk_expert}&add_bank=1`}
                style={{
                  backgroundColor: "red",
                  fontWeight: "bold",
                  color: "white",
                  padding: "5px 8px",
                  borderRadius: "15px",
                  fontSize: "12px",
                }}
              >
                Add Bank Account
              </Link>
            </div>
          } />
        ) : docError ? (
          <BoxFlex sx={{ flexDirection: "column" }}>
            <NoResultFoundFilters
              sx={{ paddingBottom: "12px" }}
              sxItem={{ justifyContent: "flex-start !important" }}
              text={docError}
            />
            <CustomBtnFilled
              variant="contained"
              label={"Open primary bank account details"}
              onClick={() => {
                setDialog((prev) => ({
                  ...prev,
                  open_bank: {
                    state: true,
                  },
                }));
              }}
            />
          </BoxFlex>
        ) : !is_currency_same ? (
          <NoResultFoundFilters sx={{ paddingBottom: "12px" }} text="Please select calls with same payable currency" />
        ) : (
          <>
            {callDetails.length === 0 ? (
              <>
                {noResult ? (
                  <NoResultFoundFilters
                    sx={{ paddingBottom: "12px" }}
                    sxItem={{ justifyContent: "flex-start !important" }}
                    text={noResult}
                  />
                ) : (
                  <CircularProgress sx={{ mt: "10px" }} />
                )}
              </>
            ) : (
              <>
                {section !== "Generate Invoice" && (
                  <SubmitPaymentRequestTable
                    section={section}
                    callDetails={callDetails}
                    select={select}
                    setSelected={setSelected}
                  />
                )}

                {section === "Confirm call details" && (
                  <BoxFlex sx={{ gap: "1rem", justifyContent: "flex-end", mt: "1rem" }}>
                    {generateInvoiceAllowed && (
                      <CustomBtnFilled
                        label="Generate Invoice"
                        variant="contained"
                        onClick={() => handleBtnClick("Generate Invoice")}
                      />
                    )}
                    <CustomBtnFilled
                      label="Upload Invoice"
                      variant="contained"
                      onClick={() => handleBtnClick("Upload Invoice")}
                    />
                  </BoxFlex>
                )}

                {section !== "Confirm call details" && (
                  <>
                    {section === "Upload Invoice" ? (
                      <>
                        {!imageUrl ? (
                          <Box sx={{ p: "1rem", pt: "32px" }}>
                            <FileUpload
                              setUrl={setImageUrl}
                              setLoading={setLoading}
                              setController={setController}
                              dropzoneConfig={{
                                maxSize: 5,
                                text: (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p>Upload Invoice (File should be upto 5MB)</p>
                                    <p>[ Drag 'n' drop file here, or click to select file ]</p>
                                  </div>
                                ),
                              }}
                            />
                          </Box>
                        ) : (
                          <BoxFlex sx={{ gap: "1rem", p: "1rem", border: "1px solid rgba(0,0,0,0.6)", borderRadius: "5px" }}>
                            <a
                              style={{ textDecoration: "underline", color: "var(--green-color)", fontSize: "14px" }}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={imageUrl}
                            >
                              Uploaded Invoice
                            </a>
                            <CustomBtnFilled label="remove" variant="outlined" onClick={() => setImageUrl("")} />
                          </BoxFlex>
                        )}
                      </>
                    ) : (
                      <>{invoice && <ViewInvoice invoice={invoice} showPrintBtn={false} />}</>
                    )}

                    <BoxFlex
                      sx={{
                        gap: "1rem",
                        justifyContent: "flex-end",
                        mt: "1rem",
                        "@media print": {
                          display: "none", // Hide the button when printing
                        },
                      }}
                    >
                      {bankDetails && (
                        <CustomBtnFilled label="Back" variant="outlined" onClick={() => handleBtnClick("Confirm call details")} />
                      )}
                      <CustomBtnFilled
                        label="Submit"
                        variant="contained"
                        onClick={() => {
                          if ((imageUrl && section === "Upload Invoice") || section === "Generate Invoice") {
                            setDialog((prev) => ({
                              ...prev,
                              submit: {
                                state: true,
                                handleSubmit: section === "Generate Invoice" ? submitGenerateInvoice : submitUploadInvoice,
                              },
                            }));
                          } else {
                            enqueueSnackbar("Upload invoice before payment request", {
                              variant: "warning",
                            });
                          }
                        }}
                      />
                    </BoxFlex>
                  </>
                )}
              </>
            )}
          </>
        )}
      </DialogModal>

      {dialog.submit.handleSubmit && (
        <SubmitDialog
          isOpen={dialog.submit.state}
          handleClose={() => handleDialogClose(setDialog)}
          handleSubmit={dialog.submit.handleSubmit}
          bankDetails={bankDetails}
        />
      )}

      {dialog.open_bank.state && bankDetails && (
        <BankDetails
          open={dialog.open_bank.state}
          closeDialog={() => handleDialogClose(setDialog)}
          bankDetail={bankDetails}
          isPrimary={true}
          refresh={async () => {
            await bankDetailsChecksHandler();
          }}
          expert_id={bankDetails.fk_expert.toString()}
          defaultEditBankOpenValue
        />
      )}
    </>
  );
}