import React, { useCallback, useRef, useState } from 'react'
import QuickSearchDropDown from './quicksearch-dropdown'
import { Box, Chip, Stack, Typography } from '@mui/material'
import QuickSearchBar from './QuickSearchBar'
import { DELIMITER } from './constant'
import Popover from '@mui/material/Popover';
import { isObjectEqual } from './helper'

interface QuickSearchComponentProps {
  searchLabel: string
  onSearch: (searchValue: string) => void
  searchValue?: string
  searchIn: string[]
  setSearchIn: (searchIn: string[]) => void
  forceSearch: () => void
  selectedKeywords: Record<string, boolean>
  setSelectedKeywords: (value: Record<string, boolean>) => void
  chips: string[]
  setChips: React.Dispatch<React.SetStateAction<string[]>>
}

const QuickSearchComponent = ({ searchLabel, onSearch, searchValue, searchIn, setSearchIn, forceSearch, selectedKeywords, setSelectedKeywords, chips, setChips }: QuickSearchComponentProps) => {
  // States
  const [previousKeywordFilters, setPreviousKeywordFilters] = useState<Record<string, boolean>>({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const debouncedOnSearch = useCallback((currentChips: string[]) => {
    onSearch((currentChips || []).join(DELIMITER));
  }, [onSearch]);

  const hasKeywordFiltersChanged = () => {
    return !isObjectEqual(selectedKeywords, previousKeywordFilters);
  }
  const handleSearch = (searchValue: string) => {
    let flag = 0;
    if (hasKeywordFiltersChanged()) {
      flag = 1;
      const newParsedSearchIn = Object.keys(selectedKeywords);
      setSearchIn(newParsedSearchIn);
    }
    setPreviousKeywordFilters(selectedKeywords);
    onSearch(searchValue);
    if (flag) {
      forceSearch();
    }
  }

  const handleReset = () => {
    setSelectedKeywords({});
    setPreviousKeywordFilters({});
  }
  const handleDropDown = () => {
    if (hasKeywordFiltersChanged()) {
      const newParsedSearchIn = Object.keys(selectedKeywords);
      setSearchIn(newParsedSearchIn);
    }
    setPreviousKeywordFilters(selectedKeywords);
  }

  const handleRemoveChip = async (index: number) => {
    if (index >= chips.length || index < 0) {
      return;
    }
    const newChips = chips.filter((chip, i) => i !== index);
    if (index === 0 && chips.length === 1) {
      setAnchorEl(null);
    }
    setChips(newChips);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (searchValue) {
      debounceTimeoutRef.current = setTimeout(() => {
        debouncedOnSearch(newChips);
        debounceTimeoutRef.current = null;
      }, 1000); // 1 seconds delay
    }
  }


  const open = Boolean(anchorEl);
  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };
  const id = anchorEl ? 'search-chip-popover' : undefined;
  let visibleChips = [];
  return (
    <div style={{ position: "relative" }}>
      <div style={{
        display: "flex", alignItems: "center",
      }}
      >
        <QuickSearchDropDown
          handleReset={handleReset}
          selectedKeywords={selectedKeywords}
          setSelectedKeywords={setSelectedKeywords}
          handleDropDown={handleDropDown}
          parsedSearchIn={searchIn}
          setParsedSearchIn={setSearchIn}
        />
        <QuickSearchBar
          placeholder={searchLabel}
          onSearch={handleSearch}
          showSearchIcon={false}
          chips={chips}
          setChips={setChips}
        />
      </div>

      <Stack gap={1} direction="row" mt={1} justifyContent={"flex-start"} alignItems={'center'} >
        {(() => {
          let totalLength = 0;
          for (let i = 0; i < chips.length; i++) {
            const chipLength = chips[i].length + 4;
            if (totalLength + chipLength > 50 && chips.length > 1) break;
            visibleChips.push(
              <Box key={`${i}_${chips[i]}`}>
                <Chip
                  size="small"
                  label={chips[i]}
                  style={{
                    color: "#6f6f6f",
                    backgroundColor: "#eceaea",
                    border: "1px solid #eceaea",
                  }}
                  onDelete={() => handleRemoveChip(i)}
                />
              </Box>
            );

            totalLength += chipLength;
          }

          return visibleChips;
        })()}
        <Typography
          style={{ cursor: "pointer" }}
          sx={{ "&:hover": { textDecoration: "underline", color: "#ec9324" } }}
          aria-describedby={id}
          onClick={(event) => {
            handleAnchorClick(event as React.MouseEvent<HTMLButtonElement>);
          }}
        >
          {chips.join("____").length > 50 && `+${chips.length - visibleChips.length}`}
        </Typography>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "25px",
              boxShadow: 0,
            }
          }
        }}
      >
        <Box p={0.5}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
            {chips.map((chip, index) => (
              <Chip
                size='small'
                key={index}
                label={chip}
                style={{ margin: "2px", color: "#6f6f6f", backgroundColor: "#eceaea", border: "1px solid #eceaea" }}
                onDelete={() => { handleRemoveChip(index) }} />
            ))}
          </Box>
        </Box>
      </Popover>
    </div>
  )
}

export default QuickSearchComponent