import { Box, Button, IconButton, Typography } from '@mui/material';
import NavbarCommon from '../../nav-bar-common'
import { NavbarActions, NavbarActionsLength } from './navbarActions';
import PEMappingNavbarItems from './navbarItems';
import { useMemo } from 'react';
import CustomBtnFilled from '../../../atoms/form-molecules/CustomBtnFilled';
import { ArrowBack, Refresh } from '@mui/icons-material';
import { useIsMobile } from '../../../utils/hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';

type Props = {
    isSelectedClicked: boolean;
    inviteClickHandler(s: boolean): void;
    bulkRevertClickHandler(): void;
    ShortlistClickHandler(): void;
    bulkShareClickHandler(): void;
    expertDetailsClickHandler: () => void
    isFilterApplied: boolean;
    resetFilters(): void;
    totalSelected: number;
    selectClickHandler(): void;
    onActionSelect: (action: { title: string, label: React.ReactNode, onClick(): void } | null) => void;
    selectedAction: { title: string, label: React.ReactNode, onClick(): void } | null
    projectData: any
    refetch: () => void
}

const PEMappingNavbar = ({
    isSelectedClicked,
    inviteClickHandler,
    ShortlistClickHandler,
    expertDetailsClickHandler,
    isFilterApplied,
    resetFilters,
    totalSelected,
    projectData,
    selectClickHandler, onActionSelect, selectedAction, bulkShareClickHandler, bulkRevertClickHandler, refetch }: Props) => {

    const memoizedActions = useMemo(() =>
        NavbarActions(
            inviteClickHandler,
            ShortlistClickHandler,
            bulkShareClickHandler,
            expertDetailsClickHandler,
            bulkRevertClickHandler
        ),
        [inviteClickHandler, ShortlistClickHandler, bulkShareClickHandler, expertDetailsClickHandler, bulkRevertClickHandler]
    );
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const handleBackToProject = async () => {
        navigate(`/layout/projects/projectdetails?id=${projectData.id}`)
    }
    const handleRefresh = async () => {
        refetch();
    }
    return (
        <>
            {projectData &&
                <Box sx={{ mb: -3, mt: -1, pb: 1 }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "10px" }}>

                        <section>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: "450",
                                    fontSize: "22px",
                                    textTransform: "capitalize",
                                }}
                            >
                                {`#${projectData?.id}:`} {projectData?.topic}
                            </Typography>
                        </section>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <Button
                                sx={{
                                    color: "#000000",
                                    textTransform: "capitalize",
                                    fontSize: "15px",
                                    alignSelf: "center",
                                    fontFamily: "inherit",


                                    padding: isMobile ? "8px 0" : "8px"
                                }}
                                onClick={handleBackToProject}
                            >
                                <ArrowBack />
                                {!isMobile && <span className="back-btn-title">Back to Project</span>}
                            </Button>
                            <IconButton
                                onClick={handleRefresh}
                            >
                                <Refresh />
                            </IconButton>
                        </div>
                    </div>
                </Box>
            }

            <NavbarCommon
                isSelectClicked={isSelectedClicked}
                Actions={memoizedActions}
                ActionsLength={NavbarActionsLength}
                NavbarItems={<PEMappingNavbarItems />}
                resetFilters={resetFilters}
                isFilterApplied={isFilterApplied}
                totalSelected={totalSelected}
                selectClickHandler={selectClickHandler}
                onActionSelect={onActionSelect}
                selectedAction={selectedAction}
                selectActionSubmitBtnName='Choose Expert'
            />
        </>
    )
}

export default PEMappingNavbar