import React, { useEffect, useRef, useState } from 'react';
import SearchBar, { SearchBarHandle } from "../../molecules/app-bar-common/search-bar";
import { SetFilterPayload, filterPayload } from "../Experts/types";
import { isClient } from "../../utils/role";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIsTablet } from "../../utils/hooks/useIsMobile";

type Props = {
  filterPayload: filterPayload,
  setFilterPayload: SetFilterPayload,
  setLocalFilterPayload: any,
  localFilterPayload: any,
  resetAllField: boolean,
  setResetAllFieldFalse: any
}

const QuickNavbarSearch = ({ filterPayload, setFilterPayload, setLocalFilterPayload, localFilterPayload, resetAllField, setResetAllFieldFalse }: Props) => {

  const isMobile = useIsTablet();
  const [submitToggle, setSubmitToggle] = useState<boolean>(false);
  const idRef = useRef<SearchBarHandle>(null)
  const nameRef = useRef<SearchBarHandle>(null)
  const emailRef = useRef<SearchBarHandle>(null)
  const mobileRef = useRef<SearchBarHandle>(null)
  useEffect(() => {
    if (submitToggle) {
      setFilterPayload(localFilterPayload);
      setSubmitToggle(false);
    }
    if (resetAllField) {
      idRef.current?.resetSearch()
      nameRef.current?.resetSearch();
      emailRef.current?.resetSearch();
      mobileRef.current?.resetSearch();
      setResetAllFieldFalse()

    }
  }, [localFilterPayload])


  const updateFilterPayloadOnChange = (key: string, value: string) => {
    setLocalFilterPayload((prev: any) => ({
      ...prev,
      isFilterChange: true,
      otherSearchFilter: {
        ...prev.otherSearchFilter,
        [key]: value,
      }
    })
    );
  }
  const setFilterPayloadOnSearch = (key: string, value: string) => {
    updateFilterPayloadOnChange(key, value);
    setSubmitToggle(true);
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.25rem",
        flexWrap: "wrap",
        flex: 1,
        // marginTop: isMobile ? "0.5rem" : "1.5rem"
      }}
    >
      {!isMobile ? (
        <>
          <SearchBar
            showToolTip
            ref={idRef}
            onSearch={(text) => setFilterPayloadOnSearch("id", text)}
            placeholder="ID"
            searchValue={filterPayload.otherSearchFilter.id || ""}
            maxWidth="110px"
            minWidth={"110px"}
            p="5px"
            m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
            showSearchIcon={false}
            getOnChange={true}
            onChangeFunction={(text) => updateFilterPayloadOnChange("id", text)}
          />
          <SearchBar
            showToolTip
            ref={nameRef}
            showSearchIcon={false}
            onSearch={(text) => setFilterPayloadOnSearch("name", text)}
            placeholder="Name"
            searchValue={filterPayload.otherSearchFilter.name || ""}
            maxWidth="110px"
            minWidth={"110px"}
            p="5px"
            m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
            getOnChange={true}
            onChangeFunction={(text) => updateFilterPayloadOnChange("name", text)}
          />
          {!isClient() && (
            <>
              <SearchBar
                showToolTip
                ref={emailRef}
                showSearchIcon={false}
                onSearch={(text) => setFilterPayloadOnSearch("email", text)}
                placeholder="Email"
                searchValue={filterPayload.otherSearchFilter.email || ""}
                maxWidth="110px"
                minWidth={"110px"}
                p="5px"
                m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
                getOnChange={true}
                onChangeFunction={(text) => updateFilterPayloadOnChange("email", text)}
              />
              <SearchBar
                showToolTip
                ref={mobileRef}
                showSearchIcon={false}
                onSearch={(text) => setFilterPayloadOnSearch("mobile", text)}
                placeholder="Mobile"
                searchValue={filterPayload.otherSearchFilter.mobile || ""}
                maxWidth="110px"
                minWidth={"110px"}
                p="5px"
                m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
                getOnChange={true}
                onChangeFunction={(text) => updateFilterPayloadOnChange("mobile", text)}
              />
            </>
          )}
        </>
      ) : (
        <Accordion>
          <AccordionSummary sx={{ background: '#6F696912' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography sx={{ fontSize: '12px', color: '#252b3b' }}>Quick Filters</Typography>
          </AccordionSummary >
          <AccordionDetails >
            <Typography sx={{ display: 'flex', flexWrap: 'wrap', background: '#6f696912', gap: '6px', paddingTop: '15px', paddingLeft: '25px', paddingBottom: '15px' }}>

              <SearchBar
                showToolTip
                ref={idRef}
                onSearch={(text) => setFilterPayload((prev) => ({
                  ...prev,
                  isFilterChange: true,
                  otherSearchFilter: {
                    ...prev.otherSearchFilter,
                    id: text,
                  }
                }))}
                placeholder="ID"
                searchValue={filterPayload.otherSearchFilter.id || ""}
                maxWidth="110px"
                minWidth={"110px"}
                p="5px"
                m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
                showSearchIcon={false}
              />
              <SearchBar
                showToolTip
                ref={nameRef}
                showSearchIcon={false}
                onSearch={(text) => setFilterPayload((prev) => ({
                  ...prev,
                  isFilterChange: true,
                  otherSearchFilter: {
                    ...prev.otherSearchFilter,
                    name: text,
                  }
                }))}
                placeholder="Name"
                searchValue={filterPayload.otherSearchFilter.name || ""}
                maxWidth="110px"
                minWidth={"110px"}
                p="5px"
                m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
              />
              {!isClient() && (
                <>
                  <SearchBar
                    showToolTip
                    ref={emailRef}
                    showSearchIcon={false}
                    onSearch={(text) => setFilterPayload((prev) => ({
                      ...prev,
                      isFilterChange: true,
                      otherSearchFilter: {
                        ...prev.otherSearchFilter,
                        email: text,
                      }
                    }))}
                    placeholder="Email"
                    searchValue={filterPayload.otherSearchFilter.email || ""}
                    maxWidth="110px"
                    minWidth={"110px"}
                    p="5px"
                    m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
                  />
                  <SearchBar
                    showToolTip
                    ref={mobileRef}
                    showSearchIcon={false}
                    onSearch={(text) => setFilterPayload((prev) => ({
                      ...prev,
                      isFilterChange: true,
                      otherSearchFilter: {
                        ...prev.otherSearchFilter,
                        mobile: text,
                      }
                    }))}
                    placeholder="Mobile"
                    searchValue={filterPayload.otherSearchFilter.mobile || ""}
                    maxWidth="110px"
                    minWidth={"110px"}
                    p="5px"
                    m={{ sm: "0 0.75rem 0 0", xs: "0 0.75rem 0 0" }}
                  />
                </>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}

export default QuickNavbarSearch;
