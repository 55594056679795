import { Grid, IconButton, Typography } from "@mui/material";
import {
  actionRowStyles,
  commonInputStyles,
  dateClearBtnExpStyles,
  inputRowStyles,
} from "../styles";
import { useHookFormContext } from "../../../utils/hooks/useHookFormContext";
import { HookTextField } from "../../../atoms/form-fields/SLFieldTextField";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import HookDatePicker from "../../../atoms/form-fields/SLFieldDatePicker";
import { useBoolean } from "../../../utils/hooks/useBoolean";

const EducationInfoFields = (props: any) => {
  const { setFormChange, eduID, removeClickHandler, isNewlyAdded, isEditable = true, setOnChange = null, showButtons = true, minSpacing = false, validationErrors = false, removeSpecificValidationError = null } = props;
  const { registerState, watch, setValue } = useHookFormContext();
  const { value: isFormChange, setTrue: setFormChangeTrue } = useBoolean();
  const handleClearBtn = (state: "start_year" | "end_year") => {
    setValue(state, null);
  };
  const [startDateForceError, setStartDateForceError] = useState<any>({});
  const [endDateForceError, setEndDateForceError] = useState<any>({});

  useEffect(() => {
    if (validationErrors) {
      if (validationErrors?.start_year && isEditable) {
        setStartDateForceError({ error: true })
      } else {
        setStartDateForceError({})
      }
      if (validationErrors?.end_year && isEditable) {
        setEndDateForceError({ error: true })
      } else {
        setEndDateForceError({})
      }
    }
  }, [validationErrors, isEditable])

  const [start_year_value, end_year_value] = watch(["start_year", "end_year"]);

  useEffect(
    () => {
      const subscription = watch((value, { name, type }) => {
        if (setOnChange) {
          setOnChange(value);
        }
        if (removeSpecificValidationError) {
          if (name === "course" && validationErrors?.course) {
            removeSpecificValidationError("course");
          }
          if (name === "institution" && validationErrors?.institution) {
            removeSpecificValidationError("institution");
          }
          if (name === "start_year" && validationErrors?.start_year) {
            removeSpecificValidationError("start_year");
          }
          if (name === "end_year" && validationErrors?.end_year) {
            removeSpecificValidationError("end_year");
          }
        }
        if (type === "change" || name === "start_year" || name === "end_year") {
          setFormChangeTrue();
          setFormChange((prevFormChangeValues: any) => {
            if (prevFormChangeValues.education === false) {
              return {
                ...prevFormChangeValues,
                education: true,
              };
            }

            return prevFormChangeValues;
          });
        }
      });

      return () => subscription.unsubscribe();
    },
    // eslint-disable-next-line
    [watch]
  );

  return (
    <Grid container spacing={minSpacing ? 1 : 3}>
      {/* College/Institute's Name */}
      <Grid item xs={12}>
        <HookTextField
          {...registerState("institution")}
          rules={{
            required: { value: true, message: "This field is required" },
            maxLength: {
              value: 100,
              message:
                "College/Institute's Name should be less than 100 characters",
            },
          }}
          forceError={validationErrors?.institution && isEditable ? true : false}
          textFieldProps={{
            ...commonInputStyles,
            label: "College/Institute's Name *",
            disabled: !isEditable,
          }}
        />
        {validationErrors?.institution && isEditable ? <Typography variant="body2" color="error" >{validationErrors?.institution}</Typography> : <></>}
      </Grid>

      {/* Name of College/Coaching */}
      <Grid item xs={12}>
        <HookTextField
          {...registerState("course")}
          rules={{
            required: { value: true, message: "This field is required" },
            maxLength: {
              value: 150,
              message: "Subject/Course should be less than 150 characters",
            },
          }}
          forceError={validationErrors?.course && isEditable ? true : false}
          textFieldProps={{
            ...commonInputStyles,
            label: "Subject/Course *",
            disabled: !isEditable,
          }}
        />
        {validationErrors?.course && isEditable ? <Typography variant="body2" color="error" >{validationErrors?.course}</Typography> : <></>}
      </Grid>

      {/* First Year */}
      <Grid item xs={6} lg={3} sx={{ ...inputRowStyles, position: "relative" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <HookDatePicker
            {...registerState("start_year")}
            datePickerProps={{
              format: "YYYY",
              className: "date-picker",
              label: "Start Year",
              disabled: !isEditable,
              slotProps: { textField: { size: "small", fullWidth: true, ...startDateForceError } },
              sx: { backgroundColor: "white" },
              views: ["year"],
              disableFuture: true,
            }}
          />
          {start_year_value && isEditable && (
            <IconButton
              sx={minSpacing ? { ...dateClearBtnExpStyles, top: "14px", } : dateClearBtnExpStyles}
              onClick={() => handleClearBtn("start_year")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </LocalizationProvider>
        {validationErrors?.start_year && isEditable ? <Typography variant="body2" color="error" >{validationErrors?.start_year}</Typography> : <></>}
      </Grid>

      {/* Last Year */}
      <Grid item xs={6} lg={3} sx={{ ...inputRowStyles, position: "relative" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <HookDatePicker
            {...registerState("end_year")}
            datePickerProps={{
              format: "YYYY",
              className: "date-picker",
              label: "End Year",
              disabled: !isEditable,
              slotProps: { textField: { size: "small", fullWidth: true, ...endDateForceError } },
              sx: { backgroundColor: "white" },
              views: ["year"],
              disableFuture: true,
            }}
          />
          {end_year_value && isEditable && (
            <IconButton
              sx={minSpacing ? { ...dateClearBtnExpStyles, top: "14px", } : dateClearBtnExpStyles}
              onClick={() => handleClearBtn("end_year")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </LocalizationProvider>
        {validationErrors?.end_year && isEditable ? <Typography variant="body2" color="error" >{validationErrors?.end_year}</Typography> : <></>}
      </Grid>

      {/* ACTION BUTTONS */}
      {
        showButtons &&
        (<Grid sx={actionRowStyles} item xs={12}>
          <CustomBtnFilled
            label={isNewlyAdded ? "Cancel" : "Remove"}
            variant="outlined"
            onClick={() => removeClickHandler(eduID, isNewlyAdded)}
          />
          {isFormChange &&
            <CustomBtnFilled
              label="submit"
              variant="contained"
              buttonType="submit"
            />
          }
        </Grid>)
      }
    </Grid>
  );
};

export default EducationInfoFields;
