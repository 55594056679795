import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AceBadge from "../assets/images/expert/ace-badge.png";
import proBadge from "../assets/images/expert/crown-badge.png";
import championBadge from "../assets/images/expert/trophy-badge.png";
import diamondIcon from "../assets/images/expert/diamond_expert.png";
import dnd from "../assets/images/expert/no-call-expert.png";

function InfoItem({
  img,
  title,
  description,
}: {
  img: string;
  title: string;
  description: string;
}) {
  return (
    <Box sx={{ display: "flex", gap: 1.5 }}>
      <img
        src={img}
        alt=""
        style={{
          width: "24px",
          height: "24px",
          marginTop: "4px",
          flexShrink: 0,
        }}
      />
      <div>
        <Typography
          component="span"
          sx={{ color: "#EC9324", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography sx={{ lineHeight: 1.3, color: "#E0DFDA." }}>
          {description}
        </Typography>
      </div>
    </Box>
  );
}

export const infoForAdmin = (
  <>
    <Box sx={{ p: 0.5, display: "flex", flexDirection: "column", gap: 1.5 }}>
      <InfoItem
        img={AceBadge}
        title="Ace"
        description="7+ projects serviced in last 2 years with $10,000+ in revenue"
      />
      <InfoItem
        img={championBadge}
        title="Champion"
        description="7+ projects serviced in last 2 years"
      />
      <InfoItem
        img={proBadge}
        title="Pro"
        description="More than 5 less than 7 projects serviced in last 2 years"
      />
      <InfoItem
        img={diamondIcon}
        title="Premium Expert"
        description="Minimum chargeable is 60 mins"
      />
      <InfoItem img={dnd} title="DND" description="Do not disturb" />
    </Box>
  </>
);

export const infoForClientAndSharedLink = (
  <>
    <Box sx={{ p: 0.5, display: "flex", flexDirection: "column", gap: 1.5 }}>
       <InfoItem
        img={AceBadge}
        title="Ace"
        description="Successfully completed 10+ projects with Infollion Clients"
      />
      <InfoItem
        img={championBadge}
        title="Champion"
        description="Successfully completed 7+ projects with Infollion Clients"
      />
      <InfoItem
        img={proBadge}
        title="Pro"
        description="Successfully completed 5+ projects with Infollion Clients"
      />
      <InfoItem
        img={diamondIcon}
        title="Premium Expert"
        description="Minimum Chargeable is 60 mins"
      />
    </Box>
  </>
);

export const requiredFieldsForW8BENE = (
  <>
    <Box sx={{ p: 0.5 }}>
      <Typography sx={{ fontWeight: 500, mb: 1 }}>
        Please make sure to fill in the required fields for W8-BEN-E:
      </Typography>
      <Box component="ul" sx={{ m: 0, pl: 2 }}>
        {[
          "Name of Organization (1)",
          "Country of incorporation or organization (2)",
          "Status (4)",
          "Address (6)",
          "Date of Signature",
          "Signature",
          "FTIN Number (9b)",
          "Claim of Tax Treaty Benefits (Part III - 14a) - if applicable"
        ].map((item) => (
          <Typography component="li" key={item} sx={{ mb: 0.5 }}>
            {item}
          </Typography>
        ))}
      </Box>
      <Typography sx={{ mt: 1, fontSize: '0.9rem', fontStyle: 'italic' }}>
        <strong>*Foreign Tax Identifying Number, a unique number used to pay taxes or identify yourself in your country. It may be found on your government-issued ID or social insurance card</strong>
      </Typography>
    </Box>
  </>
);

export const requiredFieldsForW8BEN = (
  <>
    <Box sx={{ p: 0.5 }}>
      <Typography sx={{ fontWeight: 500, mb: 1 }}>
        Please make sure to fill in the required fields for W8-BEN:
      </Typography>
      <Box component="ul" sx={{ m: 0, pl: 2 }}>
        {[
          "Name (1)",
          "Citizenship (2)",
          "Address (3)",
          "Country (3)",
          "DOB (8)",
          "Date of Signature",
          "Signature",
          "FTIN Number (6a)"
        ].map((item) => (
          <Typography component="li" key={item} sx={{ mb: 0.5 }}>
            {item}
          </Typography>
        ))}
      </Box>
      <Typography sx={{ mt: 1, fontSize: '0.9rem', fontStyle: 'italic' }}>
        <strong>*Foreign Tax Identifying Number, a unique number used to pay taxes or identify yourself in your country. It may be found on your government-issued ID or social insurance card</strong>
      </Typography>
    </Box>
  </>
);

export const requiredFieldsForW9 = (
  <>
    <Box sx={{ p: 0.5 }}>
      <Typography sx={{ fontWeight: 500, mb: 1 }}>
        Please make sure to fill in the required fields for W9:
      </Typography>
      <Box component="ul" sx={{ m: 0, pl: 2 }}>
        {[
          "Name (1) / Business Name (2)",
          "Federal Tax Classification (3a)",
          "Address (5)",
          "City, state, and ZIP code (6)",
          "Date of Signature",
          "Signature",
          "SSN / EIN Number (Part I)"
        ].map((item) => (
          <Typography component="li" key={item} sx={{ mb: 0.5 }}>
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  </>
);
