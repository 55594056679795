import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import AppBarCommon from "../../molecules/app-bar-common";
import PaginationComponent from "../../atoms/pagination";
import { getQuickSearchData } from "../../organisms/quick-search/helper";
import IndividualCards from "../../atoms/IndividualExpertCards/IndividualCards";
import { AddToProject } from "../../organisms/expert-cards/add-to-project";
import AddToStaging from "../../organisms/experts/add-to-staging";
import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import NavbarCommon from "../../molecules/nav-bar-common";
import { NavbarActions, NavbarActionsLength } from "./navbarActions";
import { AlertNBackdrop, selectedCardsTypes } from "../../../src/pages/Experts/types";
import AddToProjectExperts from "../../organisms/experts/map-multiple-experts-to-project/dialog";
import { handleSubmitClose } from "../Experts/helper";
import AfterStagingDialog from "../../organisms/experts/add-to-staging/afterStagingDialog";
import { SelectedAction } from "../compliance-approval-table/type";
import FilterDrawer from "../../organisms/quick-search/FilterDrawer";
import PageLayout from "../../atoms/page-layout";
import QuickSearchComponent from "./quickSearchComponent";
import { SetFilterPayload as TSetFilterPayload, filterPayload as TFilterPayload } from "../Experts/types";
import { defaultFilterPayload, DELIMITER, dialogsDefaultValue, stagingResponseDefaultValue } from "./constant";
import QuickSearchNavbarItems from "./quickSearchNavbarItems";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { advancedFilterDefaultForm } from "./constant";
import { constructFilterPayload, transformForPayload } from "./helper";
import { andBoxForm, PayloadAdvancedFilters } from "./types";
import QuickProfileReview from "../../atoms/IndividualExpertCards/QuickProfileReview";
import { getAllProfileDetails } from "../../organisms/expert-profile/helper";
import { useExpertFilterEvents } from "../../utils/ga-tracker/helpers";
import { DrawerHeader, Main } from "./style";
import { CssBaseline, Grid } from "@mui/material";

const QuickSearchPage = () => {
  // URL parameters for pagination
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromParams = parseInt(searchParams.get("page") || "1", 10);

  // States for search & filters
  const [selectExpert, setSelectExpert] = useState(false);
  const [selectedCards, setSelectedCards] = useState<selectedCardsTypes>([]);
  const [filterPayload, setFilterPayload2] = useState<TFilterPayload>(defaultFilterPayload);
  const [page, setPage] = useState(pageFromParams);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [searchIn, setSearchIn] = useState<string[]>([]);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKeywords, setSelectedKeywords] = useState<Record<string, boolean>>({});
  const [chips, setChips] = useState<string[]>(search.trim() !== "" ? search.split(DELIMITER) || [] : []);

  // States for modals/dialogs
  const [addToProjectOpen, setAddToProjectOpen] = useState(false);
  const [addToSEOpen, setAddToSEOpen] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState<{ label: string; value: number }>({ label: "", value: 0 });
  const [dialogs, setDialogs] = useState<any>(dialogsDefaultValue);
  const [openStageShowDialog, setOpenStageShowDialog] = useState(false);
  const [stagingResponse, setStagingResponse] = useState(stagingResponseDefaultValue);
  const [alertNBackdrop, setAlertNBackdrop] = useState<AlertNBackdrop>({ alert: false, backdrop: false });
  const [selectedAction, setSelectedAction] = useState<SelectedAction>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const handleCloseDrawer = () => setOpenDrawer(false);

  // Advanced filters
  const [andvancedFilters, setAdvancedFilters] = useState<PayloadAdvancedFilters>([]);

  // State for profile details (fetched for the drawer)
  const [apiData, setApiData] = useState<any>(null);

  // Instead of using an index, store the selected expert's ID.
  const [selectedExpertId, setSelectedExpertId] = useState<number | null>(null);
  const closeDrawer = () => setSelectedExpertId(null);

  // Reset drawer state when key parent parameters change
  useEffect(() => {
    setSelectedExpertId(null);
    setApiData(null);
  }, [page, search, rowsPerPage]);

  // Create a cache for profile details (keyed by expert ID)
  const profileCache = useRef(new Map<number, any>());

  const fetchData = async () => {
    setIsLoading(true);
    const constructedFilterPayload: any = constructFilterPayload(filterPayload);
    const result = await getQuickSearchData(page, rowsPerPage, search, searchIn, constructedFilterPayload, andvancedFilters);
    setData(result?.data || []);
    setTotalPages(result?.totalPages || 1);
    setTotalResults(result?.total || 0);
    setIsLoading(false);
  };
  // Fetch quick search data
  useEffect(() => {
    fetchData();
  }, [page, search, rowsPerPage, toggleSearch, filterPayload, andvancedFilters, searchIn]);

  // Update URL 'page' parameter when page changes
  useEffect(() => {
    setSearchParams({ page: page.toString() });
  }, [page, setSearchParams]);

  useExpertFilterEvents({ ...filterPayload, searchIn, andvancedFilters, search }, "QuickSearchPage");

  // Fetch profile details when a drawer is open for a selected expert.
  // Use cached data if available.
  useEffect(() => {
    if (selectedExpertId !== null) {
      if (profileCache.current.has(selectedExpertId)) {
        setApiData(profileCache.current.get(selectedExpertId));
        return;
      }
      getAllProfileDetails(selectedExpertId, setApiData);
    }
  }, [selectedExpertId, data]);
  // Set Cache
  useEffect(() => {
    if (selectedExpertId !== null && apiData) {
      profileCache.current.set(selectedExpertId, apiData);
    }
  }, [selectedExpertId, apiData]);

  const handleSearch = (text: string) => {
    setSearch(text);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => setPage(newPage);

  const handleRowsPerPageChange = (value: string) => {
    const newRowsPerPage = parseInt(value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  const handleOpenAddExpert = (expert: any) => {
    setSelectedExpert({ label: expert?.name, value: expert?.id });
    setAddToProjectOpen(true);
  };

  const handleCloseAddToProject = () => setAddToProjectOpen(false);

  const handleOpenSE = (expert: any) => {
    setSelectedExpert({ label: expert?.name, value: expert?.id });
    setAddToSEOpen(true);
  };

  const handleCloseSE = () => setAddToSEOpen(false);

  const handleStageSubmit = (res: any) => {
    console.log("Submit:", res);
  };

  const addToProjectClickHandler = () => setDialogs((prev: any) => ({ ...prev, addToProject: true }));

  const addToStagingClickHandler = () => setDialogs((prev: any) => ({ ...prev, addToStaging: true }));

  const selectClickHandler = () => setSelectExpert((prev) => !prev);

  const methods = useForm<andBoxForm>({ defaultValues: { andBoxForms: [advancedFilterDefaultForm] } });

  const onApplyFilter: SubmitHandler<andBoxForm> = (data) => {
    const andFilters = transformForPayload(data.andBoxForms, methods.setError);
    if (andFilters.length > 0) {
      setAdvancedFilters(andFilters);
      setOpenDrawer(false);
      setPage(1);
    }
  };

  const isSearchCriteriaEmpty = (): boolean => {
    return (
      search === "" &&
      searchIn.length === 0 &&
      andvancedFilters.length === 0 &&
      data.length === 0 &&
      filterPayload.otherSearchFilter.id === "" &&
      filterPayload.otherSearchFilter.name === "" &&
      filterPayload.otherSearchFilter.email === "" &&
      filterPayload.otherSearchFilter.mobile === "" &&
      filterPayload.badgeFilter.length === 0 &&
      filterPayload.statusFilter.length === 0
    );
  };

  // --- Navigation Handlers using selectedExpertId ---
  const handleChevronLeft = () => {
    const currentIndex = data.findIndex((item) => item.id === selectedExpertId);
    if (currentIndex > 0) {
      setSelectedExpertId(data[currentIndex - 1].id);
    }
  };

  const handleChevronRight = () => {
    const currentIndex = data.findIndex((item) => item.id === selectedExpertId);
    if (currentIndex !== -1 && currentIndex < data.length - 1) {
      setSelectedExpertId(data[currentIndex + 1].id);
    }
  };

  const onClickRefresh = () => fetchData()

  return (
    <PageLayout>
      <CssBaseline />
      <Main open={!!selectedExpertId}>
        <DrawerHeader />
        <Box sx={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "#F5F5F5" }}>
          <AppBarCommon
            title="Quick Search - Experts"
            isSearch={false}
            isUserIcon
            isIconDefine={false}
            appBarWrapperStyle={{ alignItems: "flex-start" }}
            isQuickSearch={true}
            quickSearchComponent={
              <QuickSearchComponent
                forceSearch={() => setToggleSearch(!toggleSearch)}
                searchLabel="Search"
                chips={chips}
                setChips={setChips}
                onSearch={handleSearch}
                searchValue={search}
                searchIn={searchIn}
                selectedKeywords={selectedKeywords}
                setSelectedKeywords={setSelectedKeywords}
                setSearchIn={setSearchIn}
              />
            }
            isFilter={true}
            isAdvanceFilter={andvancedFilters.length > 0}
            onFilterClick={() => setOpenDrawer(true)}
          />
          <NavbarCommon
            selectActionSubmitBtnName={"Select"}
            selectedAction={selectedAction}
            onClickRefresh={onClickRefresh}
            onActionSelect={(action) => setSelectedAction(action)}
            selectClickHandler={selectClickHandler}
            isSelectClicked={selectExpert}
            Actions={NavbarActions(addToProjectClickHandler, addToStagingClickHandler)}
            NavbarItems={
              <QuickSearchNavbarItems
                setChips={setChips}
                setSearch={setSearch}
                filterPayload={filterPayload}
                setFilterPayload={setFilterPayload2}
                setAdvancedFilter={setAdvancedFilters}
                resetAdvanceFilter={methods.reset}
                setPage={setPage}
                setSearchIn={setSearchIn}
                setSelectedKeywords={setSelectedKeywords}
              />
            }
            totalSelected={selectedCards.length}
            ActionsLength={NavbarActionsLength}
            isFilterApplied={false}
            resetFilters={() => { }}
          />
        </Box>
        <FormProvider {...methods}>
          <FilterDrawer
            setAdvancedFilter={setAdvancedFilters}
            onSubmit={methods.handleSubmit(onApplyFilter)}
            openDrawer={openDrawer}
            handleCloseDrawer={handleCloseDrawer}
          />
        </FormProvider>
        <Box marginY={2}>
          {isSearchCriteriaEmpty() ? (
            <p
              style={{
                textAlign: "center",
                color: "black",
                fontSize: "18px",
                fontWeight: "500",
                marginLeft: "29px",
              }}
            >
              Type in searchbar.....
            </p>
          ) : isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={200} />
          ) : data.length > 0 ? (
            <Box>
              <Grid container>
                {data.map((item) => (
                  <Grid item key={item.id} xs={12} sm={6} md={12} >
                    <IndividualCards
                      isDrawerExpaned={selectedExpertId === item.id}
                      expandDrawer={() => setSelectedExpertId(item.id)}
                      selectExpert={selectExpert}
                      selectedCards={selectedCards}
                      setSelectedCards={setSelectedCards}
                      private_profile={item?.private_profile}
                      totalSelected={selectedCards.length}
                      key={item?.id}
                      page={page}
                      premium_expert={item?.premium_expert}
                      badge={item?.badge}
                      total_years_of_experience={item?.total_years_of_experience}
                      dnd_enabled={item?.dnd_enabled}
                      search={search}
                      name={item?.name}
                      id={item?.id}
                      workex={item?.workex}
                      primary_email={item?.primary_email}
                      primary_mobile={item?.primary_mobile}
                      approved_by={item?.approved_by}
                      highlights={item?.highlight || {}}
                      status={item?.status}
                      handleOpenAddExpert={() => handleOpenAddExpert(item)}
                      handleOpenSE={() => handleOpenSE(item)}
                    />
                  </Grid>
                ))}
              </Grid>
              {data.length > 0 && (
                <QuickProfileReview
                  data={apiData}
                  page={page}
                  isDrawerExpaned={!!selectedExpertId}
                  closeDrawer={closeDrawer}
                  expandLeftDrawer={handleChevronLeft}
                  expandRightDrawer={handleChevronRight}
                />
              )}
            </Box>

          ) : (
            <p>No results found</p>
          )}
        </Box>
        {data.length > 0 && (
          <PaginationComponent
            page={page}
            totalPages={totalPages}
            totalResult={totalResults}
            paginationHandler={(e, newPage) => handlePageChange(newPage)}
            dropdownFilterProps={{
              link: "",
              setFilterPayload: handleRowsPerPageChange,
              dropDownItems: [
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "75", value: "75" },
                { label: "100", value: "100" },
              ],
              filterValue: rowsPerPage.toString(),
            }}
          />
        )}

        <AddToProjectExperts
          isOpen={dialogs.addToProject}
          handleClose={() => handleSubmitClose(setDialogs)}
          selectedCards={selectedCards}
          setBackdrop={(bool: boolean) =>
            setAlertNBackdrop((prev) => ({ ...prev, backdrop: bool }))
          }
        />

        <AddToStaging
          isOpen={dialogs.addToStaging}
          handleClose={() => handleSubmitClose(setDialogs)}
          selectedCards={selectedCards}
          handleStageSubmit={(response: any) => handleStageSubmit(response)}
        />

        <AfterStagingDialog
          stageResponse={stagingResponse}
          isOpen={openStageShowDialog}
          handleClose={() => setOpenStageShowDialog(false)}
          selectedCards={selectedCards}
        />

        {addToProjectOpen && (
          <AddToProject
            isOpen={addToProjectOpen}
            handleClose={handleCloseAddToProject}
            handleFormChange={() => { }}
            handleSubmitClose={handleCloseAddToProject}
            selectedExpert={selectedExpert}
          />
        )}

        {addToSEOpen && (
          <AddToStaging
            isOpen={addToSEOpen}
            handleClose={handleCloseSE}
            handleStageSubmit={handleStageSubmit}
            selectedCards={[selectedExpert]}
          />
        )}
      </Main>
    </PageLayout>
  );
};

export default QuickSearchPage;

