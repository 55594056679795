import dayjs from "dayjs";
import { isClient, isAdmin, isSuperAdmin, isExpert, isInfollion, isFinance } from "../../utils/role";

export type TBannerConfig = {
  paths: string[];
  bgColor: string;
  bannerId: string;
  message: string;
  fromDate?: string;
  isActive: boolean;
  toDate?: string;
  roles: ("INFOLLION" | "EXPERT" | "SUPER_ADMIN" | "ADMIN" | "CLIENT" | "ALL" | "FINANCE")[]
}

export type TBannerBox = {
  banner: TBannerConfig;
  removeBanner: () => void;
  currentBannerIndex: number;
  index: number
};

export const bannerConfigurations: TBannerConfig[] = [
  {
    bannerId: 'SWEQSC123CW',
    paths: ['ALL'],
    bgColor: 'red',
    isActive: true,
    fromDate: "24/03/2025",
    toDate:'31/03/2025',
    message: "Due to our financial year-end procedures, new payment request submissions will be temporarily disabled from March 28th to March 31st. Services shall resume from April 1st, 2025",
    roles: ['SUPER_ADMIN', 'ADMIN', 'FINANCE', "EXPERT"],
  },
];

// Helper function to check if user has one of the roles required by the banner
export const userHasRequiredRole = (banner: TBannerConfig): boolean => {
  // If banner roles includes 'ALL', show to everyone
  if (banner.roles.includes('ALL')) {
    return true;
  }

  // Check if the user has at least one of the roles required by the banner
  return banner.roles.some(role => {
    switch(role) {
      case "ADMIN":
        return isAdmin();
      case "SUPER_ADMIN":
        return isSuperAdmin();
      case "EXPERT":
        return isExpert();
      case "CLIENT":
        return isClient();
      case "INFOLLION":
        return isInfollion();
      case "FINANCE":
        return isFinance();
      default:
        return false;
    }
  });
};

export const isBannerVisible = (banner: TBannerConfig): boolean => {
  // First, check if the banner is active
  if (!banner.isActive) {
    return false;
  }

  // Check if the current date is within the date range
  const now = dayjs().tz('Asia/Kolkata').startOf('day')
  let isWithinDateRange = true;

  // Check if the current date is between the fromDate and toDate
  if (banner.fromDate && banner.toDate) {
    const fromDate = dayjs(banner.fromDate, "DD/MM/YYYY");
    const toDate = dayjs(banner.toDate, "DD/MM/YYYY");
    isWithinDateRange = now.isBetween(fromDate, toDate, null, "[]");
  }
  // Check if the current date is same of after the from date
  else if (banner.fromDate && !banner.toDate) {
    const fromDate = dayjs(banner.fromDate, "DD/MM/YYYY");
    isWithinDateRange = now.isSame(fromDate, 'day') || now.isAfter(fromDate, 'day');
  }
  // Check if the current date is same of before the to date
  else if (!banner.fromDate && banner.toDate) {
    const toDate = dayjs(banner.toDate, "DD/MM/YYYY");
    isWithinDateRange = now.isSame(toDate, 'day') || now.isBefore(toDate, 'day');
  }

  // If not within date range, banner is not visible
  if (!isWithinDateRange) {
    return false;
  }

  // Check if the user has the required role
  return userHasRequiredRole(banner);
};