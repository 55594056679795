import { isAdmin, isSuperAdmin } from '../../../utils/role';
import NavbarCommon from '../../nav-bar-common';
import ExchangeRateNavbarItems from './navbarItems';
import { Tabs, Tab } from '@mui/material';

type Props = {
    isFilterApplied: boolean;
    resetFilters(): void;
    selectedTab: number;  
    handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;  

}

const ExchangeRateNavbar = ({
    isFilterApplied,
    resetFilters,
    selectedTab,
    handleTabChange,
}: Props) => {

    return (
        <>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Exchange Rate Tabs"
                sx={{ marginRight: "auto" }}
            >
                <Tab label="Payable" />
                {(isAdmin() || isSuperAdmin()) && ( 
                    <Tab label="Chargeable" />
                )}
            </Tabs>
            <NavbarCommon
                isSelectClicked={false}
                isSelectApplied={false}
                NavbarItems={<ExchangeRateNavbarItems />}
                resetFilters={resetFilters}
                isFilterApplied={isFilterApplied}
                selectActionSubmitBtnName='Choose Experts'
            />
        </>
    )
}

export default ExchangeRateNavbar