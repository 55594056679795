import { Grid } from "@mui/material";
import DialogModal from "../../../../../atoms/dialog";
import { Data } from "../../list-view/types";
import { LocalDayjs } from "../../../../../utils/timezoneService";
import { useMemo } from "react";
import { getAvgRating } from "../../helper";
import CustomBtnFilled from "../../../../../atoms/form-molecules/CustomBtnFilled";
import { isSuperAdmin } from "../../../../../utils/role";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  data: Data;
  openAddRatingDialog: () => void;
}

export default function ViewRatings({ isOpen, handleClose, data, openAddRatingDialog }: Props) {
  // for snippet
  const relevancy_rating = useMemo(() => {
    return data.pe_ratings.filter(r => r.ques_id === "Relevancy");
  }, [data.pe_ratings.length]);

  const project_relevancy_rating = useMemo(() => {
    return data.pe_ratings.filter(r => r.ques_id === "Project");
  }, [data.pe_ratings.length]);

  const seniority_rating = useMemo(() => {
    return data.pe_ratings.filter(r => r.ques_id === "Seniority");
  }, [data.pe_ratings.length]);

  const pe_ratings_length = useMemo(() => {
    return data.pe_ratings.filter(r => r.ques_id === "Project").length;
  }, [data.pe_ratings.length])
  console.log('pe ratings', data.pe_ratings);

  return (
    <DialogModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={`Ratings: ${getAvgRating(data.pe_ratings)}/ 10 ( ${pe_ratings_length} rating${pe_ratings_length > 1 ? "s" : ""} )`}
      TitleEl={
        <>
          {
            (data.status !== "Rejected" && (isSuperAdmin() || data.is_group_admin || data.is_cem)) ?
              <CustomBtnFilled
                label="Add Rating"
                variant="contained"
                onClick={openAddRatingDialog}
              /> : <></>
          }
        </>
      }
    >
      <Grid container sx={{ mt: "10px", "& p": { fontSize: "13px", padding: '10px' } }}>
        <Grid xs={2.5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Project Relevancy</p>
        </Grid>
        <Grid xs={2.5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Rated By</p>
        </Grid>
        <Grid xs={2} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Rated On</p>
        </Grid>
        <Grid xs={5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Remarks</p>
        </Grid>
        {project_relevancy_rating.map(r => (
          <>
            <Grid xs={2.5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rating}</p>
            </Grid>
            <Grid xs={2.5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rated_by_value.name}</p>
            </Grid>
            <Grid xs={2} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rated_on ? LocalDayjs(r.rated_on).format("DD MMM YY") : ""}</p>
            </Grid>
            <Grid xs={5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.remark || "-"}</p>
            </Grid>
          </>
        ))}
      </Grid>

      <Grid container sx={{ mt: "10px", "& p": { fontSize: "13px", padding: '10px' } }}>
        <Grid xs={2.5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Seniority</p>
        </Grid>
        <Grid xs={2.5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Rated By</p>
        </Grid>
        <Grid xs={2} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Rated On</p>
        </Grid>
        <Grid xs={5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Remarks</p>
        </Grid>
        {seniority_rating.map(r => (
          <>
            <Grid xs={2.5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rating}</p>
            </Grid>
            <Grid xs={2.5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rated_by_value.name}</p>
            </Grid>
            <Grid xs={2} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rated_on ? LocalDayjs(r.rated_on).format("DD MMM YY") : ""}</p>
            </Grid>
            <Grid xs={5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.remark || "-"}</p>
            </Grid>
          </>
        ))}
      </Grid>

      <Grid container sx={{ mt: "10px", "& p": { fontSize: "13px", padding: '10px' } }}>
        <Grid xs={2.5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Relevancy (Snippet)</p>
        </Grid>
        <Grid xs={2.5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Rated By</p>
        </Grid>
        <Grid xs={2} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Rated On</p>
        </Grid>
        <Grid xs={5} item sx={{ placeItems: "center", fontWeight: "600", border: "1px solid rgba(0,0,0,0.3)" }}>
          <p>Remarks</p>
        </Grid>
        {relevancy_rating.map(r => (
          <>
            <Grid xs={2.5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rating}</p>
            </Grid>
            <Grid xs={2.5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rated_by_value.name}</p>
            </Grid>
            <Grid xs={2} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.rated_on ? LocalDayjs(r.rated_on).format("DD MMM YY") : ""}</p>
            </Grid>
            <Grid xs={5} item sx={{ placeItems: "center", border: "1px solid rgba(0,0,0,0.3)" }}>
              <p>{r.remark || "-"}</p>
            </Grid>
          </>
        ))}
      </Grid>
    </DialogModal>
  )
}