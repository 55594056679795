import { Box } from "@mui/material";
import FilterBox from "./FilterBox";
import DynamicFields from "./DynamicFields";
import { HookAutoComplete } from "../../atoms/form-fields/SLFieldAutoComplete";
import { filterOnDropdownFields, filterConfig, defaultOrFields } from "../../pages/Quick-Search/constant";
import { useHookFormContext } from "../../utils/hooks/useHookFormContext";
import { useFieldArray } from "react-hook-form";
import { filterOnFieldProps } from "./types";
import { listOptions } from "./helper";
import Grid from '@mui/material/Grid';
import { useBoolean } from "usehooks-ts";

export const FilterOnField = ({ andIndex, domains, countries }: filterOnFieldProps) => {
  const { control, registerState, setValue, getValues, clearErrors } = useHookFormContext()
  const {
    remove: removeOrBox,
    fields: orFormElements,
    replace: replaceOrBox,
    insert: insertOrBox
  } =
    useFieldArray({
      control,
      name: `andBoxForms.${andIndex}.orFormElements`,
    });

  const handleFilterTypeChange = (orIndex: number, value: any) => {
    const selectedFilterType = value?.label;
    if (selectedFilterType && filterConfig[selectedFilterType]) {
      setValue(
        `andBoxForms.${andIndex}.orFormElements.${orIndex}.filters`,
        [...filterConfig[selectedFilterType].filters]
      );
    }
    clearErrors();
  };

  const handleRemoveOrBox = (orIndex: number) => {
    orFormElements.length > 1
      ? removeOrBox(orIndex)
      : replaceOrBox(defaultOrFields);
  };

  const toggleCustomForm = (andIndex: number, orIndex: number, selectedFilter: any, isCustomField: boolean, toggleIsCustomField: () => void) => {
    const selectedFilterType = selectedFilter?.value?.label
    toggleIsCustomField()
    if (isCustomField && selectedFilterType) {
      setValue(
        `andBoxForms.${andIndex}.orFormElements.${orIndex}.filters`,
        [...filterConfig[selectedFilterType].filters]
      );
    } else {
      setValue(`andBoxForms.${andIndex}.orFormElements.${orIndex}.filters`, [...filterConfig["Custom"].filters])
    }
  }
  return (
    <Box width={"100%"} justifyItems={"center"}>
      {orFormElements.map((filterOnField: any, orIndex: number) => {
        const { value: isCustomField, toggle: toggleIsCustomField } = useBoolean(false)
        const filtersLength = filterOnField?.filters.length
        const selectedFilterOn = getValues(`andBoxForms.${andIndex}.orFormElements.${orIndex}`)
        return (
          <Box width={"100%"} key={`${andIndex}-${orIndex}`}>
            <FilterBox
              onMakeDependent={() => toggleCustomForm(andIndex, orIndex, selectedFilterOn, isCustomField, toggleIsCustomField)}
              onAdd={() => insertOrBox(orIndex + 1, defaultOrFields)}
              showDisabled={orIndex >= 1}
              isAnd={false}
              onRemove={() => handleRemoveOrBox(orIndex)}
              isCustomField={isCustomField}
              selectedFilterOnInput={selectedFilterOn}
            >
              <Grid container columns={12} rowSpacing={1.5} columnSpacing={0.5}>
                <Grid item xs={12} sm={6} md={isCustomField ? 4 : filtersLength === 3 ? 3 : 4} flex={1}>
                  <HookAutoComplete
                    key={filterOnField.id}
                    {...registerState(`andBoxForms.${andIndex}.orFormElements.${orIndex}.value`)}
                    isWrapperInputField={true}
                    staticPlaceholderForInputField={getValues(`andBoxForms.${andIndex}.orFormElements.${orIndex}.value.label`)}
                    autocompleteProps={{
                      autoHighlight: true,
                      onChange: (_, value: any) => {
                        handleFilterTypeChange(orIndex, value);
                      },
                      options: filterOnDropdownFields,
                      getOptionLabel: (option) => option.label,
                      isOptionEqualToValue: (option, value) => {
                        return option.id === value.id
                      },
                      size: 'small',
                      renderOption: listOptions,
                    }}
                    rules={{
                      required: { value: !!filterOnField.required, message: "This field is required" }
                    }}
                    textFieldProps={{
                      label: "Filter On",
                      size: "small",
                      required: !!filterOnField.required,
                    }}
                  />
                </Grid>
                <DynamicFields
                  formArrayPath={`andBoxForms.${andIndex}.orFormElements.${orIndex}.filters`}
                  andIndex={andIndex}
                  orIndex={orIndex}
                  domains={domains}
                  countries={countries}
                />
              </Grid>
            </FilterBox>
          </Box>
        );
      })}
    </Box>
  )
}




export default FilterOnField;

