import { HeadCell } from "../../../molecules/table-view-common/types";

export const headCells: (selectedTab: number) => readonly HeadCell[] = (
  selectedTab
) => {
  if (selectedTab === 0) {
    return [
    {
        id: "date",
        numeric: false,
        disablePadding: true,
        label: "Date",
        isSort: true,
    },
    {
        id: "currency",
        numeric: false,
        disablePadding: false,
        label: "Currency",
        isSort: true,
    },
    {
        id: "buy_rate",
        numeric: true,
        disablePadding: false,
        label: "Buy Rate",
        isSort: true,
    },
]
  } else {
    return [
      {
        id: "date",
        numeric: false,
        disablePadding: true,
        label: "Date",
        isSort: true,
      },
      {
        id: "other_currency",
        numeric: false,
        disablePadding: false,
        label: "Currency",
        isSort: true,
      },
      {
        id: "exchange_rate",
        numeric: true,
        disablePadding: false,
        label: "Chargeable Rate",
        isSort: true,
      },
    ];
  }
};
