import { SxProps, Typography } from "@mui/material";
import { HTMLAttributes } from "react";
import { AutocompleteRenderOptionState, AutocompleteOwnerState } from "@mui/material/Autocomplete";
import { DropdownOption } from "../../pages/Quick-Search/types";
import { FormFieldType } from "./types";

export const listOptions = (
  props: HTMLAttributes<HTMLLIElement>,
  option: DropdownOption,
  state: AutocompleteRenderOptionState,
  ownerState: AutocompleteOwnerState<DropdownOption, false, false, false, "div">,
  labelStyle?: SxProps
) => {
  return (
    <li {...props} key={option.id}>
      <Typography sx={{ ...labelStyle, fontSize: "13px" }}>{option.label}</Typography>
    </li>
  );
};

export const getFieldType = (inputElement: any): FormFieldType => {
    if (inputElement?.field === "includeExclude" || inputElement?.field === "currentPast") {
      return FormFieldType.WATCH_AUTOCOMPLETE;
    } else if (inputElement.field === 'Company') {
      return FormFieldType.COMPANY;
    } else if (inputElement.type === 'freeSolo' && inputElement.field === 'otherDomains') {
      return FormFieldType.FREE_SOLO;
    } else if (inputElement?.type === 'textInput') {
      return FormFieldType.TEXT_INPUT;
    } else if (inputElement.field === 'Geography') {
      return FormFieldType.GEOGRAPHY;
    } else if (inputElement.field === 'Domain') {
      return FormFieldType.DOMAIN;
    } else if (inputElement.type === 'datePicker') {
      return FormFieldType.DATE_PICKER;
    }
    return FormFieldType.UNKNOWN;
  };