import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, FormControl, InputLabel, Select, MenuItem, Box, Paper, Divider, Chip, CircularProgress, IconButton } from "@mui/material";
import { useState } from "react";
import CustomBtnFilled from "../../atoms/form-molecules/CustomBtnFilled";
import { APIRoutes } from "../../constants";
import { RequestServer } from "../../utils/services";
import { getUserId } from "../../utils/role";
import { IdentityVerifications } from "../../constants/veriication.constants";
import VerifiedIcon from '@mui/icons-material/Verified';
import BadgeIcon from '@mui/icons-material/Badge';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";
import { commonInputStyles } from "../../common/input-styles";
import { HookTextField } from "../../atoms/form-fields/SLFieldTextField";
import BasicAutocomplete from "../autocompletes/basic-autocomplete";

interface VerifyExpertDialogProps {
  open: boolean;
  onClose: () => void;
  expertId: string;
}

const VerifyExpertDialog = ({ open, onClose, expertId }: VerifyExpertDialogProps) => {
  const [verifySource, setVerifySource] = useState("LinkedIn");
  const [loading, setLoading] = useState(false);
  const userId = getUserId() || "0";
  const { enqueueSnackbar } = useSnackbar();

  const handleVerifyClick = async () => {
    try {
      setLoading(true);
      const verificationData = {
        card_id: expertId,
        table_name: "expert",
        type: "Identity",
        source: verifySource,
        is_manual: true,
        verified_by: userId
      };

      const response: any = await RequestServer(
        APIRoutes.VERIFY_EXPERT,
        "POST",
        verificationData
      );
      console.log(response);
      setLoading(false);
      enqueueSnackbar(response.message, {
        variant: "success",
      });
      onClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      enqueueSnackbar("Something Went Wrong", {
        variant: "warning",
      });
    }
  }

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      fullWidth 
      maxWidth="sm"
      PaperProps={{
        style: { 
          borderRadius: '12px',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        pb: 0.5,
        pt: 1.5
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6">Verify Expert</Typography>
        </Box>
        <IconButton 
          aria-label="close" 
          onClick={onClose} 
          size="small" 
          sx={{ 
            color: 'grey.500',
            '&:hover': {
              color: 'grey.700',
            } 
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ py: 1.5 }}>
        <Paper
          elevation={0}
          sx={{
            p: 1.5,
            mb: 1.5,
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9'
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.8 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ width: '150px' }}>
                Expert ID:
              </Typography>
              <Typography variant="body2" fontWeight="medium">
                {expertId}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle2" color="text.secondary" sx={{ width: '150px' }}>
                Verification Type:
              </Typography>
              <Typography variant="body2" fontWeight="medium">
                Identity
              </Typography>
            </Box>
          </Box>
        </Paper>
        
        <FormControl fullWidth size="small" sx={{ mt: 0.5 }}>
          <InputLabel id="verify-source-label" sx={{ fontSize: '0.875rem' }}>Verified from</InputLabel>
            <Select
            labelId="verify-source-label"
            value={verifySource}
            label="Verified from"
            onChange={(e) => setVerifySource(e.target.value)}
            disabled={loading}
            size="small"  
            variant="outlined"
            sx={{ 
              height: '36px',
              borderRadius: '8px',
              '& .MuiSelect-select': {
                padding: '6px 12px',
                lineHeight: 1.2
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e0e0e0'
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EC9324'
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EC9324'
              }
            }}
            >
            {IdentityVerifications.map((source) => (
              <MenuItem key={source} value={source} dense>
              {source}
              </MenuItem>
            ))}
            </Select>
        </FormControl>
        
      </DialogContent>
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <CustomBtnFilled
          label="Cancel"
          onClick={onClose}
          variant="outlined"
          startIcon={<CloseIcon />}
          disabled={loading}
          sx={{ borderRadius: '8px', py: 0.5 }}
        />
        <CustomBtnFilled
          label={loading ? "Verifying..." : "Mark As Verified"}
          onClick={handleVerifyClick}
          variant="contained"
          startIcon={loading ? <CircularProgress size={18} color="inherit" /> : <VerifiedIcon />}
          disabled={loading}
          sx={{ borderRadius: '8px', py: 0.5 }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default VerifyExpertDialog;