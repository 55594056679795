export const markContactedThroughDropDownItems = [
    {
        label:"Naukri",
        value:"Naukri"
    },
    {
        label:"LinkedIn",
        value:"LinkedIn"
    },
    {
        label:"Lusha",
        value:"Lusha"
    },
    {
        label:"Signal Hire",
        value:"Signal Hire"
    },
    {
        label:"Rocket Reach",
        value:"Rocket Reach"
    },
    {
        label:"Contact Out",
        value:"Contact Out"
    },
    {
        label:"Apollo.ai",
        value:"Apollo.ai"
    },
    {
        label:"Other",
        value:"Other"
    }
];
