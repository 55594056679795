import { Box, Checkbox } from "@mui/material";
import CustomBtnFilled from "../../atoms/form-molecules/CustomBtnFilled";
import { ClientHeaderIcons } from "../../molecules/client-profile-header";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import React, { useMemo } from "react";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import TextOrIcon from '../../assets/images/expert/text-or-icon.png'
import TextAndIcon from '../../assets/images/expert/text-and-icon.png'

interface Props {
  onRemove: () => void;
  onAdd: () => void;
  onMakeDependent?: () => void;
  children: React.ReactNode;
  isAnd?: boolean,
  showDisabled: boolean
  selectedFilterOnInput?: any,
  isCustomField?: boolean
}

const FilterBox = ({ onMakeDependent, onAdd, onRemove, children, isAnd = false, showDisabled, selectedFilterOnInput, isCustomField }: Props) => {

  const canAddDependent = useMemo(
    () => ['Workex Location', 'Company', 'Date', 'Workex Designation', 'Workex Division'].includes(selectedFilterOnInput?.value?.id || ''),
    [selectedFilterOnInput]
  );
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  return (
    <Box px={isAnd ? 1.5 : 0} width={"100%"}>
      {showDisabled &&
        <Box display={"flex"} alignItems={"center"} justifyContent={'center'}>
          <CustomBtnFilled
            label={isAnd ? "AND" : "OR"}
            variant="outlined"
            disabled
            styles={{ fontSize: "10px", padding: "0px", minWidth: "58px", margin: "8px", alignSelf: 'center' }}
          >
            <AddIcon sx={{ fontSize: "10px", mr: "1px" }} />
          </CustomBtnFilled>
        </Box>
      }
      <Box display={"flex"} width={"100%"} alignItems={"flex-start"} justifyContent={'center'} >
        <Box border={isAnd ? 1.5 : 1} borderColor={isAnd ? "#EC9324" : "#ffb703"} flex={1} borderRadius={"15px"} flexDirection={'column'} display={'flex'} width={"100%"} p={1.2}>
          {children}
        </Box>
        <Box display={"flex"} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} mt={isAnd ? 1 : 0.3} gap={isAnd ? 0.7 : 0.3}>
          <ClientHeaderIcons
            title={`remove ${isAnd ? "AND" : "OR"}`}
            isMaterialIcon={true}
            isIcon={false}
            handleClick={onRemove}
            MaterialIcon={CloseIcon}
            iconStyle={{ color: "white" }}
            MaterialIconStyle={{ width: isAnd ? "14px" : "10px" }}
            style={{
              width: isAnd ? "18px" : "12px",
              height: isAnd ? "18px" : "12px",
              marginLeft: "10px",
              borderRadius: "30%"
            }}
          />
          {(canAddDependent) && (
            <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Checkbox
                size="small"
                sx={{
                  width: '13px',
                  alignSelf: 'center',
                  height: '13px',
                  marginLeft: '9px',
                  '& .MuiSvgIcon-root': {
                    fontSize: '14px',
                  },
                }}
                checked={selectedFilterOnInput?.filters?.length > 3 ? true : false}
                {...label}
                onClick={onMakeDependent}
                icon={<ChangeCircleOutlinedIcon />}
                checkedIcon={<ChangeCircleIcon />}
              />
            </Box>
          )}
          <ClientHeaderIcons
            title={`add ${isAnd ? "AND" : "OR"}`}
            isIcon={true}
            icon={isAnd ? TextAndIcon : TextOrIcon}
            handleClick={onAdd}
            iconStyle={{
              borderRadius: "30%",
              padding: '1px',
              ml: 1.2,
              alignSelf: 'center',
              width: isAnd ? "18px" : "16px",
              height: isAnd ? "18px" : "16px",
              backgroundColor: "#edede9",
              "&:hover": {
                backgroundColor: "#edede9",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterBox;