import { TableCell } from "@mui/material";
import { Data } from "../types";

type Props = {
    row: Data;
    labelId: string;
    isSelected: boolean;
    selectedTab?: number;
}

const TableCellsRow = ({ row, labelId, isSelected,selectedTab }: Props) => {

    return (
        <>
            <TableCell align="left">
                {row.date}
            </TableCell>
            {selectedTab === 0 ? (
                <>
            <TableCell align="left">
                {row.currency}
            </TableCell>
            <TableCell align="left">
                {row.buy_rate}
            </TableCell>
                </>
            ) : (
                <>
                    <TableCell align="left">{row.other_currency}</TableCell>
                    <TableCell align="left">
                        {row.exchange_rate ? (1 / row.exchange_rate).toFixed(2) : 'N/A'}
                    </TableCell>
        </>
            )}
        </>
    )
}

export default TableCellsRow;