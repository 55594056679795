import { Box, Drawer, Typography, Tabs, Tab } from '@mui/material'
import React, { FormEventHandler, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import { tabStyle } from '../../molecules/calender-picker-dialog/helper';
import FilterOnField from '../../molecules/quick-search/FilterOnField';
import { useFieldArray } from 'react-hook-form';
import { useHookFormContext } from '../../utils/hooks/useHookFormContext';
import { advancedFilterDefaultForm } from '../../pages/Quick-Search/constant';
import CustomBtnFilled from '../../atoms/form-molecules/CustomBtnFilled';
import { useGeoFetch } from '../../utils/hooks/useGeoFetch';
import { useDomainFetch } from '../../utils/hooks/useFetchDomains';
import FilterBox from '../../molecules/quick-search/FilterBox';

interface FilterDrawerProps {
    openDrawer: boolean
    handleCloseDrawer: () => void
    onSubmit?: FormEventHandler<HTMLFormElement>
    setAdvancedFilter: React.Dispatch<React.SetStateAction<any[]>>;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const dividerStyles = {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    width: "80%",
    height: "0.5px",
    backgroundColor: "rgba(0, 0, 0, 0.12)"
}

const fullDivider = (<span style={{ ...dividerStyles, width: "100%" }}></span>)
const FilterDrawer = ({ openDrawer, handleCloseDrawer, onSubmit, setAdvancedFilter }: FilterDrawerProps) => {

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box>{children}</Box>}
            </div>
        );
    }
    const [valueTab, setValueTab] = useState<number>(0);
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const { control, reset } = useHookFormContext()
      const { onlyCountryList: countryGeoList } = useGeoFetch();
      const { combineLevelLabelDomains } = useDomainFetch();

    const { fields: andBoxForms, remove: removeAndBox, replace: replaceAndBox, insert: insertAndBox } =
        useFieldArray({
            control,
            name: "andBoxForms",
        });

    const onRemove = (andIndex: number) => {
        andBoxForms.length > 1 ? removeAndBox(andIndex) : replaceAndBox(advancedFilterDefaultForm)
    }
    return (
        <Drawer anchor="right" open={openDrawer} onClose={handleCloseDrawer}>
            <Box sx={{ width: 900 }}>
                {/* Header Section start */}
                <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                    <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
                        Advanced Search
                    </Typography>
                    <CloseIcon sx={{ cursor: "pointer" }} onClick={handleCloseDrawer} />
                </Box>
                {/* Header Section end */}
                {fullDivider}
                {/* Tabs section start */}
                {/* <Tabs
                    variant='fullWidth'
                    value={valueTab}
                    onChange={handleChange}
                    aria-label="Advanced-search-n-History-tabs"
                    sx={{ display: "flex", justifyContent: "center" }} // Center align tabs
                >
                    <Tab label="Advanced Search" {...a11yProps(0)} sx={{ ...tabStyle, fontSize: '14px' }} />
                    <Tab label="History" {...a11yProps(1)} sx={{ ...tabStyle, fontSize: '14px' }} />
                </Tabs> */}
                <Box>
                    <CustomTabPanel value={valueTab} index={0}>
                        <form onSubmit={onSubmit}>
                            <Box my={2} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} >
                                {andBoxForms?.map((andBoxForm, andIndex: number) => (
                                    <Box width={"100%"} justifyItems={"center"} key={andBoxForm.id}>
                                        <FilterBox
                                            showDisabled={andIndex >= 1}
                                            onAdd={() => insertAndBox(andIndex + 1, advancedFilterDefaultForm)}
                                            onRemove={() => onRemove(andIndex)}
                                            isAnd={true}
                                        >
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                                width={"100%"}
                                                alignItems={"center"}>
                                                <FilterOnField
                                                    andIndex={andIndex}
                                                    domains={combineLevelLabelDomains}
                                                    countries={countryGeoList}
                                                />
                                            </Box>
                                        </FilterBox>
                                    </Box>
                                )
                                )}
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 2, gap: 1 }}>
                                <CustomBtnFilled
                                    label="Apply Filter"
                                    variant="outlined"
                                    buttonType='submit'
                                />
                                <CustomBtnFilled
                                    label="Reset & Remove"
                                    variant="contained"
                                    onClick={() => {
                                        reset()
                                        setAdvancedFilter([])
                                    }}
                                />
                            </Box>
                        </form>
                    </CustomTabPanel>
                    {/* <CustomTabPanel value={valueTab} index={1}>
                        <Typography>this is history tab</Typography>
                    </CustomTabPanel> */}
                </Box>
                {/* Tabs section start */}
            </Box>
        </Drawer>
    )
}

export default FilterDrawer