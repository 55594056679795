import { FC, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { invalidHolderNameOptions, invalidPanOptions, isValid_IFSC_Code, msme_options, searchIFSC, verifyPan } from "../helper";
import { useHookFormContext } from "../../../utils/hooks/useHookFormContext";
import { HookCheckBox } from "../../../atoms/form-fields/SLFieldCheckBox";
import styles from "../style.module.scss";
import { RequiredTextField } from "../../../molecules/input-components/RequiredTextField";
import { HookRadioButton } from "../../../atoms/form-fields/SLFieldRadioButton";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import { useBoolean } from "../../../utils/hooks/useBoolean";
import UploadFiles from "../add-msme-certificate";
import { useBankContext } from "../context";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CountriesAutocomplete from "../../../molecules/input-components/countriesAutocomplete";
import { useFullPageLoading } from "../../../atoms/full-page-loading/loadingContext";
import { BoxFlex } from "../../../atoms/boxSpaceBtw";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { List, ListItem, ListItemIcon, Tooltip } from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpIcon from '@mui/icons-material/Help';

import { styled } from '@mui/material/styles';


const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const BankDetailsIndia = ({ isEdit }: { isEdit: boolean }) => {
  const { registerState, watch, setError, resetField, setValue, clearErrors } = useHookFormContext();
  const { setController, setMsmeUrl, msmeFileUrl, setDocVerification, docVerification, panUrl, setPanUrl } = useBankContext();
  const { setLoading: setFullLoading } = useFullPageLoading();
  const { value: isUploadMsme, setTrue: openUploadMsme, setFalse } = useBoolean();
  const { value: isUploadPan, setTrue: openUploadPan, setFalse: setPanFalse } = useBoolean();
  const { value: isNewUpload, setTrue: SetNewUpload } = useBoolean();
  const isGstin = watch("is_gstin");
  const isMsme = watch("msme") === "yes";
  const [invalid_holder_name_value, holder_name_value, pan_number_value] = watch(["invalid_holder_name", "holder_name", "pan_number"]);
  const debounceRef = useRef<any>(null);

  const closeUploadMsme = () => {
    setController({
      control: null,
      for: "",
      setSelectedFile: null,
    });
    setFalse();
  }

  const closeUploadPan = () => {
    setController({
      control: null,
      for: "",
      setSelectedFile: null,
    });
    setPanFalse();
  }

  function setIFSCError() {
    setError("ifsc_code", {
      type: "custom",
      message: "The IFSC is invalid",
    });
    resetField("bank_name");
    resetField("bank_address");
  }

  async function getbankDetails(ifscCode: string) {
    const isValidRegex = isValid_IFSC_Code(ifscCode);
    if (!isValidRegex) {
      setIFSCError();
    }
    try {
      setFullLoading(true);
      const response = await searchIFSC(ifscCode);

      if (response?.data) {
        setValue("bank_name", response.data.BANK);
        setValue("bank_address", response.data.ADDRESS);
        clearErrors("ifsc_code");
      }

      setFullLoading(false);
    } catch {
      setFullLoading(false);
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change") {
        if (name === "ifsc_code") {
          if (value.ifsc_code.length === 11) {
            getbankDetails(value.ifsc_code);
          } else {
            clearErrors("ifsc_code");
            resetField("bank_name");
            resetField("bank_address");
          }
        }

        if (name === "holder_name" || name === "pan_number") {
          if (debounceRef.current) {
            clearTimeout(debounceRef.current);
          }

          const holderName = value.holder_name;
          const panNumber = value.pan_number;

          if (name === "pan_number" && value.pan_number.length === 10) {
            (async () => {
              setValue("invalid_holder_name", null);
              setValue("invalid_doc", null);
              await verifyPan(value.holder_name, value.pan_number, setFullLoading, setDocVerification, docVerification);
            })();
          } else if (name === "holder_name" && panNumber?.length === 10) {
            setValue("invalid_holder_name", null);
            setValue("invalid_doc", null);
            debounceRef.current = setTimeout(async () => {
              await verifyPan(holderName, panNumber, setFullLoading, setDocVerification, docVerification);
            }, 700);
          }
        }
      }
    });

    return () => {
      subscription.unsubscribe();
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const doc_rejected = docVerification.state === "manual_verify_rejected";

  useEffect(() => {
    if (!isEdit) {
      setValue("account_holder_country", "India")
    } else {
      if (docVerification.state !== "document_verified" && docVerification.state !== "manual_verify_rejected") {
        (async () => {
          await verifyPan(holder_name_value, pan_number_value, setFullLoading, setDocVerification, docVerification);
        })();
      }

      if (doc_rejected) {
        setValue("invalid_holder_name", "pan_manual_verification");
      }
    }
  }, []);

  const invalidPanLabel = docVerification.state === "invalid_doc" ? "The PAN is invalid. Enter correct PAN or choose the option below:" : docVerification.state === "NON_OPERATIVE" ? "The PAN is Non-Operative. Enter correct PAN or choose the option below:" : ""
  const reasons_for_automatic_failure = (
    <div>
      <Typography variant="body2">
        Reasons for Automatic Verification Failure -
      </Typography>
      <div style={{ marginLeft: "1rem" }}>
        <List sx={{ listStyleType: 'disc' }} dense disablePadding>
          <ListItem sx={{ display: 'list-item' }} dense disablePadding>
            <Typography fontSize={10}>
              <b>Mismatch Between Account Holder Name and PAN Card Name</b>
            </Typography>
          </ListItem>
          <ListItem sx={{ paddingTop: "0px" }} dense>
            <List sx={{ listStyleType: 'circle' }} dense disablePadding	>
              <ListItem sx={{ display: 'list-item' }} dense disablePadding>The name on the PAN card and the bank account belong to two different individuals.</ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }} dense disablePadding>
            <Typography fontSize={10}>
              <b>Use of Full Name vs. Abbreviations</b>
            </Typography>
          </ListItem>
          <ListItem sx={{ paddingTop: "0px" }} dense >
            <List sx={{ listStyleType: 'circle' }} dense disablePadding	>
              <ListItem sx={{ display: 'list-item' }} dense disablePadding>Any discrepancy in name formatting, such as using initials or short forms instead of the full name.</ListItem>
              <ListItem sx={{ display: 'list-item' }} dense disablePadding>
                Example:
              </ListItem>
              <ListItem sx={{ paddingTop: "0px" }} dense>
                <List sx={{ listStyleType: 'square' }} dense disablePadding	>
                  <ListItem sx={{ display: 'list-item' }} dense disablePadding>PAN Card Name: Akash Kumar Singh</ListItem>
                  <ListItem sx={{ display: 'list-item' }} dense disablePadding>Account Holder Name: Akash K. Singh or Akash Kr. Singh</ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }} dense disablePadding>
            <Typography fontSize={10}>
              <b>Name Change After Marriage</b>
            </Typography>
          </ListItem>
          <ListItem sx={{ paddingTop: "0px" }} dense>
            <List sx={{ listStyleType: 'circle' }} dense disablePadding	>
              <ListItem sx={{ display: 'list-item' }} dense disablePadding>A difference in the last name due to marriage, leading to a mismatch between the PAN card and the bank account name.
              </ListItem>
              <ListItem sx={{ display: 'list-item' }} dense disablePadding>
                Example:
              </ListItem>
              <ListItem sx={{ paddingTop: "0px" }} dense>
                <List sx={{ listStyleType: 'square' }} dense disablePadding	>
                  <ListItem sx={{ display: 'list-item' }} dense disablePadding>PAN Card Name: Aditi Bajaj</ListItem>
                  <ListItem sx={{ display: 'list-item' }} dense disablePadding>Account Holder Name: Aditi Singh or Aditi Bajaj Singh</ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }} dense disablePadding>
            <Typography fontSize={10}>
              <b>Spelling Mistake in Account Holder Name</b>
            </Typography>
          </ListItem>
          <ListItem sx={{ paddingTop: "0px" }} dense>
            <List sx={{ listStyleType: 'circle' }} dense disablePadding	>
              <ListItem sx={{ display: 'list-item' }} dense disablePadding>Incorrect Spelling entered while adding Account Holder name</ListItem>
              <ListItem sx={{ display: 'list-item' }} dense disablePadding>
                Example:
              </ListItem>
              <ListItem sx={{ paddingTop: "0px" }} dense>
                <List sx={{ listStyleType: 'square' }} dense disablePadding	>
                  <ListItem sx={{ display: 'list-item' }} dense disablePadding>Account  Holder Name : Suarabh Singh</ListItem>
                  <ListItem sx={{ display: 'list-item' }} dense disablePadding>Actual Name : Saurabh Singh</ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </div>
    </div>
  )
  const invalid_holder_name_node = (<div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
    <Typography sx={{ color: "red", fontWeight: "500" }}>
      Automatic PAN verification has failed. Please choose one of the following options:
    </Typography>
    <NoMaxWidthTooltip
      arrow
      title={reasons_for_automatic_failure}
    >
      <Typography sx={{ color: "black", fontSize: "12px", cursor: "pointer", width: "fit-content" }}>
        <HelpIcon />
      </Typography>
    </NoMaxWidthTooltip>
  </div>)
  const invalid_holder_name_label = doc_rejected ? "PAN got rejected in manual pan verification. Please reupload the PAN or choose one of these options:" : invalid_holder_name_node;

  return (
    <>
      <Grid item xs={12} md={12} className={styles.inputRow}>
        <RequiredTextField
          id="bank_country"
          label="My Bank Account is in:"
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} className={styles.inputRow}>
        <RequiredTextField id="holder_name" label="Account Holder Name" />
      </Grid>


      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField
          id="account_holder_address"
          label="Account Holder's Address"
          max={140}
        />
      </Grid>

      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <CountriesAutocomplete
          id="account_holder_country"
          label="Account Holder's Residing country"
        />
      </Grid>
      <Grid item xs={12} className={styles.inputRow}>
        <RequiredTextField id="ifsc_code" label="IFSC Code" max={11} />
      </Grid>
      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField id="bank_name" label="Bank's Name" />
      </Grid>
      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField id="bank_address" label="Bank's Address *" max={1000} />
      </Grid>
      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField
          max={10}
          maxMsg="Please enter a valid PAN (10 characters)"
          id="pan_number"
          label="Pan Card No"
          pattern={{
            value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}$/,
            message: "Please Enter a valid PAN Number",
          }}
        />
        {docVerification.state === "document_verified" &&
          <BoxFlex sx={{ color: "green", gap: '0.2rem', "& p": { fontSize: "14px", fontWeight: "500" } }}>
            <CheckCircleIcon sx={{ fontSize: "14px" }} />
            <p>Pan Verified</p>
          </BoxFlex>
        }
      </Grid>
      <Grid item xs={12} sm={6} className={styles.inputRow}>
        <RequiredTextField
          id="account_number"
          label="Enter Bank Account Number"
          max={35}
        />
      </Grid>

      <Grid item xs={12}>
        {(docVerification.state === "invalid_doc" || docVerification.state === "NON_OPERATIVE") &&
          <HookRadioButton
            {...registerState("invalid_doc")}
            label={invalidPanLabel}
            labelStyle={{
              color: "red",
              fontWeight: "500"
            }}
            radioGroupProps={{
              sx: {
                "& .MuiTypography-root": {
                  marginLeft: "-5px !important"
                },
              },
            }}
            fields={invalidPanOptions}
          />}


        {(docVerification.state === "invalid_holder_name" || doc_rejected) &&
          <HookRadioButton
            {...registerState("invalid_holder_name")}
            label={invalid_holder_name_label}
            labelStyle={{
              color: "red",
              fontWeight: "500"
            }}
            radioGroupProps={{
              sx: {
                "& .MuiTypography-root": {
                  marginLeft: "-5px !important"
                },
              },
            }}
            fields={invalidHolderNameOptions}
          />}
      </Grid>

      {(invalid_holder_name_value === "pan_manual_verification" && (docVerification.state === "invalid_holder_name" || doc_rejected)) &&
        <Grid item xs={12}>
          {panUrl && ((doc_rejected && !isNewUpload) ? false : true) ?
            <BoxFlex sx={{ gap: "1rem" }}>
              <Link to={panUrl} target="_blank" rel="noopener noreferrer">
                <Typography sx={{
                  fontSize: "12px",
                  color: "var(--green-color)",
                  "&:hover": {
                    textDecoration: "underline"
                  },
                  "&:focus": {
                    textDecoration: "underline"
                  }
                }}>
                  View Uploaded Pan Card
                </Typography>
              </Link>
              <CustomBtnFilled
                label="Update PAN"
                variant="outlined"
                onClick={openUploadPan}
              />
            </BoxFlex> :
            <CustomBtnFilled
              label="Upload PAN"
              variant="outlined"
              onClick={openUploadPan}
            />}
        </Grid>
      }

      {doc_rejected && !isNewUpload &&
        <BoxFlex sx={{ flexDirection: "column", alignItems: "flex-start", mt: "10px" }}>
          <BoxFlex sx={{ color: "red", gap: '0.2rem', "& p": { fontSize: "12px", fontWeight: "500" } }}>
            <HighlightOffIcon sx={{ fontSize: "14px" }} />
            <p>PAN verification rejected, please update your document</p>
          </BoxFlex>
          {panUrl &&
            <>
              <Link to={panUrl} target="_blank" rel="noopener noreferrer">
                <Typography sx={{
                  fontSize: "12px",
                  color: "red",
                  textDecoration: "underline"
                }}>
                  View Rejected PAN Form
                </Typography>
              </Link>
            </>
          }
        </BoxFlex>
      }

      <Grid item xs={12} sm={12} className={styles.inputRow}>
        <HookCheckBox
          {...registerState("is_primary")}
          label="Set This Account as Primary"
        />
      </Grid>
      <Grid item xs={12} sm={isGstin ? 6 : 12} className={styles.inputRow}>
        <HookCheckBox
          {...registerState("is_gstin")}
          label="Do you have GSTIN?"
        />
      </Grid>

      {isGstin && (
        <>
          <Grid item xs={12} sm={6} className={styles.inputRow}>
            <RequiredTextField
              id="gstin"
              label="GSTIN"
              max={35}
              pattern={{
                value:
                  /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z1-9]){1}([a-zA-Z0-9]){1}$/,
                message: "Please Enter a valid GSTIN",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={styles.inputRow}>
            <RequiredTextField id="gst_name" label="Name" />
          </Grid>
          <Grid item xs={12} sm={6} className={styles.inputRow}>
            <RequiredTextField id="gst_address" label="Address" max={140} />
          </Grid>
        </>
      )}

      <Grid container item xs={12} sm={12} className={styles.inputRow}>
        <Grid item xs={12}>
          <HookRadioButton
            {...registerState("msme")}
            label="Are you registered under MSME act?"
            radioGroupProps={{
              sx: {
                "& .MuiTypography-root": {
                  marginLeft: "-5px !important",
                },
              },
            }}
            fields={msme_options}
          />
        </Grid>

        {isMsme &&
          (<Grid item xs={12}>
            {msmeFileUrl ?
              <Link to={msmeFileUrl} target="_blank" rel="noopener noreferrer">
                <Typography sx={{
                  fontSize: "12px",
                  color: "var(--green-color)",
                  "&:hover": {
                    textDecoration: "underline"
                  },
                  "&:focus": {
                    textDecoration: "underline"
                  }
                }}>
                  View MSME Certificate
                </Typography>
              </Link> :
              <CustomBtnFilled
                label="Upload  MSME Certificate"
                variant="outlined"
                onClick={openUploadMsme}
              />}
          </Grid>)
        }
      </Grid>

      {/* <Grid item xs={12} className={styles.inputRow}>
        <HookRadioButton
          {...registerState("itr")}
          label="Have you filed ITR for AY 2022-23 and AY 2023-24?"
          radioGroupProps={{
            sx: {
              "& .MuiTypography-root": {
                marginLeft: "-5px !important",
              },
            },
          }}
          fields={msme_options}
        />
      </Grid>


      {isItr ?
        <>
          <Grid item xs={12} className={styles.inputRow}>
            <RequiredTextField
              id="itr_2years_ago"
              label={isMobile ? "AY 2022-23 acknowledgement number of ITR" : "Please share the acknowledgement number of ITR for AY 2022-23"}
            />
          </Grid>
          <Grid item xs={12} className={styles.inputRow}>
            <RequiredTextField
              id="itr_previous_year"
              label={isMobile ? "AY 2023-24 acknowledgement number of ITR" : "Please share the acknowledgement number of ITR for AY 2023-24"}
            />
          </Grid>
        </> :
        noItr ?
          <Grid item xs={12}>
            <Typography sx={{ color: "red", fontWeight: "500" }}>
              TDS shall be deducted at 20% instead of 10%.
            </Typography>
          </Grid> :
          <></>
      } */}



      {/* Upload MSME Certificate */}
      {isUploadMsme &&
        <UploadFiles
          isOpen={isUploadMsme}
          handleClose={closeUploadMsme}
          handleSubmitClose={(url: string) => {
            setMsmeUrl(url);
            setFalse();
          }}
          isPanUpload={false}
        />
      }

      {/* Upload PAN */}

      {isUploadPan &&
        <UploadFiles
          isOpen={isUploadPan}
          handleClose={closeUploadPan}
          handleSubmitClose={(url) => {
            setPanUrl(url);
            setPanFalse();
            SetNewUpload();
            setDocVerification((prev) => ({ ...prev, state: "invalid_holder_name" }));
          }}
          isPanUpload={true}
        />
      }
    </>
  );
};

export default BankDetailsIndia;
