import { Dispatch, SetStateAction } from "react";
import { APIRoutes } from "../../constants";
import { RequestServer } from "../../utils/services";
import axios from "axios";

export type BankDetailsContext = {
  controller: any,
  setController: React.Dispatch<React.SetStateAction<any>>,
  msmeFileUrl: string,
  setMsmeUrl: React.Dispatch<React.SetStateAction<string>>,
  panUrl: string,
  setPanUrl: React.Dispatch<React.SetStateAction<string>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  docVerification: DocVerification,
  setDocVerification: SetDocVerification,
  wForm: WForm,
  setWForm: SetWForm;
}

export const invalidHolderNameOptions = [
  {
    label: "Deduct 20% TDS from my payments.",
    value: "consent_for_higher_tds"
  },
  {
    label: "Upload a copy of my PAN card for manual verification to apply the appropriate TDS rate.",
    value: "pan_manual_verification"
  },
]

export const invalidPanOptions = [
  {
    label: "Since I have not provided a valid PAN, please deduct 20% TDS on my payments.",
    value: "consent_for_higher_tds"
  }
]

export const emptyDefaultValues = {
  bank_country: "",
  ifsc_code: "",
  bank_name: "",
  bank_address: "",
  holder_name: "",
  pan_number: "",
  account_number: "",
  is_primary: false,
  is_gstin: false,
  gstin: "",
  gst_name: "",
  gst_address: "",
  add_intermediatry: false,
  intermediary_bank_name: "",
  intermediary_bank_address: "",
  intermediary_bank_swift_code: "",
  intermediary_bank_account_number: "",
  swift_code: "",
  routing_code: "",
  account_holder_address: "",
  account_holder_country: "",
  reason_for_different_country: "",
  iban: "",
  canada_code: "",
  bank_country_code: "",
  // itr: "",
  msme: "",
  invalid_holder_name: "pan_manual_verification",
  individual_option: "",
  ssn: "",
  entity_name: "",
  entity_type: "",
  ein: "",
  ftin: ""
  // itr_2years_ago: "",
  // itr_previous_year: ""
};

export const msme_options = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

export const commonInputStyles: any = {
  className: "backgroundWhite-description",
  size: "small",
  variant: "outlined",
  fullWidth: true,
  sx: { background: "white" },
};

export const BankCountry = [
  { label: "India", value: "India" },
  { label: "US", value: "US" },
];

export function isValid_IFSC_Code(ifsc_Code: string) {
  let regex = new RegExp(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/);

  if (ifsc_Code == null) {
    return false;
  }

  if (regex.test(ifsc_Code) === true) {
    return true;
  } else {
    return false;
  }
}

export async function searchIFSC(searchTerm: string) {
  const data = await axios.get("https://ifsc.razorpay.com/" + searchTerm);
  return data;
}

export type DocVerification = {
  state: "invalid_doc" | "invalid_holder_name" | "document_verified" | "manual_verify_rejected" | "API_NOT_WORKING_DO_MANUAL_VERIFICATION" | "NON_OPERATIVE" | null,
  name_on_pan: string | null,
  manually_verified_pan_cards: {account_holder_name: string, pan: string}[]
};
export type SetDocVerification = Dispatch<SetStateAction<DocVerification>>;

export type WForm = {
  w9: string | null,
  W8_BEN_url: string | null,
  W8_BEN_E_url: string | null,
};

export type SetWForm = Dispatch<SetStateAction<WForm>>;

export const submitIndiaForm = async (
  formData: any,
  id?: number,
  isEdit?: boolean,
  bank_id?: string,
  msmeFileUrl?: string,
  doc_verification?: DocVerification,
  pan_url?: string
) => {
  let payload: any = {
    is_primary: formData.is_primary,
    account_holder_name: formData.holder_name,
    account_number: formData.account_number,
    ifsc_code: (formData.ifsc_code as string).toLocaleUpperCase(),
    fk_expert: id,
    bank_name: formData.bank_name,
    bank_address: formData.bank_address,
    bank_country: formData.bank_country,
    pan: (formData.pan_number as string).toLocaleUpperCase(),
    bank_country_code: formData.bank_country_code,
    registered_under_msme_act: formData.msme === "yes" ? 1 : 0,
    account_holder_address: formData.account_holder_address,
    account_holder_residing_country: formData.account_holder_country,
    doc_verification: doc_verification?.state === "manual_verify_rejected" ? "invalid_holder_name" : doc_verification?.state
  };

  if (formData.is_gstin) {
    payload = {
      ...payload,
      gstin: formData?.gstin,
      gst_name: formData.gst_name,
      gst_address: formData.gst_address,
    };
  } else {
    payload = {
      ...payload,
      gstin: null,
      gst_name: null,
      gst_address: null,
    }
  }

  if (formData.msme === "yes" && msmeFileUrl) {
    payload.msme_certificate = msmeFileUrl;
  }

  // If Edit Form is Opened, Bank Details ID is neccessary
  if (isEdit && bank_id) {
    payload = {
      ...payload,
      id: bank_id,
    };
  }

  /*
    PAN VERIFICATION -
    
    If => doc_verification state is "document_verified", It means that Pan is verified simple

    If => doc_verification state is other than "document_verified"

       -- If => doc_verification state is "invalid_doc"
          VALUES:
          -- pan_url will be null
          -- name_on_pan will be null
          -- formData.invalid_doc valus will be true , which is a checkbox giving us consent to deduct higher TDS
          WHAT WE SENT TO BACKEND:
          -- consent_for_higher_tds: true
          -- document_verified: false

       -- If => doc_verification state is "invalid_holder_name"
          -- If user uploads the PAN for manual verification
            VALUES:
            -- pan_url will be a string
            -- formData.invalid_holder_name will be "pan_manual_verification"
            WHAT WE SENT TO BACKEND:
            -- pan_url: string
            -- document_verified: false

          -- If user gives the consent to deduct 20% TDS
            VALUES:
            -- pan_url will be null
            -- formData.invalid_holder_name will be "consent_for_higher_tds" => which will be sent to backend as a bool
            WHAT WE SENT TO BACKEND:
            -- consent_for_higher_tds: true 
            -- document_verified: false
  */

  if(doc_verification?.state === "document_verified") {
    payload = {
      ...payload,
      document_verified: true
    }
  } else {
    const name_on_pan = doc_verification?.name_on_pan;

    payload = {
      ...payload,
      document_verified: false,
      ...((pan_url && formData.invalid_holder_name === "pan_manual_verification") ? {pan_url} : {}),
      ...( name_on_pan ? {name_on_pan} : {}),
      ...((formData.invalid_doc === "consent_for_higher_tds" || formData.invalid_holder_name === "consent_for_higher_tds") ? {consent_for_higher_tds: true} : {consent_for_higher_tds: false})
    }
  }

  const method = isEdit ? "PATCH" : "POST";
  const response = await RequestServer(APIRoutes.bankDetails, method, payload);
  return response;
};

export const submitUsForm = async (
  formData: any,
  id?: number,
  isEdit?: boolean,
  bank_id?: string,
  wForm?: WForm,
  docVerification?: DocVerification
) => {
  const is_individual = formData.individual_option === "yes" ? true : false;

  const w9_form_url = wForm?.w9 ?? null;
  const W8_BEN_url = is_individual ? wForm?.W8_BEN_url ?? null : null;
  const W8_BEN_E_url = !is_individual ? wForm?.W8_BEN_E_url ?? null: null;
  const ftin = formData.ftin ?? null;

  let payload: Object = {
    is_primary: formData.is_primary,
    account_holder_name: formData.holder_name,
    account_number: formData.account_number,
    fk_expert: id,
    bank_name: formData.bank_name,
    bank_address: formData.bank_address,
    bank_country: formData.bank_country,
    account_holder_address: formData.account_holder_address,
    account_holder_residing_country: formData.account_holder_country,
    swift_code: (formData.swift_code as string).toLocaleUpperCase(),
    bank_country_code: formData.bank_country_code,
    ...(w9_form_url ? {w9_form_url} : {}),
    ...((W8_BEN_url) ? {W8_BEN_url} : {}),
    ...((W8_BEN_E_url) ? {W8_BEN_E_url} : {}),
    ...(formData.individual_option ? {is_individual: formData.individual_option} : {}),
    ...((formData.ssn && is_individual) ? {ssn: formData.ssn}: {}),
    ...((formData.entity_name && !is_individual) ? {entity_name: formData.entity_name} : {}),
    ...((formData.entity_type && !is_individual)? {entity_type: formData.entity_type.value} : {}),
    ...((formData.ein && !is_individual) ? {ein: formData.ein} : {}),
    ...((docVerification?.state ? {doc_verification: docVerification.state === "manual_verify_rejected" ? "invalid_holder_name" : docVerification.state} : {})),
    ...(ftin ? {ftin}: {})
  };

  if (formData.bank_country !== formData.account_holder_country) {
    payload = {
      ...payload,
      is_different_country: true,
      reason_for_different_country: formData.reason_for_different_country,
    };
  } else {
    payload = {
      ...payload,
      is_different_country: false,
    };
  }

  if (formData.bank_country === "United States of America") {
    payload = {
      ...payload,
      routing_code: formData.routing_code,
    };
  } else if (formData.bank_country === "Canada") {
    payload = {
      ...payload,
      canadian_code: formData.canada_code,
    };
  } else {
    if (formData.iban) {
      payload = {
        ...payload,
        iban: formData.iban,
      };
    }
  }

  if (formData.add_intermediatry) {
    payload = {
      ...payload,
      intermediary_bank: {
        intermediary_bank_name: formData.intermediary_bank_name,
        intermediary_bank_address: formData.intermediary_bank_address,
        intermediary_bank_swift_code: formData.intermediary_bank_swift_code,
        intermediary_bank_account_number:
          formData.intermediary_bank_account_number,
      },
    };
  }

  // If Edit Form is Opened, Bank Details ID is neccessary
  if (isEdit && bank_id) {
    payload = {
      ...payload,
      id: bank_id,
    };
  }

  const method = isEdit ? "PATCH" : "POST";
  const response = await RequestServer(APIRoutes.bankDetails, method, payload);
  return response;
};

export interface CountryData {
  name: string;
  currency: string;
  iso2: string;
  iso3: string;
}

export function getBankCountryCode(data: CountryData[], country: string) {
  if (!data) return "";
  const result = data.find((item) => item.name === country);
  if (!result) return "";

  return result.iso3;
}

type VerifyPanRes = {
  success: boolean,
  message: string,
  data: {
    account_holder_name_invalid?: boolean,
    pan_invalid?: boolean,
    document_verified?: boolean,
    name_on_pan?: string
    non_operative_pan?: boolean;
  }
}

export async function verifyPan(account_holder_name: string, pan: string, setLoading: (loading: boolean) => void, setDocVerification: SetDocVerification, docVerification: DocVerification) {
  setLoading(true);
  try {
    const payload = {
      account_holder_name,
      pan
    }

    if(docVerification.manually_verified_pan_cards) {
      const document_verified = docVerification.manually_verified_pan_cards.find(p => (p.account_holder_name === account_holder_name && p.pan === pan));

      if(document_verified) {
        setDocVerification((prev) => ({
          ...prev,
          state: "document_verified",
          name_on_pan: null
        }));
        return;
      }
    }

    const res: VerifyPanRes = await RequestServer(APIRoutes.VerifyPan,"POST", payload);
    if(res.success && res.data.document_verified) {
      setDocVerification((prev) => ({
        ...prev,
        state: "document_verified",
        name_on_pan: null
      }));
    } else {
      if(res.message === "API_NOT_WORKING_DO_MANUAL_VERIFICATION") {
        setDocVerification((prev) => ({
          ...prev,
          state: "API_NOT_WORKING_DO_MANUAL_VERIFICATION",
          name_on_pan: null,
        }))
      } else if(res.data.pan_invalid) {
        setDocVerification((prev) => ({
          ...prev,
          state: "invalid_doc", 
          name_on_pan: null}));
      } else if(res.data.non_operative_pan) {
        setDocVerification((prev) => ({
          ...prev,
          state: "NON_OPERATIVE", 
          name_on_pan: res.data.name_on_pan ?? null}));
      } else {
        setDocVerification((prev) => ({
          ...prev,
          state: "invalid_holder_name",
          name_on_pan: res.data.name_on_pan ?? null
        }));
      }
    }

  } catch(error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
}


export const radio_options = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

export const entity_options = [
  { label: 'LLC', value: 'LLC' },
  { label: 'Corporate', value: 'Corporate' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'Others(other than Individual)', value: 'Others' }
];