import TableCell from "@mui/material/TableCell"
import Box from "@mui/material/Box";

import { editAdminClickHandler, getColorsForRole } from "./helper";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useAdminContext } from "../../../pages/Admin/context";
import { Data } from "./types";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomChip } from "../../../atoms/chips/CustomChip";
import { useState } from "react";
import { Alert, Menu, MenuItem, Snackbar } from "@mui/material";
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import { useNavigate } from "react-router-dom";
import MonitorTwoToneIcon from '@mui/icons-material/MonitorTwoTone';

type Props = {
    row: Data;
    labelId: string;
    isSelected: boolean;
    refetchAdminData(): Promise<void>;
}

const TableCellsRow = ({ row, labelId, isSelected, refetchAdminData }: Props) => {
    const { setDialogOpen } = useAdminContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();
    const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleDashboardClick = (metabase: any, key: string, user_id: any,selectedName: string) => {
        const url=`/layout/dashboards?id=${metabase[key]}&name=${selectedName}&user_id=${user_id}`;
        window.open(url, "_blank");
    }
    const tooltipTitle = row.meta?.metabase ? "See Dashboards" : "Not Available";
    const handleTooltipClick = () => {
        if (tooltipTitle === "Not Available") {
            setSnackbarOpen(true);
        }
    };
    return (
        <>
            <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                sx={{ paddingLeft: isSelected ? "0" : "1rem" }}
            >
                {row.name}
            </TableCell>
            <TableCell align="left" >{row.id}</TableCell>
            <TableCell align="left" >{row.mobile}</TableCell>
            <TableCell align="left">{row.email}</TableCell>
            <TableCell align="left">
                <CustomChip
                    label={row.role}
                    color={getColorsForRole(row.role).color}
                    bg={getColorsForRole(row.role).bg}
                    sx={{
                        "& span": {
                            fontSize: "12px !important",
                            fontWeight: "500"
                        }
                    }}
                />
            </TableCell>            
            <TableCell align="left">
                {new Date(row?.joining_date)?.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric'
                })}
            </TableCell>

            <TableCell sx={{ display: "flex", alignItems: "center", width: "fit-content" }}>
                {row.actions ?
                    <> <Tooltip title="Edit Admin" arrow>
                        <IconButton disabled={!row.is_active} onClick={() => editAdminClickHandler(setDialogOpen, row, refetchAdminData)}>
                            <ModeEditIcon />
                        </IconButton>
                    </Tooltip>
                        <Tooltip title="Change Password" arrow>
                            <IconButton disabled={!row.is_active} onClick={() => setDialogOpen((prev) => ({
                                ...prev,
                                changePass: {
                                    state: true,
                                    admin_id: row.id.toString(),
                                    admin_name: row.name
                                }
                            }))}>
                                <LockIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={row.is_active ? "Deactivate" : "Activate"} arrow>
                            <IconButton
                                // disabled={!row.is_active}
                                onClick={() => setDialogOpen((prev) => ({
                                    ...prev,
                                    [row.is_active ? 'deactivate' : 'activate']: {
                                        state: true,
                                        admin_id: row.id.toString(),
                                        admin_name: row.name
                                    }
                                }))}>
                                {row.is_active ? (
                                    <BlockIcon style={{ fill: '#fa30309b' }} />
                                ) : (
                                    <CheckCircleIcon style={{ fill: '#4caf50' }} />
                                )}
                            </IconButton>
                        </Tooltip>
                        {  /* Superadmins can view any dashboards - kept ON HOLD */ }
                        {/* <Tooltip title={tooltipTitle} arrow>
                            <IconButton onClick={row.meta?.metabase ? handleIconClick : handleTooltipClick}>
                                <MenuBookTwoToneIcon />
                            </IconButton>
                        </Tooltip> */}
                        {row.meta?.metabase && Object.keys(row.meta.metabase).length > 0 && (
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                {Object.keys(row.meta.metabase).map((key) => (
                                    <MenuItem key={key} onClick={() => handleDashboardClick(row.meta.metabase, key, row.id, row.name    )}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Menu>
                        )}
                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={3000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        >
                            <Alert
                                onClose={handleSnackbarClose}
                                severity="info"
                                sx={{ width: "100%", background:'#EC9324' ,color:'white'}}
                            >
                                No dashboard found!
                            </Alert>
                        </Snackbar>
                    </>
                    : <></>
                }
                <Tooltip title="Dashboards" arrow>
                    <IconButton onClick={() => setDialogOpen((prev) => ({
                        ...prev,
                        changeDashboard: {
                            state: true,
                            admin_id: row.id.toString(),
                            dashboards: row?.meta?.metabase || "",
                            refetchAdmin: refetchAdminData,
                        }
                    }))}>
                        {/* <img src="/src/assets/images/dashboard.png" width={25} height={30} /> */}
                        <MonitorTwoToneIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
            <TableCell align="left">
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.75rem",
                        justifyContent: "flex-start",
                    }}
                >
                    {row.groups}
                </Box>
            </TableCell>
        </>
    )
}

export default TableCellsRow