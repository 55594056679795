export const ratingOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
];

export interface DefaultValues {
  fk_expert: number;
  fk_project: number;
  project_relevancy_rating: number | string | null;
  project_relevancy_remark: string | string[] | null;
  seniority_rating: number | string | null;
  seniority_remark: string | string[] | null;
  relevancy_rating: number | string | null;
  relevancy_remark: string | string[] | null;
};

export const defaultValues = {
  relevancy_rating: null,
  relevancy_remark: "",
  seniority_rating: null,
  seniority_remark: "",
  project_relevancy_rating: null,
  project_relevancy_remark: "",
};

export const commonInputStyles: any = {
  // className: "backgroundWhite-description",
  size: "small",
  variant: "outlined",
  fullWidth: true,
};