import { APIRoutes } from "../../constants";
import { Dialog, Filters } from "./types";


export const defaultFilters: Filters = {
    isFilterChange: false,
    date: null,
    dateRange: null, 
    currency: 'all',
    isFilterApplied: false
}

export const getUrl = (filters: Filters, selectedTab:number) => {
    const entityType=selectedTab===0? "ExchangeRate" : "Currency&base_currency=INR";
    return (
        `${APIRoutes.EXCHANGE_RATE}/data?entityType=${entityType}&` +
        // (filters.date ? filters.date : "") +
        (filters.dateRange ? `${filters.dateRange}&` : "") + 
        (filters.currency !== "all"
            ? (selectedTab === 0 ? `currency=${filters.currency}` : `other_currency=${filters.currency}`)
            : "")
    )
}

export const defaultDialog: Dialog = {
    add: {
        state: false
    }
}

export const acceptedExcelFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/vnd.ms-excel", // .xls
    "application/vnd.oasis.opendocument.spreadsheet", // .ods
    "text/csv", // .csv
    "application/vnd.ms-excel.sheet.macroEnabled.12", // .xlsm
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12", // .xlsb
  ];