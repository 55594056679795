import { Box, Collapse, IconButton, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import { TBannerBox, TBannerConfig, bannerConfigurations, isBannerVisible } from "./helper";

export const GlobalBanner = () => {
  const location = useLocation();
  const path = useMemo(() => location.pathname + location.search, [location.pathname, location.search]);
  const closedBanners = useRef<string[]>([]);
  const [visibleBanners, setVisibleBanners] = useState<TBannerConfig[]>([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  // Single ref object that holds all the necessary banner data
  const bannerData = useRef<{
    refs: (HTMLDivElement | null)[],
    heights: number[]
  }>({
    refs: [],
    heights: []
  });

  // Update visible banners when path changes
  useEffect(() => {
    const visible_banners = bannerConfigurations
      .filter(config => config.isActive)
      .filter(config =>
        config.paths.includes('ALL') ||
        config.paths.some(configuredPath => path.startsWith(configuredPath))
      )
      .filter(banner => isBannerVisible(banner) && !closedBanners.current.includes(banner.bannerId));

    setVisibleBanners(visible_banners);
    setCurrentBannerIndex(prev => visible_banners.length > 0 ? Math.min(prev, visible_banners.length - 1) : 0);

    // Reset banner data when visible banners change
    bannerData.current = {
      refs: Array(visible_banners.length).fill(null),
      heights: Array(visible_banners.length).fill(0)
    };
  }, [path]);

  // Measure banner heights using ResizeObserver
  useEffect(() => {
    if (visibleBanners.length === 0) return;

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const targetElement = entry.target as HTMLDivElement;
        const index = bannerData.current.refs.findIndex(ref => ref === targetElement);

        if (index !== -1) {
          bannerData.current.heights[index] = entry.contentRect.height;

          // Update container height if this is the current banner
          if (index === currentBannerIndex) {
            setContainerHeight(entry.contentRect.height);
          }
        }
      });
    });

    // Observe all banner elements
    bannerData.current.refs.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, [visibleBanners, currentBannerIndex]);

  // Update container height when current banner changes
  useEffect(() => {
    if (visibleBanners.length > 0 && bannerData.current.heights[currentBannerIndex]) {
      setContainerHeight(bannerData.current.heights[currentBannerIndex]);
    }
  }, [currentBannerIndex, visibleBanners]);

  // Rotate banners if there are multiple
  useEffect(() => {
    if (visibleBanners.length <= 1) return;

    const intervalId = setInterval(() => {
      setCurrentBannerIndex(prevIndex => (prevIndex + 1) % visibleBanners.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [visibleBanners]);

  const removeBanner = (bannerId: string) => {
    closedBanners.current = [...closedBanners.current, bannerId];

    const updatedVisibleBanners = visibleBanners.filter(banner => banner.bannerId !== bannerId);
    setVisibleBanners(updatedVisibleBanners);

    // Adjust current banner index if needed
    if (currentBannerIndex >= updatedVisibleBanners.length && updatedVisibleBanners.length > 0) {
      setCurrentBannerIndex(updatedVisibleBanners.length - 1);
    }
  };

  // If no visible banners, return null
  if (visibleBanners.length === 0) return null;

  return (
    <Box sx={{
      width: '100%',
      height: containerHeight,
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '#f5f5f5',
      transition: 'height 0.3s ease-in-out'
    }}>
      {visibleBanners.map((banner, index) => (
        <BannerBox
          key={banner.bannerId}
          currentBannerIndex={currentBannerIndex}
          index={index}
          banner={banner}
          removeBanner={() => removeBanner(banner.bannerId)}
          ref={(el) => { bannerData.current.refs[index] = el; }}
        />
      ))}
    </Box>
  );
};

const BannerBox = React.forwardRef<HTMLDivElement, TBannerBox>(
  ({ banner, removeBanner, currentBannerIndex, index }, ref) => {
    const { value: isBannerOpen, setFalse: setBannerClose } = useBoolean(true);
    const isActive = currentBannerIndex === index;

    const handleBannerCloseButton = () => {
      setBannerClose();
      setTimeout(() => removeBanner(), 300);
    };

    return (
      <Box
        ref={ref}
        sx={{
          width: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: isActive ? 1 : 0,
          visibility: isActive ? 'visible' : 'hidden',
          transition: 'opacity 0.5s ease-in-out, visibility 0.5s ease-in-out',
          zIndex: isActive ? 1 : 0
        }}
      >
        <Collapse in={isBannerOpen}>
          <Box
            paddingY={0.5}
            sx={{
              background: banner.bgColor,
              borderRadius: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Typography
              textAlign={'center'}
              // paddingX={8}
              fontWeight={600}
              fontSize={14}
              color='white'
              lineHeight={1.3}
              sx={{
                wordBreak: 'break-word',
                overflow: 'hidden'
              }}
            >
              {banner.message}
            </Typography>
            <IconButton
              aria-label="close"
              size="small"
              sx={{
                color: 'white',
                position: "absolute",
                right: 0,
                pr: 1.5,
              }}
              onClick={handleBannerCloseButton}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Collapse>
      </Box>
    );
  }
);