import React, { useState, useCallback, useMemo } from "react";
import { Tooltip, IconButton, Typography, Box, Divider, Grid, Checkbox, List, ListItem, ButtonBase } from "@mui/material";
import Diamond from "../../assets/images/expert/diamond_expert.png";
import { toggleItemInArray } from "../../common/select";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { statusChip } from "../../atoms/IndividualExpertCards/style";
import ContactMail from "../profile-cardV1/ContactMail";
import dnd from "../../assets/images/expert/no-call-expert.png";
import "./style.scss"
import FiberManualRecordTwoToneIcon from '@mui/icons-material/FiberManualRecordTwoTone';
import { ExpertBadge } from "../profile-cardV1/clientloginProfileCard";
import { IndividualCardProps } from "./types";

const IndividualCards: React.FC<IndividualCardProps> = React.memo(
  ({
    name,
    id,
    primary_email,
    private_profile,
    primary_mobile,
    approved_by,
    highlights,
    status,
    handleOpenAddExpert,
    handleOpenSE,
    selectExpert,
    selectedCards = [],
    setSelectedCards,
    page,
    isDrawerExpaned,
    expandDrawer,
    workex,
    premium_expert,
    badge,
    total_years_of_experience,
    dnd_enabled
  }) => {
    const [expandedKeys, setExpandedKeys] = useState<Record<string, boolean>>({});
    const isMobile = useIsMobile();

    const toggleExpand = (key: string) => {
      setExpandedKeys(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const truncateText = (text: string, maxLength: number): string => {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    const styledHighlights = (text: string) => {
      if (!text) return "";

      const regex = /<em>(.*?)<\/em>/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(text)) !== null) {
        const [fullMatch, content] = match;

        if (match.index > lastIndex) {
          parts.push(text.slice(lastIndex, match.index));
        }

        parts.push(
          <span key={match.index} style={{ color: "#EC9324", backgroundColor: "yellow", fontWeight: "bold" }}>
            {content}
          </span>
        );

        lastIndex = match.index + fullMatch.length;
      }

      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
      }

      return parts;
    };

    // Prepare highlight entries
    const highlightEntries = Object.entries(highlights || {}).map(([key, value]) => {
      const displayValue = Array.isArray(value) ? value.join(" ") : String(value);
      const isExpanded = expandedKeys[key] || false;
      const truncatedValue = truncateText(displayValue, 200);

      return {
        key,
        displayValue,
        truncatedValue,
        isExpanded,
      };
    });
    const VerticalDivider = () => (
      <hr style={{ border: "1px solid #bdb7b7", width: "16px", transform: "rotate(90deg)", borderRadius: "25%", margin: "0 2px" }} />
    );


    const formatDate = (dateString: string | null) => {
      if (!dateString) return null;
      const date = new Date(dateString);
      return date.toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" });
    };

    const toggleSelected = (selectedExpert: { name: string, id: number }) => {
      setSelectedCards(
        toggleItemInArray<{ label: string; value: number }>(
          selectedCards,
          {
            label: selectedExpert.name,
            value: Number(selectedExpert.id),
          }
        )
      );
    };

    return (
      <Box onClick={expandDrawer}
        sx={{
          height: "fit-content",
          backgroundColor: "white",
          borderRadius: "15px",
          padding: 1,
          boxShadow: 1,
          marginX: 2,
          marginBottom: 2,
          position: "relative",
          border: isDrawerExpaned ? '2px solid #FFA500' : "",
          cursor: "pointer",
          ...(isDrawerExpaned && {
            outline: "1px solid rgb(236, 147, 36)",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important"
          }),
          "&:hover": {
            boxShadow: "0px 4px 14px rgba(37, 43, 59, 0.21)"
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap' }}>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Tooltip title="Expert Name" arrow>
                <ButtonBase disableRipple sx={{ color: "black", fontWeight: "400", fontSize: "13px", textTransform: 'none' }}>
                  <a
                    href={`/layout/expert-profile?id=${id}&page=${page}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontWeight: 'bold', fontSize: '13px', color: 'inherit', textDecoration: 'none', whiteSpace: 'nowrap' }}
                  >
                    {name}
                  </a>
                </ButtonBase>

              </Tooltip>
              <Tooltip title="ID" arrow>
                <ButtonBase disableRipple sx={{ color: "#EC9324", fontSize: '13px', fontWeight: '500', minWidth: '15px' }}>
                  #{id}
                </ButtonBase>
              </Tooltip>
              {badge && (
                <ExpertBadge isProfile classname="icon" badge={badge} />
              )}
              {dnd_enabled && (
                <Tooltip title="DND on" arrow>
                  <img
                    className="icon"
                    src={dnd}
                    width="20px"
                    height="20px"
                    alt="Private Profile Icon"
                  />
                </Tooltip>
              )}
              {premium_expert && (
                <Tooltip title="Premium Expert" arrow>
                  <img
                    src={Diamond}
                    width="24px"
                    height="24px"
                    alt="Premium Expert Icon"
                  />
                </Tooltip>
              )}

            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
              <Box display={'flex'} gap={1} alignItems={'center'}>
                <Box sx={statusChip(status)}>
                  <Tooltip title='status' arrow>
                    <ButtonBase disableRipple sx={{ color: "black", fontWeight: "400", fontSize: "12px", textTransform: 'none' }}>
                      {status}
                    </ButtonBase>
                  </Tooltip>
                </Box>
                <Box>
                  {typeof total_years_of_experience === 'number' && (
                    <Tooltip title='Total Years Of Exprience' arrow>
                      <ButtonBase disableRipple sx={{ color: "black", fontWeight: "400", fontSize: "12px", textTransform: 'none' }}>
                        {total_years_of_experience} years
                      </ButtonBase>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {primary_email && primary_mobile && (
                  <ContactMail primary_email={primary_email} primary_mobile={primary_mobile} />
                )}
                {status === "Confirmed" && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="PE+" arrow>
                      <IconButton onClick={handleOpenAddExpert}>
                        <Typography
                          component="span"
                          sx={{
                            backgroundColor: "#e7e9e8",
                            borderRadius: "50%",
                            color: "#14171F",
                            fontSize: "11.5px",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          PE+
                        </Typography>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="SE+" arrow>
                      <IconButton onClick={handleOpenSE}>
                        <Typography
                          component="span"
                          sx={{
                            backgroundColor: "#e7e9e8",
                            borderRadius: "50%",
                            color: "#14171F",
                            fontSize: "11.5px",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          SE+
                        </Typography>
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                {!isMobile && (
                  <Tooltip title="Approved By" arrow>
                    <ButtonBase disableRipple sx={{ color: "black", fontWeight: "400", fontSize: "13px", textTransform: 'none' }}>
                      {approved_by}
                    </ButtonBase>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            {selectExpert && status === "Confirmed" && (
              <Checkbox
                checked={selectedCards.some((card: any) => card.value === id)}
                onChange={() => toggleSelected({ name, id })}
                sx={{
                  '&.Mui-checked': {
                    color: '#ec9324',
                  },
                  '& .MuiSvgIcon-root': {
                    borderRadius: '4px',
                  },
                }}
              />
            )}
          </Box>
        </Box>
        {/* Header end */}
        <Divider style={{ margin: "3px 0", color: "black" }} />
        <Box sx={{ mt: 1 }}>
          {highlightEntries.map(({ key, displayValue, truncatedValue, isExpanded }) => (
            <Box key={key} sx={{ mb: 0.5 }}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    component="span"
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      display: "inline",
                    }}
                  >
                    {key.replace(/_/g, " ")}:
                  </Typography>

                  <Typography
                    variant="body2"
                    component="span"
                    style={{
                      fontSize: "13px",
                      textAlign: "justify",
                      display: "inline",
                      marginLeft: "4px",
                    }}
                  >
                    {isExpanded ? (

                      styledHighlights(displayValue)
                    ) : (
                      <>
                        <Typography variant="body2" component="span">
                          {styledHighlights(truncatedValue)}
                          {displayValue.length > 200 && (
                            <ButtonBase disableRipple sx={{
                              color: "rgb(236, 147, 36)",
                              cursor: 'pointer',
                              marginLeft: "4px"
                            }}
                              onClick={() => toggleExpand(key)}

                            >
                              ...Read more
                            </ButtonBase>
                          )}
                        </Typography>
                      </>
                    )}
                  </Typography>
                  {isExpanded && (
                    <Typography variant="body2" component="span">
                      <ButtonBase disableRipple sx={{
                        color: 'rgb(236, 147, 36)',
                        cursor: 'pointer',
                        marginTop: '4px'
                      }}
                        onClick={() => toggleExpand(key)}
                      >
                        Show less
                      </ButtonBase>
                    </Typography>
                  )}
                </Grid>
              </Grid>

              {isMobile && highlightEntries.length > 1 && (
                <Divider
                  sx={{
                    marginY: 2,
                    width: '75vw',
                    borderStyle: "dashed",
                    backgroundColor: "#fafafa",
                    marginTop: '1px',
                    marginBottom: '0px'
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Divider style={{ margin: "3px 0", color: "black" }} />
        {
          workex && workex.length > 0 && (
            <>
              <Typography component="span" variant="body2" fontWeight={'bold'}>
                Workex:
              </Typography>
              <List sx={{ paddingLeft: 0.5 }} disablePadding>
                {workex.map((item: any, index: number) => {
                  const { company, designation, division, location, start_date, end_date } = item;
                  const formattedStartDate = start_date ? formatDate(start_date) : null;
                  const formattedEndDate = end_date ? formatDate(end_date) : "Present";
                  return (
                    <ListItem disablePadding disableGutters key={index} >
                      <FiberManualRecordTwoToneIcon fontSize="small" sx={{ mr: 0.5 }} />
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {company && (
                          <>
                            <Typography component="span" variant="body2" fontWeight={550}>
                              {company}
                            </Typography>
                            {(designation || location || start_date) && <VerticalDivider />}
                          </>
                        )}
                        {designation && (
                          <>
                            <Typography component="span" variant="body2" >
                              {designation}{division ? ` (${division})` : ""}
                            </Typography>
                            {(location || start_date) && <VerticalDivider />}
                          </>
                        )}
                        {location && (
                          <>
                            <Typography component="span" variant="body2">
                              {location}
                            </Typography>
                            {start_date && <VerticalDivider />}
                          </>
                        )}
                        {start_date && (
                          <Typography component="span" variant="body2">
                            {`${formattedStartDate} - ${formattedEndDate}`}
                          </Typography>
                        )}
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </>
          )
        }
      </Box>
    );
  }
);

export default IndividualCards;