import Box from "@mui/material/Box";
import AppBarCommon from "../../app-bar-common";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
type Props = {
    setFilterPayload?: (callback: (prev: any) => any) => void;
};
const ExpertsListPageHeader: React.FC<Props> = () => {
    const isMobile = useIsMobile();
    return (
        <Box sx={{ pb: isMobile ? "0" : "24px" }}>
            <AppBarCommon
                title="Experts - Enrichment"
                isSearch={false}
                isUserIcon
                isIconDefine={false}
            />
        </Box>
    );
};

export default ExpertsListPageHeader;
