import { RequestServer } from "../../utils/services";
import { APIRoutes } from "../../constants";
import { PayloadAdvancedFilters } from "../../pages/Quick-Search/types";

export const getQuickSearchData = async (page: number = 1, limit: number = 10, search: string = "", search_in: string[] | undefined, filter_payload: any | undefined,  andvancedFilter: PayloadAdvancedFilters = []) => {
  try {

    const payload = { page, limit, search, search_in: search_in?.length ? search_in : undefined,filter_payload, andFilters: andvancedFilter };

    const response = await RequestServer(APIRoutes.ELASTICSEARCHURL, "POST", payload);

    if (response?.data) {
      return {
        data: response.data,
        total: response.total,
        page: response.page,
        totalPages: response.totalPages,
      };
    }
    return { data: [], total: 0, page: 1, totalPages: 100 };
  } catch (error) {
    console.error("Error:", error);
    return { data: [], total: 0, page: 1, totalPages: 100 };
  }
};
