import { labelValueType } from "../../organisms/client/all-clients/types"
import { combineLevelLabelDomainsData } from "../../utils/hooks/useFetchDomains";

export interface filterOnFieldProps {
  andIndex: number,
  domains: combineLevelLabelDomainsData[],
  countries: labelValueType[]
}
export type companyFieldsOptions = {
  current: labelValueType[];
};

export type TFormField = {
  formArrayPath: string,
  andIndex: number,
  orIndex: number,
  domains: combineLevelLabelDomainsData[],
  countries: labelValueType[]
}
export interface IWithFormField {
  andIndex: number
  orIndex: number
  fields: any
  onRemoveWithField: (withFormFilterOnIndex: number) => void
  toWithFilterOnValue: { label: string, id: string }
  domains: combineLevelLabelDomainsData[],
  countries: labelValueType[]
}
export enum FormFieldType {
  WATCH_AUTOCOMPLETE = 'WatchAutoComplete',
  COMPANY = 'company',
  FREE_SOLO = 'freeSolo',
  TEXT_INPUT = 'textInput',
  GEOGRAPHY = 'geography',
  DOMAIN = 'domain',
  DATE_PICKER = 'datePicker',
  UNKNOWN = 'unknown'
}