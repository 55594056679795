import { Box, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, List, ListItem, Typography } from "@mui/material";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";

interface PEPopupDialogProps {
    openDialog: boolean;
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    errorDetails: any[];
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    handleFinalSubmit: () => void;
}

const PEPopupDialog = ({ openDialog, setDialogOpen, errorDetails, checked, setChecked, handleFinalSubmit }: PEPopupDialogProps) => {
    const [expandStatus, setExpandStatus] = useState<boolean[]>([]);
    const [hardStop, setHardStop] = useState<boolean>(false);
    useEffect(() => {
        if (!errorDetails?.length) {
            return;
        }
        for (let i = 0; i < errorDetails.length; i++) {
            for (let j = 0; j < errorDetails[i].details.length; j++) {
                if (errorDetails[i].details[j].hardStop === true) {
                    setHardStop(true);
                    break;
                }
            }
        }
    }, [errorDetails])
    useEffect(() => {
        const expandStatusArray = errorDetails.map(() => false);
        setExpandStatus(expandStatusArray);
    }, [errorDetails]);
    const handleExpandCollapse = (index: number) => {
        const newExpandStatus = [...expandStatus];
        newExpandStatus[index] = !newExpandStatus[index];
        setExpandStatus(newExpandStatus);
    }
    const handleClose = () => {
        setChecked(false);
        setDialogOpen(false);
    }
    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Are you sure you want to add this expert?</DialogTitle>
            <DialogContent>
                {errorDetails && errorDetails?.length > 0 && (
                    <Grid container >
                        <Grid container spacing={2} sx={{ maxHeight: 400, overflowY: 'auto' }}>
                            {errorDetails.map((error, index) => {
                                return (
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                backgroundColor: 'white',
                                                display: "flex",
                                                color: "red",
                                                alignItems: "center",
                                                padding: "5px",
                                                borderRadius: '10px',
                                                border: '1.5px dashed red',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleExpandCollapse(index)}
                                        >
                                            <Typography className="work-history-text" fontSize={16} sx={{ flexGrow: 1, fontWeight: 500, maxWidth: "calc(100% - 25px)" }}>{error.message}</Typography>
                                            <Box>
                                                {expandStatus[index] ? (
                                                    <ExpandMore sx={{ maxWidth: '100%', verticalAlign: 'middle' }} />
                                                ) : (
                                                    <ExpandLess sx={{ maxWidth: '100%', verticalAlign: 'middle' }} />
                                                )}
                                            </Box>
                                        </Typography>
                                        <Collapse in={!expandStatus[index]}>
                                            <List sx={{ padding: 1, gap: 0, listStyleType: 'disc', marginLeft: 1 }} >
                                                {error.details.map((specificError: any, specificErrorIndex: any) => {
                                                    let hardStopFlag = false;
                                                    if (specificError.hardStop === true) {
                                                        hardStopFlag = true;
                                                    }
                                                    return (
                                                        <ListItem sx={{ padding: 0.5, display: 'list-item' }}>
                                                            <Grid item style={{ width: "100%" }} key={specificErrorIndex}>
                                                                <Typography fontSize={13} color={hardStopFlag ? "error" : ""}>{specificError.key_name + ` -> ` + specificError.key_value + (specificError.specific_message && specificError.specific_message.trim() !== "" ? ` (` + specificError.specific_message + `)` : ``)}</Typography>
                                                            </Grid>
                                                        </ListItem>
                                                    );
                                                }
                                                )}
                                            </List>
                                        </Collapse>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        {!hardStop && (
                            <FormControlLabel
                                label={<Typography fontSize={13}>I understand and want to proceed.</Typography>}
                                control={<Checkbox style={{ color: "#EC9324" }} checked={checked} onChange={() => setChecked((prev) => !prev)} />}
                            />
                        )}
                    </Grid>
                )
                }
            </DialogContent >
            <DialogActions>
                {!hardStop ? (
                    <>
                        <CustomBtnFilled
                            label="Cancel"
                            variant="outlined"
                            onClick={handleClose}
                        />
                        <CustomBtnFilled
                            variant="contained"
                            label="Yes"
                            disabled={!checked}
                            onClick={handleFinalSubmit}
                        />
                    </>
                ) : <>
                    <CustomBtnFilled
                        label="I Understand"
                        variant="outlined"
                        onClick={handleClose}
                    />
                </>}

            </DialogActions>
        </Dialog >
    )
}

export default PEPopupDialog