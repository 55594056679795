import { Check } from '@mui/icons-material';
import { Box, Grid, Icon, Popover, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { KEYWORDS } from './constant';
import CustomBtnFilled from '../../atoms/form-molecules/CustomBtnFilled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const buttonContainerStyle = { display: "flex", alignItems: "center", backgroundColor: "#eceaea", margin: "0px", borderRadius: "25px 0px 0px 25px", padding: "3px 12px", paddingRight: "0px", cursor: "pointer", height: "32px", borderRight: "1px solid rgba(150,150,150,0.5)" }
const buttonStyle = { display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }
const labelStyle = { color: "#6f6f6f", fontSize: "12px", fontWeight: "500" }
const iconStyle = { display: "flex", alignItems: "center", color: "#6f6f6f", justifyContent: "center", height: "100%" }
const gridStyles = { overflowY: "auto", maxHeight: "70vh", maxWidth: "540px" }

interface QuickSearchDropDownProps {
  parsedSearchIn: string[];
  setParsedSearchIn: (value: string[]) => void;
  handleDropDown: () => void;
  selectedKeywords: Record<string, boolean>;
  setSelectedKeywords: (value: Record<string, boolean>) => void;
  handleReset: () => void;
}

const QuickSearchDropDown = ({ 
  parsedSearchIn, 
  setParsedSearchIn, 
  handleDropDown, 
  selectedKeywords, 
  setSelectedKeywords, 
  handleReset 
}: QuickSearchDropDownProps) => {
  const [quickSearchLabel, setQuickSearchLabel] = useState<string>("QuickSearch")
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setParsedSearchIn(Object.keys(selectedKeywords));
    setAnchorEl(null);
  };

  const id = anchorEl ? 'search-dropdown-chip-popover' : undefined;
  const handleSelect = async (item: string) => {
    const newSelectedKeywords = { ...selectedKeywords }
    if (newSelectedKeywords[item]) {
      delete newSelectedKeywords[item]
    } else {
      newSelectedKeywords[item] = true
    }
    setSelectedKeywords(newSelectedKeywords);
  }
  const inputElement = document.getElementById("search-bar-input");
  const getInputWidth = useMemo(() => {
    return inputElement ? inputElement.offsetWidth + 52 : 500;
  }, [inputElement]);

  const calculateLabel = () => {
    const keys = Object.keys(selectedKeywords);
    const selectedKeywordsLength = keys.length;
    if (selectedKeywordsLength === 0) {
      setQuickSearchLabel("All")
    } else if (selectedKeywordsLength === 1) {
      setQuickSearchLabel(KEYWORDS[keys[0]])
    } else {
      setQuickSearchLabel(`${selectedKeywordsLength} Selected`)
    }
  }
  useEffect(calculateLabel, [selectedKeywords])
  const checkIfAllSelected = () => {
    return Object.keys(selectedKeywords).length === Object.keys(KEYWORDS).length
  }
  const handleSelectAll = async () => {
    const ifAllSelected = checkIfAllSelected();
    if (ifAllSelected) {
      setSelectedKeywords({});
    }
    else {
      const newSelectedKeywords = Object.keys(KEYWORDS).reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {} as Record<string, boolean>)
      setSelectedKeywords(newSelectedKeywords)
    }
  }


  return (
    <>
      <Box sx={buttonContainerStyle} onClick={(event: any) => {
        handleAnchorClick(event)
        handleDropDown()
      }}>
        <Box
          sx={buttonStyle} >
          <Typography variant="body2" sx={labelStyle}>
            {quickSearchLabel}
          </Typography>
          <Icon sx={iconStyle}>
            {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </Icon>
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "25px",
              boxShadow: 0,
              mt: '10px',
              maxWidth: `${getInputWidth}px`,
              maxHeight: "70vh",
            }
          }
        }}
      >
        <Box px={1.5} py={1.5} boxShadow={2}>
          <Box sx={{ flexGrow: 1, marginBottom: "20px" }}>
            <Grid container sx={gridStyles} spacing={{ xs: 1, md: 2 }} columns={{ xs: 2, sm: 4, md: 6 }}>
              {Object.keys(KEYWORDS).map((item: string, index: number) => renderItem({ item, index, label: KEYWORDS[item], handleSelect, selectedKeywords }))}
            </Grid>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
              <CustomBtnFilled variant="outlined" label="Reset" onClick={handleReset} />
            </Box>
            <CustomBtnFilled variant='contained' label={checkIfAllSelected() ? "Unselect All" : "Select All"} onClick={handleSelectAll} />
          </Box>
        </Box>
      </Popover>
    </>

  )
}

type ButtonProp = {
  item: string,
  index: number,
  label: string,
  handleSelect: (item: string) => void
  selectedKeywords: Record<string, boolean>
}

const renderItem = ({ item, index, label, handleSelect, selectedKeywords }: ButtonProp) => {
  return (
    <Grid item xs={1} md={2} key={index} >
      <Box
        key={index}
        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "5px", border: "1px solid #eceaea", borderRadius: "5px", backgroundColor: selectedKeywords[item] ? "#ec9324" : "white", transition: "all 0.3s ease-in-out", cursor: "pointer" }}
        onClick={() => handleSelect(item)}
      >
        <Icon style={(selectedKeywords[item] ? { ...iconStyle, color: "white" } : iconStyle)} sx={{ opacity: selectedKeywords[item] ? 1 : 0 }}>
          <Check />
        </Icon>
        <Typography variant="body2" sx={{ color: selectedKeywords[item] ? "white" : "#6f6f6f", fontSize: "12px", fontWeight: "500", textTransform: "capitalize" }}>{label}</Typography>
        <Icon style={iconStyle} sx={{ opacity: 0 }}>
          <Check />
        </Icon>
      </Box>
    </Grid>);
}

export default QuickSearchDropDown