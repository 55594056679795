export const AppRoutes = {
  ROOT: "/",
  LAYOUT: "layout",
  EXPERT_SEARCH: "/layout/expert-search",
  EXPERT_PROFILE: "/layout/expert-profile",
  EDIT_EXPERT: "expert-profile/edit",
  ADD_EXPERT: "/experts/add",
  PROJECTS: "/layout/projects",
  PROJECT_DETAILS: "/layout/projects/projectdetails",
  PROJECT_PE_MAPPING: "/layout/projects/projectDetails/PEMapping",
  COMPLIANCE_APPROVAL_TABLE: "/layout/compliance-approval",
  CLIENTS: "/layout/clients",
  CLIENT_PROFILE: "client-profile",
  MODERATE: "moderate",
  ADVANCE_SETTINGS: "advance-settings",
  LOGIN: "login",
  REGISTER: "/register-user",
  COMPLIANCE: "/compliance",
  EXPERTCOMPLIANCE: "/expert-compliance",
  AGREEMENT: "/agreement",
  OTP: "/login/otp",
  FORGOTPASSWORD: "/login/forgot",
  RESETPASSWORD: "/login/reset-password",
  ADMIN_USER: "admin",
  CALENDER: "/layout/calendar",
  CONTACTS: "/layout/contacts",
  GROUPS: "/layout/groups",
  SETTINGS: "/layout/settings",
  CALLS: "/layout/calls",
  MY_CALENDAR: "/layout/my-calendar",
  TAGS: "/layout/tags",
  ACCEPT_INVITATION: "/layout/accept-invitation",
  Expert_Project_Page: "/layout/expert-projects",
  PROFILES_SHARED: "/layout/profiles-shared",
  CALL_CALENDAR: "/layout/call-calendar",
  PAYMENTS: "/layout/payments",
  EXPERT_PENDING_APPROVAL: "/layout/expert-pending-approval",
  DOCUMENTS: "/documents",
  VIEW_INVOICE: "/documents?type=Invoice",
  REPORTS: "/layout/reports",
  MYDASHBOARDS: "/layout/dashboards",
  ZOOM: "/layout/zoom",
  USAGE: "/layout/usage",
  CE_MAPPING_EXPERTS: "/layout/ce-experts",
  VENDORS: "/layout/reports/vendors",
  PAYMENT_REQUESTS: "/layout/payment-requests",
  CE_TRACKER: "/layout/ce-tracker",
  EXCHANGE_RATE: "/layout/exchange-rate",
  MANUAL_VERIFICATION: "/layout/manual-verification",
  QUICK_SEARCH: "/layout/quick-search",
  EXPERT_ENRICHMENT: "/layout/Experts-list",
  MASTERSLIST: "/layout/masters-list",
  SELECTEDMASTERS: "/layout/Masters-list/Selected-Master"
};
