import { APIRoutes } from "../../constants";
import { RequestServer } from "../../utils/services";
interface WorkExperience {
  id: number;
  company: string;
  location?: string;
  fk_company?: number;
  designation: string;
  job_description: string;
  division: string;
  start_date: string;
  end_date: string;
  currently_works_here: boolean;
}
interface Education {
  course: string;
  start_year: string;
  end_year: string;
  institution: string;
}
interface ExpertData {
  existingData: {
    name: string;
    email: string;
    work_experiences: WorkExperience[];
    education: Education[];
  };
  enrichedData: {
    name: string;
    email: string;
    work_experiences: WorkExperience[];
    education: Education[];
  };
}
export const fetchExpertData = async (sourceLink: string, expertId: number): Promise<ExpertData | null> => {
  try {
    const response = await RequestServer(
      `${APIRoutes.EXPERTSLISTENRICHMENT}?source_link=${encodeURIComponent(sourceLink)}&expertId=${expertId}`,
      "GET"
    );
    if (response?.success && response.data) {
      return response.data;
    }
    throw new Error("Invalid API response format");
  } catch (error: any) {
    console.error("Error fetching expert data:", error);
    return null;
  }
};

export const updateExpertData = async (payload: any) => {
  try {
    const response = await RequestServer(APIRoutes.UPDATEDEXPERTDATA, "POST", payload);

    if (response?.success) {
      return { success: true, message: response.message || "Data updated successfully" };
    } else {
      throw new Error(response?.message || "Failed to update data");
    }
  } catch (error: any) {
    console.error("Error updating expert data:", error);
    return { success: false, message: error?.message || "An error occurred while updating data" };
  }
};