import React, { useEffect, useState } from 'react';
import { RequestServer } from '../../utils/services';
import { APIRoutes } from '../../constants';
import PageLayout from '../../atoms/page-layout';
import MyDashboardsPageHeader from '../../molecules/app-bars/Mydashboards-page';
import { Box, Button, Divider, MenuItem, Select } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';

const DashboardPage = () => {
    const [dashboardIds, setDashboardIds] = useState<{ name: string; id: string }[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<string>('');
    const [metabaseURL, setMetabaseURL] = useState<string>('');
    const [error, setError] = useState<string | null>(null);
    const [params] = useSearchParams();
    const fetchDashboardIds = async (adminId?: string) => {
        try {
            let url = APIRoutes.getDashboardIds;
            if (adminId) url = `${url}?user_id=${adminId}`;
            const response = await RequestServer(url, 'get');
            const ids = Object.entries(response.data).map(([name, id]:any) => ({
                id: id,
                name: name
            })) || [];
            setDashboardIds(ids);

            let defaultSelectDashboardId;
            if (params.has('id')) {
                defaultSelectDashboardId = String(params.get("id"));
            } else if (ids.length > 0) {
                defaultSelectDashboardId = ids[0].id
            }
            setSelectedDashboard(defaultSelectDashboardId);
        }
        catch (error) {
            setError('Failed to fetch dashboard IDs');
        }
    };
    useEffect(() => {
        fetchDashboardIds(params.get('user_id') ?? '');
    }, []);

    const getMetabaseURL = async () => {
        if (!selectedDashboard) return;
        try {
            const response = await RequestServer(APIRoutes.getMetabaseURL, 'post', {
                dashboard: +[selectedDashboard],
                ...(params.has('user_id')?{user_id:params.get('user_id')}:{}),
                name: dashboardIds?.find(d=>d.id == selectedDashboard)?.name
            });
            setMetabaseURL(response.url);
            setError(null);
        } catch (error: any) {
            setError(error.message);
        }
    };
    useEffect(() => {
        if (selectedDashboard) {
            getMetabaseURL()
        }
    }, [selectedDashboard])

    return (
        <PageLayout>
            <MyDashboardsPageHeader
                selectedDashboard={selectedDashboard}
                setSelectedDashboard={setSelectedDashboard}
                dashboardIds={dashboardIds}
                getMetabaseURL={getMetabaseURL}
            />
              <Divider  />
            {dashboardIds.length===0?(
                <Box sx={{mt:6, textAlign:'center', color:'black', fontSize:'20px', fontWeight:500, lineHeight:1.2}}>
                    No dashboards available....
                </Box>
            ):(
                metabaseURL && (
                    <Box sx={{ mt: 3 }}>
                        <iframe
                            src={metabaseURL}
                            title="Metabase Dashboard"
                            width="100%"
                            height="850vh"
                            style={{ border: '1px solid #ccc' }}
                        />
                    </Box>
            )
                )}
        </PageLayout>
    );
};
export default DashboardPage;