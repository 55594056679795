import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material";
import "./nav-bar.scss"
import MultiTabFilters from "../../atoms/tabs-selector/multi";
import { filterPayload as TFilterPayload, SetFilterPayload as TSetFilterPayload } from "../../pages/Experts/types";
import { useIsTablet } from "../../utils/hooks/useIsMobile";
import DropDownDrawerWithChip from "../../molecules/dropdown-drawer-with-chip";
import QuickNavbarSearch from "./quickNavbarSearch";
import DropDownDrawerWithChipMultiple from "../../molecules/dropdown-drawer-with-chip-multiple";
import { statusOptions } from "../../molecules/nav-bars/expert-cards-page/helper";
import LabeledComponent from "../../molecules/nav-bars/expert-cards-page/labeledComponent";
import MultipleDropDown from "../../atoms/drop-down-filter/multipleDropdown";
import CustomBtnFilled from "../../atoms/form-molecules/CustomBtnFilled";
import { defaultFilterPayload } from "./constant";
import { useState } from "react";
import { useBoolean } from "usehooks-ts";

const BadgeFilterOptions = [
  { label: "Ace", value: "ace" },
  { label: "Champ", value: "champion" },
  { label: "Pro", value: "pro" }
]

const theme = createTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 12,
    h6: {
      fontSize: "0.75rem",
      fontWeight: 400,
      color: "#252B3B",
    },
    body1: {
      fontSize: "0.75rem",
      fontWeight: 400,
      color: "#252B3B",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: "#252B3B",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#EC9324",
    },
  },
});

type Props = {
  filterPayload: TFilterPayload;
  setFilterPayload: TSetFilterPayload;
  setAdvancedFilter: React.Dispatch<React.SetStateAction<any[]>>;
  resetAdvanceFilter: () => void
  setSearchIn: React.Dispatch<React.SetStateAction<any[]>>
  setSelectedKeywords: React.Dispatch<React.SetStateAction<any>>
  setChips: React.Dispatch<React.SetStateAction<any>>
  setSearch: React.Dispatch<React.SetStateAction<string>>
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const QuickSearchNavbarItems = ({
  filterPayload,
  setFilterPayload,
  setAdvancedFilter,
  resetAdvanceFilter,
  setSearchIn,
  setSelectedKeywords,
  setChips,
  setSearch,
  setPage
}: Props) => {

  const isMobile = useIsTablet();
  const { setTrue: setResetAllFieldTrue, value: isResetAllField, setFalse: setResetAllFieldFalse } = useBoolean(false)
  const [localFilterPayload, setLocalFilterPayload] = useState(filterPayload);

  const handleClearAll = () => {
    setPage(1);
    setSearch('')
    setResetAllFieldTrue()
    setLocalFilterPayload(defaultFilterPayload)
    setFilterPayload(defaultFilterPayload)
    resetAdvanceFilter()
    setSearchIn([])
    setAdvancedFilter([])
    setSelectedKeywords({})
    setChips([])
  }
  return (
    <ThemeProvider theme={theme}>
      <Stack width={'100%'} justifyContent={"space-between"} direction="row" alignItems="center">
        <QuickNavbarSearch
          resetAllField={isResetAllField}
          filterPayload={filterPayload}
          setFilterPayload={setFilterPayload}
          localFilterPayload={localFilterPayload}
          setLocalFilterPayload={setLocalFilterPayload}
          setResetAllFieldFalse={setResetAllFieldFalse}
        />
        {!isMobile && <hr className="divider" />}
        {isMobile ? (
          <DropDownDrawerWithChip
            chipLabel="Badge"
            value={filterPayload.badgeFilter[0] || null}
            listArr={BadgeFilterOptions}
            onItemSelect={(type: string | null) => {
              setFilterPayload((prev) => ({
                ...prev,
                badgeFilter: type?.length ? [type] : [],
                isFilterChange: true,
              }));
            }}
          />
        ) : (

          <>
            <MultiTabFilters
              formats={filterPayload.badgeFilter}
              setFormats={(s: string[]) =>
                setFilterPayload((prev) => ({
                  ...prev,
                  badgeFilter: s,
                  isFilterChange: true,
                }))
              }
              options={BadgeFilterOptions}
            />
            {!isMobile && <hr className="divider" />}
          </>
        )}

        {isMobile ? (
          <DropDownDrawerWithChipMultiple
            chipLabel="Status"
            selectedValues={filterPayload.statusFilter}
            listArr={statusOptions}
            onItemSelect={(filter: string[]) => {
              setFilterPayload((prev) => ({
                ...prev,
                isFilterChange: true,
                statusFilter: filter,
              }));
            }}
          />
        ) : (
          <LabeledComponent
            label="Status:"
            component={
              <MultipleDropDown
                filterValue={filterPayload.statusFilter}
                dropDownItems={statusOptions}
                setFilterPayload={(filter: string[]) =>
                  setFilterPayload((prev) => ({
                    ...prev,
                    isFilterChange: true,
                    statusFilter: filter,
                  }))
                }
              />
            }
          />
        )}
        <CustomBtnFilled
          label="Clear All"
          variant="contained"
          onClick={handleClearAll}
        />
      </Stack>
    </ThemeProvider>
  )
}

export default QuickSearchNavbarItems
