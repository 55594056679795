import { useWatch } from "react-hook-form"
import { HookAutoComplete } from "../../../atoms/form-fields/SLFieldAutoComplete"
import { DropdownOption } from "../../../pages/Quick-Search/types"
import { useHookFormContext } from "../../../utils/hooks/useHookFormContext"
import { listOptions } from "../helper"

interface Props {
  inputElement: any,
  index: number,
  registerName: (index: number) => string,
  formArrayPath: string,
}

const WatchAutoComplete = ({ inputElement, index, formArrayPath, registerName }: Props) => {
    const { registerState, control } = useHookFormContext()
    const selectedValue = useWatch({
        control,
        name: `${formArrayPath}.${index}.value`,
    })

    return (
        <HookAutoComplete
            key={inputElement.id}
            rules={{
                required: { value: !!inputElement.required, message: "This field is required" },
            }}
            isWrapperInputField={true}
            staticPlaceholderForInputField={selectedValue?.label}
            {...registerState(registerName(index))}
            autocompleteProps={{
                autoHighlight: true,
                limitTags: 1,
                options: inputElement.values,
                getOptionLabel: (option: DropdownOption) => option.label,
                isOptionEqualToValue: (option, value) => {
                    return option.id === value.id
                },
                size: 'small',
                renderOption: listOptions
            }}
            textFieldProps={{
                label: inputElement.label,
                size: "small",
                required: !!inputElement.required
            }}
        />
    )
}

export default WatchAutoComplete