import { isAdmin, isSuperAdmin } from "../../utils/role";
import { filterPayload as TFilterPayload } from "../Experts/types";
import { DropdownOption, Filter, orFields } from "./types";


const KEYWORDS: Record<string, string> = {
  "headline": "Headline",
  "bio": "Bio",
  "work_experience": "Workex",
  "snippet": "Snippet",
  "expertise": "Expert Geo",
}

const QUICK_SEARCH_FILTER_KEYS: string[] = ["id", "name", "email", "mobile", "statusFilter"]

const DELIMITER: string = ";;";

const defaultFilterPayload: TFilterPayload = {
  dateFilter: null,
  typeFilter: null,
  sortFilter: (isSuperAdmin() || isAdmin()) ? "desc___updated_at" : "asc___name",
  advanceFilter: null,
  isFilterChange: false,
  rowsPerPage: 24,
  statusFilter: [],
  badgeFilter: [],
  searchFilter: null,
  pending_approvals: false,
  otherSearchFilter: {
    id: "",
    name: "",
    nick_name: "",
    email: "",
    mobile: "",
    headline: "",
    bio: "",
    functions: "",
    company: "",
    domain: "",
    work_division: ""
  },
  timelineFilters: {
    actor: null,
    action: null,
    date: null,
    filterAdded: false,
    isFilterChange: false,
  },
};
const currentPastfilter: Filter = {
  type: "dropdown",
  field: "currentPast",
  label: "Time Period",
  value: null,
  required: true,
  values: [
    { label: "Current", id: "current" },
    { label: "Past", id: "past" },
    { label: "Overall", id: "overall" },
  ],
}
const includeExcludeFilter: Filter = {
  type: "dropdown",
  field: "includeExclude",
  label: "Filter Type",
  value: null,
  required: true,
  values: [
    { label: "Include", id: "includes" },
    // { label: "Exact", id: "exact" },
    { label: "Exclude", id: "doesNotIncludes" },
  ],
}

const defaultOrFields: orFields = {
  filterType: "dropdown",
  field: "filterOn",
  value: null,
  required: true,
  filters: [
    currentPastfilter,
    includeExcludeFilter
  ],
}
const advancedFilterDefaultForm = {
  orFormElements: [defaultOrFields]
}

const filterOnDropdownFields: DropdownOption[] = [
  { id: 'Company', label: "Company" },
  { id: 'Date', label: "Date" },
  { id: 'Workex Designation', label: "Workex Designation" },
  { id: 'Workex Division', label: "Workex Division" },
  { id: 'Workex Location', label: "Workex Location" },
  { id: 'Domain', label: "Domain" },
  { id: 'Function', label: "Function" },
  { id: 'Base Location', label: "Base Location" },
  { id: 'Total Years Of Experience', label: "Total Years Of Experience" }
]
interface FilterConfig {
  [key: string]: {
    value: string | null | undefined | []; // Allow null, undefined, or array for value
    required: boolean;
    filters: Filter[]; // Use the Filter type here
  };
}
const filterConfig: FilterConfig = {
  Company: {
    value: "", // one of filterOnDropdownFields
    required: true,
    filters: [
      { type: "dropdown", field: "Company", label: "Company", value: [], required: true },
      currentPastfilter,
      includeExcludeFilter
    ],
  },
  Date: {
    value: "",
    required: true,
    filters: [
      { type: "datePicker", field: "AFTER", label: 'After (>)', required: false, value: null },
      { type: "datePicker", field: "BEFORE", label: 'Before (<)', required: false, value: null },
    ],
  },
  "Workex Designation": {
    value: "",
    required: true,
    filters: [
      { type: "textInput", field: "Designation", label: 'Designation', value: "", required: true },
      currentPastfilter,
      includeExcludeFilter,
    ],
  },
  "Base Location": {
    value: "",
    required: true,
    filters: [
      { type: "dropdown", field: "Geography", label: "Base Location *", value: [], required: true },
      includeExcludeFilter
    ],
  },
  Domain: {
    value: "",
    required: true,
    filters: [
      { type: "dropdown", field: "Domain", value: [], label: "Domains *", required: true },
      { type: "freeSolo", field: 'otherDomains', value: [], label: "Other Domains", required: false },
      includeExcludeFilter
    ],
  },
  Function: {
    value: "",
    required: true,
    filters: [
      { type: "textInput", field: "Function", value: "", label: "Function", required: true },
      includeExcludeFilter
    ],
  },
  "Workex Division": {
    value: "",
    required: true,
    filters: [
      { type: "textInput", field: "Division", value: "", label: "Division", required: true },
      includeExcludeFilter,
      currentPastfilter
    ],
  },
  "Workex Location": {
    value: "",
    required: true,
    filters: [
      { type: "dropdown", field: "Geography", value: [], label: "Workex Location *", required: true },
      currentPastfilter,
      includeExcludeFilter
    ],
  },

  "Custom": {
    value: "",
    required: true,
    filters: [
      { type: "datePicker", isCustom: true, field: "AFTER", label: 'After', required: false, value: null },
      { type: "datePicker", isCustom: true, field: "BEFORE", label: 'Before', required: false, value: null },
      { type: "dropdown", field: "Geography", value: [], label: "Workex Location", required: false },
      { type: "dropdown", field: "Company", label: "Company", value: [], required: false },
      { type: "textInput", field: "Division", value: "", label: "Division", required: false },
      { type: "textInput", field: "Designation", label: 'Designation', value: "", required: false },
      currentPastfilter,
      includeExcludeFilter
    ]
  },

  "Total Years Of Experience": {
    value: "",
    required: true,
    filters: [
      { type: "textInput", field: "min", inputType:'number', value: null, label: "Min (>=)", required: false },
      { type: "textInput", field: "max", inputType:'number', value: null, label: "Max (<=)", required: false },
      
    ],
  }
};

const stagingResponseDefaultValue = {
  addedExperts: [],
  already_added_to_pe_table: [],
  already_staged_expert_ids: [],
  not_confirmed_expert_ids: [],
}

const dialogsDefaultValue = {
  addToStaging: false,
  addToProject: false,
  timelineFilters: false,
  timeline: {
    state: false,
    data: null,
    messages: null,
    id: null,
  },
}

export {
  advancedFilterDefaultForm,
  filterOnDropdownFields,
  KEYWORDS,
  defaultOrFields,
  filterConfig,
  DELIMITER,
  QUICK_SEARCH_FILTER_KEYS,
  defaultFilterPayload,
  stagingResponseDefaultValue,
  dialogsDefaultValue
}