import { useWatch } from "react-hook-form"
import { useHookFormContext } from "../../../utils/hooks/useHookFormContext"
import HookDatePicker from "../../../atoms/form-fields/SLFieldDatePicker"
import { Box, IconButton } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  inputElement: any,
  index: number,
  registerName: (index: number) => string,
  formArrayPath: string,
}

export const BeforeAfterDatePicker = ({ inputElement, index, formArrayPath, registerName }: Props) => {
  const afterDatePath = `${formArrayPath}.0.value`
  const beforeDatePath = `${formArrayPath}.1.value`
  const { registerState, control, setValue } = useHookFormContext()
  const [after_date, before_date] = useWatch({
    control,
    name: [afterDatePath, beforeDatePath],
  })
  return (
    <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"} position={'relative'}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <HookDatePicker
          key={inputElement.id}
          {...registerState(registerName(index))}
          rules={{
            required: inputElement.isCustom
              ? false
              : (before_date === null && after_date === null ? { value: true, message: "This field is required" } : false)
          }}
          datePickerProps={{
            onChange: (value: any) => {
              if (inputElement.field === "AFTER") {
                if (before_date) {
                  if (before_date?.$y < value?.$y) {
                    setValue(beforeDatePath, null)
                  }
                }
              }
            },
            format: "YYYY",
            className: "date-picker",
            minDate: inputElement.field === "BEFORE" ? dayjs(after_date) : dayjs(null),
            label: inputElement.label,
            disabled: false,
            slotProps: {
              textField: {
                size: "small",
                fullWidth: true,
                required: inputElement.isCustom ? false : (before_date === null && after_date === null ? true : false)
              }
            },
            sx: { backgroundColor: "white", fontSize: "small" },
            views: ["year"],
            disableFuture: true,

          }}
        />
        {(inputElement.field === "AFTER" && after_date) || (inputElement.field === "BEFORE" && before_date) ? (
          <IconButton
            sx={{
              position: "absolute",
              right: "30px",
            }}
            onClick={() => setValue(`${formArrayPath}.${index}.value`, null)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ) : null}
      </LocalizationProvider>
    </Box>
  )
}
