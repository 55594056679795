import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { allowedSubdomains } from './GoogleAnalyticsTracker';

export const useExpertFilterEvents = (filterPayload: any, trackerAppliedPlace?: string) => {
  let category = ''
  let action = ''
  if (trackerAppliedPlace === 'QuickSearchPage') {
    action = 'Quick Search'
    category = 'Quick Search'
  }else if(trackerAppliedPlace === 'AllExpertPage') {
    action = 'Expert Search'
    category = 'Expert Search'
  }
  useEffect(() => {
    const currentHostname = window.location.hostname;
    if (!allowedSubdomains.includes(currentHostname)) {
      return
    }

    const newFilters: { [key: string]: any } = {};

    const addFilter = (key: string, value: any) => {
      if (value) { // Only add if it has a value
        newFilters[key] = value;
      }
    };

    if (filterPayload.advanceFilter && Object.keys(filterPayload.advanceFilter).length > 0) {
      Object.entries(filterPayload.advanceFilter).forEach(([key, value]) => {
        addFilter(`Advanced: ${key}`, value);
      });
    }

    if (filterPayload.otherSearchFilter) {
      Object.entries(filterPayload.otherSearchFilter).forEach(([key, value]) => {
        if (value) {

          addFilter(`Quick Search: ${key}`, value);
        }
      });
    }

    addFilter('Badge Filter', filterPayload.badgeFilter?.length > 0 ? filterPayload.badgeFilter.join(', ') : undefined);
    addFilter('Date Filter', filterPayload.dateFilter);
    addFilter('Pending Approvals', filterPayload.pending_approvals ? 'true' : undefined);
    addFilter('Search Filter', filterPayload.searchFilter);
    addFilter("searchIn", filterPayload?.searchIn?.length > 0 ? filterPayload.searchIn.join(', ') : undefined);
    addFilter("search", filterPayload?.search ? filterPayload.search : undefined);

    if (filterPayload?.andvancedFilters?.length > 0) {
      filterPayload?.andvancedFilters?.forEach((andFilter: any, index: any) => {
        let keyForAdvancedFilter = `Drawer ${index + 1}st andFilter --> `
        let valueForAdvancnedFilter = '';
        andFilter?.orFilters?.forEach((orFilter: any, index: any, orFilters: any) => {
          if (orFilter?.dropDownType) { 
            valueForAdvancnedFilter += orFilter.dropDownType ;
            keyForAdvancedFilter += orFilter.dropDownType;
            if (index < orFilters.length - 1) { // Check if it's NOT the last element
              valueForAdvancnedFilter += ', ';
              keyForAdvancedFilter += ', ';
            }
          }
        })
        addFilter(keyForAdvancedFilter, valueForAdvancnedFilter ? valueForAdvancnedFilter : undefined);
      })
    }


    //Sort was going on page load , so we are not sending sort 
    
    // if (filterPayload.sortFilter) {
    //   const [order, field] = filterPayload.sortFilter.split('___');
    //   addFilter('Sort', `${field} ${order}`);
    // }

    addFilter('Dropdown: Status', filterPayload.statusFilter?.length > 0 ? filterPayload.statusFilter.join(', ') : undefined);
    addFilter('Timeline Filter', filterPayload.timelineFilters?.filterAdded ? 'true' : undefined);
    addFilter('Type', filterPayload.typeFilter);

    // Send events for new or changed filters
    Object.entries(newFilters).forEach(([key, value]) => {
      ReactGA.event({
        category: category,
        action: action,
        label: key,
      });
    });

    const expert_search_combo = Object.keys(newFilters)
      .map(key => key.split(/: |___/).pop()
        ?.replace(/^domain_|Filter$/g, '')
        .trim()
      )
      .filter(Boolean)
      .join(',')
      .slice(0, 254);
    ReactGA.event({
      category: `${category} Combo`,
      action: `${action} Combo`,
      label: expert_search_combo,
    });

  }, [filterPayload]);
};