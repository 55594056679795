import { QUICK_SEARCH_FILTER_KEYS } from "./constant";
import { andFields, Filter, orFields, PayloadAdvancedFilters, PFilter } from "./types";
import { filterPayload as TFilterPayload } from "../Experts/types";

/**
 * This fn takes the whole data comes from react-hook-form and transform according to payload structure
 */
function transformForPayload(andBoxData: andFields[], setError: any): PayloadAdvancedFilters {
  const errorTracker = { hasError: false };
  const transformedData = andBoxData.map((andItem, andIndex) => ({
    orFilters: andItem.orFormElements.map((orItem, orIndex) => ({
      dropDownType: extractDropDownType(orItem),
      filters: orItem.filters.map((filterItem, index) => processFilter(filterItem, orItem, andIndex, orIndex, index, setError, errorTracker)),
    })),
  }));

  if (transformedData) {
    removeEmptyFilters(transformedData);
  }

  return errorTracker.hasError ? [] : transformedData;
}

// Removes filters from the transformed data that have no selected or included values.
function removeEmptyFilters(transformedFilters: PayloadAdvancedFilters) {
  transformedFilters.map((andGroup) => {
    andGroup.orFilters.map((orGroup) => {
      orGroup.filters = orGroup.filters.filter(
        (filterItem) => {
          return (Array.isArray(filterItem?.selectedValues) && filterItem.selectedValues.length > 0) ||
            (Array.isArray(filterItem?.includedValues) && filterItem.includedValues.length > 0);
        }
      );
    });
  });
}
// Extract dropdown type for readability
function extractDropDownType(orItem: orFields): string {
  const isCustom = orItem?.filters?.length > 3
  return isCustom ? "Custom" : (typeof orItem.value === "object" ? orItem.value.id : orItem.value)
}

// Process each filter item
function processFilter(
  filterItem: Filter,
  orItem: orFields,
  andIndex: number,
  orIndex: number,
  index: number,
  setError: any,
  errorTracker: { hasError: boolean }
): PFilter {
  const filter: PFilter = { field: filterItem.field };

  if (filterItem.field === "Company") {
    return processCompanyFilter(filter, filterItem);
  }

  if (filterItem.type === "datePicker") {
    return processDatePicker(filter, filterItem, orItem, andIndex, orIndex, index, setError, errorTracker);
  }

  if (filterItem.type === "textInput") {
    return processTextInputFilter(filter, filterItem, orItem, andIndex, orIndex, index, setError, errorTracker);
  }

  return processGenericFilter(filter, filterItem);
}

function processTextInputFilter(
  filter: PFilter,
  filterItem: any,
  orItem: any,
  andIndex: number,
  orIndex: number,
  index: number,
  setError: any,
  errorTracker: { hasError: boolean }
): PFilter {
  filter.selectedValues = [];
  if (["Function", "Designation", "Division"].includes(filterItem.field)) {
    if (typeof filterItem.value === "string" && filterItem.value) {
      filter.includedValues = [filterItem.value];
    }
  }
  if (filterItem.field === 'max' || filterItem.field === 'min') {
    const greaterThanEqualToValue = filterItem.field === 'max' ? orItem.filters?.[index - 1]?.value : filterItem?.value;
    const lessThanEqualToValue = filterItem.field === 'min' ? orItem.filters?.[index + 1]?.value : filterItem?.value;
    
    if (greaterThanEqualToValue || lessThanEqualToValue) {
      filterItem?.value && (filter.selectedValues = [String(filterItem?.value)]);
      if (greaterThanEqualToValue && lessThanEqualToValue) {
        if (Number(greaterThanEqualToValue) > Number(lessThanEqualToValue)) {
          const field = `andBoxForms.${andIndex}.orFormElements.${orIndex}.filters.${index-1}.value`; // Error on the greaterThanEqualTo field
          setError(field, { type: "manual", message: "Min value must be less then or equal to Max" });
          errorTracker.hasError = true;
        }
      }
    }else{
      const field = `andBoxForms.${andIndex}.orFormElements.${orIndex}.filters.${index}.value`;
      setError(field, { type: "manual", message: "Please fill at least one of two fields" });
      errorTracker.hasError = true;
    }

  }
  filter.selectedValues?.length === 0 && delete filter?.selectedValues
  return filter;
}

// Handle 'Company' filters separately
function processCompanyFilter(filter: PFilter, filterItem: any): PFilter {
  filter.includedValues = [];
  filter.selectedValues = [];

  if (Array.isArray(filterItem.value)) {
    filterItem.value.forEach((val: any) => {

      (typeof val.value === "string" && val.value.includes("free_text")) ? filter.includedValues?.push(val.label) : filter?.selectedValues?.push(val.label);
    });
  } else if (typeof filterItem.value === "object" && filterItem?.value?.label) {
    filterItem.value.label && filter.includedValues?.push(filterItem.value.label);
  }
  filter.includedValues?.length === 0 && delete filter?.includedValues
  filter.selectedValues?.length === 0 && delete filter?.selectedValues
  return filter;
}

// Handle 'datePicker' filters separately
function processDatePicker(
  filter: PFilter,
  filterItem: any,
  orItem: any,
  andIndex: number,
  orIndex: number,
  index: number,
  setError: any,
  errorTracker: { hasError: boolean }
): PFilter {
  if (filterItem.field === "BEFORE") {
    const afterFieldValue = orItem.filters[index - 1]?.value?.$y;
    const beforeFieldValue = filterItem.value?.$y;

    if (afterFieldValue > beforeFieldValue) {
      const field = `andBoxForms.${andIndex}.orFormElements.${orIndex}.filters.${index}.value`;
      setError(field, { type: "manual", message: "Before date should be equal or greater than after date" });
      errorTracker.hasError = true;
    }
  }
  if (filterItem.value?.$y) {
    filter.selectedValues = [String(filterItem.value?.$y)]
  }
  return filter;
}

// Handle generic filters (arrays, objects, and primitives)
function processGenericFilter(filter: PFilter, filterItem: any): PFilter {
  filter.selectedValues = [];
  if (Array.isArray(filterItem.value)) {
    filterItem.value.forEach((val: any) => {
      if (val?.label || val) {
        filter?.selectedValues?.push(val.label || val);
      }
    });
  } else {
    if (filterItem.value?.id || filterItem.value) {
      filter?.selectedValues?.push(typeof filterItem.value === "object" ? filterItem.value?.id : filterItem.value);
    }
  }
  filter.selectedValues?.length === 0 && delete filter?.selectedValues
  return filter;
}

const constructFilterPayload = (currentFilterPayload: TFilterPayload): any => {
  const unparsedFilterPayload: any = currentFilterPayload?.otherSearchFilter;
  const parsedFilterPayload: any = {}
  if (unparsedFilterPayload) {
    for (const key of QUICK_SEARCH_FILTER_KEYS) {
      if (unparsedFilterPayload[key] && unparsedFilterPayload[key]?.trim() !== "") {
        parsedFilterPayload[key] = unparsedFilterPayload[key];
      }
    }
  }
  return {
    ...parsedFilterPayload,
    ...(currentFilterPayload.statusFilter.length > 0 && { status: currentFilterPayload.statusFilter }),
    ...(currentFilterPayload.badgeFilter.length > 0 && { badges: currentFilterPayload.badgeFilter }),
  };
};

// Utility function to check if two objects are equal
const isObjectEqual = (obj1: Record<string, boolean>, obj2: Record<string, boolean>): boolean => {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
};
export {
  transformForPayload,
  constructFilterPayload,
  isObjectEqual
}